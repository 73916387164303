import { LOGOUT_PAGE, QMOD_VERSION, QMODII_VERSION, QM_WM_ID, QM_ENV } from "@/common/config";
import store from '../store/index'
import APIService from '@/common/APIService'
import NotificationService from './NotificationService';
import EventBus from '../event-bus';
//import URLUtils from '../utilities/URLUtils';
import CookieUtils from '../utilities/CookieUtils';
import JwtUtils from '../utilities/JwtUtils';
import LocalStorageService from "./LocalStorageService";
import { clearAllUnsavedChanges } from "../components/editor/config";

class AuthSession {
  accessToken = "";
  forumToken = "";
  user = null;
  authExpiry = null;
  feedTokenSet = null;
  chatTotken = "";
  authorized = false;
  registered = false;
  featureSettings = null;

}
/**
 * Responsible for all Authorization related tasks between
 * the server and the client.
 */
class AuthServiceImpl {
    
  AUTH_SESSION = 'INVRS_AUTH_SESSION';// KEY for local storage
/**
 * How often the access token is renewed.
 */
 RENEW_INTERVAL = 10*60*1000;

  /**
   * Renews authorization every RENEW_INTERVAL 
   * 
   */
renewalTimer = null; 
renewalInprogress = false;
authorized = false;

  constructor() {
    EventBus.on('logout-click', () => {
			this.logout();
    });
    
    EventBus.on('notification-stopped', () => {
			this.renewAuthorization();
    });
    
    EventBus.on('reauthorization-required', () => {
			this.renewAuthorization();
    });

    EventBus.on('authorization-required', () => {
      store.commit('SET_AUTHORIZED', false);
      EventBus.emit('login-required');
    });
  }

  sendNewAuthTokens(tokens) {
    const bc = new window.BroadcastChannel2('invrs_auth_session_channel');
    // console.debug('sending message from renewal token call')
    bc.postMessage( JSON.stringify({type: 'renew', tokens: tokens}));
//     //await bc.close();
//     //window.close();
  }
  sendLogoutMessage() {
    const bc = new window.BroadcastChannel2('invrs_auth_session_channel');
    // console.debug('sending message from logout call')
    bc.postMessage( JSON.stringify({type: 'logout'}));
//     //await bc.close();
//     //window.close();
  }
 

  waitForAuthorization(timeout) {
    store.commit("SET_AUTHORIZED", false);
    this.authorize();
    var start = Date.now();
    return new Promise(waitForAuth); 
 
    function waitForAuth(resolve, reject) {
        if (store.state.authorized)  { //eslint-disable-line
          console.debug("access authorized application may start up.");
          resolve(store.state.authorized);
        }else if (timeout && (Date.now() - start) >= timeout){
                reject(new Error("timeout waiting for authorization"));
        }else{
                setTimeout(waitForAuth.bind(this, resolve, reject), 30);
        }
    }
  }

 
  /**
   * Trades in the nonce for an access token.
   */
  authorize() {
   // return new Promise( (resolve, reject) => {
      let storedAuthSession = this.loadAuthSession();
      if( this.needsAuth(storedAuthSession) ) {
        
        let nonce = CookieUtils.getCookie('invrsNonce');
        if( nonce) {
          
          store.commit('SET_NONCE', nonce);
        
          return this.doAuthorization().then(
            (result) => {this.authorizationSuccess(result)},
            (error) => {this.authorizationError(error)});
        }
        else {
      
          console.debug("No nonce found redirecting back to login")
          window.location.href = LOGOUT_PAGE;
        }
      
      
      }
      else {
        
        //console.log("authorizing from stored session");
       

        this.initQmods(storedAuthSession).then( () => {
          //console.debug("authentication complete via stored auth session, qmods loaded proceeding with app initialization");
          store.commit('SET_TOKEN',storedAuthSession.accessToken);
          store.commit('SET_FORUM_TOKEN',storedAuthSession.forumToken);
          store.commit('users/SET_USER', storedAuthSession.user);
         
          store.commit("SET_SID", storedAuthSession.sid);
          store.commit("SET_CHAT_TOKEN", storedAuthSession.chatToken);
          store.commit("SET_FEED_TOKEN_SET", storedAuthSession.feedTokenSet);
          store.commit("SET_REGISTERED", storedAuthSession.registered);
          store.commit('SET_AUTHORIZED', true);
          store.commit('SET_FEATURE_SETTINGS', storedAuthSession.featureSettings);
          this.startAuthRenewer();
        });
      }

    //});
    
  }

  needsAuth(storedAuthSession) {
    return (!storedAuthSession || this.isAuthSessionExpired(storedAuthSession));
  }

  startAuthRenewer() {
    //console.debug("starting auth renewer");
    this.renewalTimer = setInterval(() => {this.renewAuthorization()}, this.RENEW_INTERVAL);
  }

  isAuthSessionExpired(authSession) {

    if( authSession.authExpiry) {
      let now = new Date();
      return authSession.authExpiry <= now;
    }
    else {
      return true;
    }
  }

  changeUserObject(user) {
    const authSession = JSON.parse(LocalStorageService.getString('INVRS_AUTH_SESSION'));
    authSession.user = user;
    localStorage.setItem(this.AUTH_SESSION, JSON.stringify(authSession));
  }

  loadAuthSession() {
    try {
      let storedAuthSessionStr = localStorage.getItem(this.AUTH_SESSION);
     
      if( storedAuthSessionStr ) {
       
        let storedAuthSession = JSON.parse(storedAuthSessionStr);
        if( !storedAuthSession.authExpiry) {
         
          let parsedToken = JwtUtils.parseJwt(storedAuthSession.accessToken);
          storedAuthSession.authExpiry = new Date(parsedToken.exp*1000)
          
        }
        return storedAuthSession;
      }
      else {
        console.debug("no stored auth session found");
        return null;
      }
    } catch( err ) {
      console.debug("error restoring auth session " + err);
      return null;
    }
  }
  /**
   * Called when authorization is successful.  
   * Stores the access token and the user.
   * Starts up the NotificationService so that we can receive server events.
   * 
   * @param  result result.data has the access token and the user object.
   */
  authorizationSuccess(result) {
    sessionStorage.removeItem("nonce");
    
  
    //console.debug(JSON.stringify(result));
    let authSession = new AuthSession();
    authSession.accessToken = result.data.accessToken;
    authSession.forumToken = result.data.forumToken;
    authSession.user = result.data.user;
    authSession.sid = result.data.sid;
    authSession.chatToken = result.data.chatToken;
    authSession.feedTokenSet = result.data.feedTokenSet;
    authSession.registered = result.data.registered;
    authSession.authorized = true;
    authSession.featureSettings = result.data.featureSettings;
    authSession.hasProxy = result.data.hasProxy;
    authSession.featuredGroupFeed = result.data.featuredGroupFeed;
    authSession.checksum = result.data.checksum;

    //console.debug("waiting to init qmods before setting authorized to true currently authorized = "+store.state.authorized);
    this.initQmods(authSession).then( () => {
      console.debug("authentication complete, qmods loaded proceeding with app initialization");
      store.commit('SET_TOKEN',result.data.accessToken);
      store.commit('SET_FORUM_TOKEN',result.data.forumToken);
      store.commit('users/SET_USER', result.data.user);
      store.commit('SET_SID', result.data.sid);
      store.commit('SET_AUTHORIZED', true);
      store.commit('SET_CHAT_TOKEN', result.data.chatToken);
      store.commit('SET_FEED_TOKEN_SET', result.data.feedTokenSet);
      store.commit('SET_REGISTERED', result.data.registered);
      store.commit('SET_CHAT_NOTIFICATION_COUNTS', result.data.chatNotificationCounts);
      store.commit('SET_FEED_NOTIFICATION_COUNTS', result.data.feedNotificationCounts);
      if( result.data.systemNotifications && result.data.systemNotifications.length > 0 ){
        store.commit('SET_SYSTEM_NOTIFICATIONS', result.data.systemNotifications);
      }
      store.commit('SET_FEATURE_SETTINGS', result.data.featureSettings);
      store.commit('SET_HAS_PROXY', result.data.hasProxy)
      store.commit('SET_FEATURED_GROUP_FEED', result.data.featuredGroupFeed)
      store.commit('SET_CHECKSUM', result.data.checksum);
      store.commit('SET_COMPARE_METRIC', result.data.compareMetric);
      store.commit('SET_LIST_COMPARE_METRICS', result.data.listCompareMetrics);
      localStorage.setItem(this.AUTH_SESSION, JSON.stringify(authSession));
    
      
      //NotificationService.start(store.state.users.user.userId);
      this.startAuthRenewer();

      EventBus.emit('track-user', authSession.user);
    });
   

    
    
  }

  onSidReceived(sid){
    const authSession = JSON.parse(LocalStorageService.getString('INVRS_AUTH_SESSION'));
    authSession.sid = sid;

    this.initQmods(authSession).then( () => {
      // console.debug("onSidReceived");
      store.commit('SET_SID', authSession.sid);
      localStorage.setItem(this.AUTH_SESSION, JSON.stringify(authSession));
    
    });
  }

  async initQmods(authSession) {
    if(authSession.sid){
      console.log("authSession has sid loading qmods");
      //let dividendsHistoryQmod = this.createQmodNode("dividends-history-qmod", "/assets/js/dividendsHistoryQmodLoader.js", authSession.sid);
      //let analystQmod = this.createQmodNode("analyst-qmod", "/assets/js/analystQmodLoader.js", authSession.sid);
      //let corporateEventsQmod = this.createQmodNode("corporate-events-qmod", "/assets/js/corporateEventsQmodLoader.js", authSession.id);
      //let securityOverviewChartQmod = this.createQmodNode("security-overview-chart-qmod", "/assets/js/securityOverviewChartQmodLoader.js", authSession.sid);
      //let fundOverviewQmod = this.createQmodNode("fund-overview-qmod", "/assets/js/fundOverviewQmodLoader.js", authSession.sid);
      //let fundProfileQmod = this.createQmodNode("fund-profile-qmod", "/assets/js/fundProfileQmodLoader.js", authSession.sid);
      //let fundHoldingsQmod = this.createQmodNode("fund-holdings-qmod", "/assets/js/fundHoldingsQmodLoader.js", authSession.sid);
      //let fundSectorAllocationChartQmod = this.createQmodNode("fund-sector-allocation-chart-qmod", "/assets/js/fundSectorAllocationChartQmodLoader.js", authSession.sid);
      //let insidersQmod = this.createQmodNode("insiders-qmod", "/assets/js/insidersQmodLoader.js", authSession.sid);
      //let relativePerformanceChartQmod = this.createQmodNode("relative-performance-chart-qmod", "/assets/js/relativePerformanceChartQmodLoader.js", authSession.sid);
      //let miniQuotesQmod = this.createQmodNode("mini-quotes-qmod", "/assets/js/miniQuotesQmodLoader.js", authSession.sid);
      //let newsQmod = this.createQmodNode("news-qmod", "/assets/js/newsQmodLoader.js", authSession.sid);
      //let filingsQmod = this.createQmodNode("filings-qmod", "/assets/js/filingsQmodLoader.js", authSession.sid);
      //let earningsQmod = this.createQmodNode("earnings-qmod", "/assets/js/earningsQmodLoader.js", authSession.sid);
      //let portfolioQmod = this.createQmodNode("portfolio-qmod", "/assets/js/portfolioQmodLoader.js", authSession.sid);
      let qmod = this.createQmodNode("qmod", "//qmod.quotemedia.com/js/qmodLoader.js", authSession.sid);
      //let tradesQmod = this.createQmodNode("trades-qmod", "/assets/js/tradesQmodLoader.js", authSession.sid, "v1.49.0");
      //let performanceQmod = this.createQmodNode("shareinfoperformance-qmod", "/assets/js/performanceQmodLoader.js", authSession.sid);
      //let earningsCalendarQmod = this.createQmodNode("earningscalendar-qmod", "/assets/js/earningsCalendarQmodLoader.js", authSession.sid);
      //let miniMarketCurrenciesQmod = this.createQmodNode("minimarketcurrencies-qmod", "/assets/js/miniMarketCurrenciesQmodLoader.js", authSession.sid);
      //let miniMarketFuturesQmod = this.createQmodNode("minimarketfutures-qmod", "/assets/js/miniMarketFuturesQmodLoader.js", authSession.sid);
      //let miniMarketIndicesQmod = this.createQmodNode("minimarketindices-qmod", "/assets/js/miniMarketIndicesQmodLoader.js", authSession.sid);
      //let miniMarketRatesQmod = this.createQmodNode("minimarketrates-qmod", "/assets/js/miniMarketRatesQmodLoader.js", authSession.sid);
      //let investmentCalculatorQmod = this.createQmodNode("investment-calculator-qmod", "/assets/js/investmentCalculatorQmodLoader.js", authSession.sid);
      //let dividendsCalendarQmod = this.createQmodNode("dividendscalendar-qmod", "/assets/js/dividendsCalendarQmodLoader.js", authSession.sid);
      
      let qmodii = this.createQmodNode("qmodiiLoader", `//static.c1.quotemedia.com/qmod/qmodii/${QMODII_VERSION}/qModii.umd.min.js`, authSession.sid);
      
      document.body.appendChild(qmod);
      document.body.appendChild(qmodii);
    // document.getElementById("qmod").setAttribute("data-qmod-sid", authSession.sid);
    return this.ensureMainQModReady(3000);
    //await this.ensureMainQModReady(3000);//.then( () => {
        //console.debug("****** appending qmods as main qmod is ther*****");
        // document.body.appendChild(filingsQmod);
        // document.body.appendChild(earningsQmod);
        // document.body.appendChild(miniQuotesQmod);
        // document.body.appendChild(newsQmod);
        // document.body.appendChild(portfolioQmod);
        // document.body.appendChild(analystQmod);
        // document.body.appendChild(corporateEventsQmod);
        // document.body.appendChild(relativePerformanceChartQmod);
        // document.body.appendChild(securityOverviewChartQmod);
        // document.body.appendChild(fundHoldingsQmod);
        // document.body.appendChild(fundSectorAllocationChartQmod);
        // document.body.appendChild(fundOverviewQmod);
        // document.body.appendChild(fundProfileQmod);
        // document.body.appendChild(insidersQmod);
        // document.body.appendChild(dividendsHistoryQmod);
         //document.body.appendChild(tradesQmod);
        // document.body.appendChild(performanceQmod);
        // document.body.appendChild(earningsCalendarQmod);
        // document.body.appendChild(miniMarketCurrenciesQmod);
        // document.body.appendChild(miniMarketFuturesQmod);
        // document.body.appendChild(miniMarketIndicesQmod);
        // document.body.appendChild(miniMarketRatesQmod);
        // document.body.appendChild(investmentCalculatorQmod);
        // document.body.appendChild(dividendsCalendarQmod);

       // return this.ensureQModReady('tradesQMod', 3000);
    //});
    } else {
      return null;
    }
    

   // return this.ensureQModReady(3000);
      
  }

  

  ensureMainQModReady(timeout) {
    var start = Date.now();
    return new Promise(waitForQMod); // set the promise object within the ensureFooIsSet object
 
      // waitForFoo makes the decision whether the condition is met
      // or not met or the timeout has been exceeded which means
      // this promise will be rejected
      function waitForQMod(resolve, reject) {
        //console.debug("in wait for qmod");
          if (window.qMod)  { //eslint-disable-line
            //console.debug("Main qmod loader initialized child qmods can be loaded safely");
              resolve(window.qMod);
      }else if (timeout && (Date.now() - start) >= timeout){
              reject(new Error("timeout waiting for qmod"));
      }else{
              setTimeout(waitForQMod.bind(this, resolve, reject), 30);
      }
    }
}

  ensureQModReady(qmodName, timeout) {
    var start = Date.now();
    return new Promise(waitForQMod); // set the promise object within the ensureFooIsSet object
 
      // waitForFoo makes the decision whether the condition is met
      // or not met or the timeout has been exceeded which means
      // this promise will be rejected
      function waitForQMod(resolve, reject) {
        //console.debug("in wait for qmod");
          if (window[qmodName])  { //eslint-disable-line
          //console.debug("qmod loaders initialized app can initialize safely");
              resolve(window[qmodName]);
      }else if (timeout && (Date.now() - start) >= timeout){
              reject(new Error("timeout waiting for qmod"));
      }else{
              setTimeout(waitForQMod.bind(this, resolve, reject), 30);
      }
    }
}
    

  createQmodNode(id, src, sid, customVersion) {
    let qmod = document.createElement('script');
    qmod.setAttribute("id", id);
    qmod.setAttribute("type", "application/javascript");
    qmod.setAttribute("src", src)
    qmod.setAttribute("data-qmod-wmid", QM_WM_ID);
    qmod.setAttribute("data-qmod-env", QM_ENV);
    qmod.setAttribute("async", "");
    if( id == 'qmodiiLoader') {
      if( customVersion ){
        qmod.setAttribute("data-qmod-version", customVersion);
      }
      else {
        qmod.setAttribute("data-qmod-version", QMODII_VERSION);
      }
    }
    else {
      if( customVersion ){
        qmod.setAttribute("data-qmod-version", customVersion);
      }
      else {
        qmod.setAttribute("data-qmod-version", QMOD_VERSION);
      }
    }
   
    qmod.setAttribute("data-qmod-sid", sid);
    return qmod;
  }

 /**
  * Called when authorization is successfully renewed.
  * Replaces the current access token with a new one.
  * 
  * @param result 
  */
  reauthorizationSuccess(result) {
    this.renewalInprogress = false;
    
    this.accessTokenRenewed(result.data.accessToken)
   

    let authSession = new AuthSession();
    authSession.accessToken = result.data.accessToken;
    authSession.forumToken = store.state.forumToken;
    authSession.user = store.state.users.user;
    authSession.sid = store.state.sid;
    authSession.chatToken = store.state.chatToken;
    authSession.feedTokenSet = store.state.feedTokenSet;
    authSession.authorized = true;
    authSession.registered = store.state.registered;
    authSession.chatNotificationCounts = store.state.chatNotificationCounts;
    authSession.feedNotificationCounts = store.state.feedNotificationCounts;
    authSession.featureSettings = store.state.featureSettings;
    authSession.hasProxy = store.state.hasProxy;
    authSession.checksum = store.state.checksum;
    // localStorage.setItem(this.AUTH_SESSION, JSON.stringify(authSession));

    const data = {
      'accessToken': result.data.accessToken,
      'sid': store.state.sid,
      'chatToken': store.state.chatToken
    }

    this.sendNewAuthTokens(JSON.stringify(data));


    if( !NotificationService.isRunning() && !authSession.hasProxy) {
     
      NotificationService.start(store.state.users.user.analyst.analystId);
    }
    
    
  }
  
  accessTokenRenewed(newToken) {
    store.commit('SET_TOKEN',newToken);
  }


  /**
   * Called when there is an error attempting to convert the nonce into an access token.
   * Should alert user and redirect back to login page.
   * 
   * @param error 
   */
  authorizationError(error) {
    
    sessionStorage.removeItem("nonce");
    console.debug('Error authorization access '+ error);
    store.commit('SET_AUTHORIZED', false);
    EventBus.emit('login-required');
   
  }

  /**
   * Called when an error occurs attempting to get a renewed access token.
   * For now should just log and let existing token being used until it expires
   * and let expiration logic work. (ie request user login again)
   */
  reauthorizationError() {
    this.renewalInprogress = false;
    
    //todo show error to user
    store.commit('SET_AUTHORIZED', false);
    EventBus.emit('login-required');
    
  }

  /**
   * Makes the actual autorization call.
   */
  doAuthorization() {
    return APIService.get('/user/authorize'); 
  }

  /** 
   * Called after the logout call completes successfully
   * Clears the authorization related data from the store
   * and redirects to login page
   */
  loggedOut() {
    
    clearInterval(this.renewalTimer)
    store.commit('SET_TOKEN', null);
    store.commit('SET_NONCE', null);
    store.commit('users/SET_USER', null);
    store.commit('SET_FORUM_TOKEN', null);
    store.commit('SET_CHAT_TOKEN', null);
    store.commit('SET_SID', null);
    store.commit('SET_FEED_TOKEN_SET', null);
    store.commit('SET_REGISTERED', false);
    store.commit('SET_AUTHORIZED', false);
    store.commit ('SET_FEATURE_SETTINGS', null);
    store.commit('SET_CHECKSUM', null);
    // localStorage.removeItem(this.AUTH_SESSION);
    // LocalStorageService.clearAppState();
    sessionStorage.removeItem("showTourPrompt");
    this.sendLogoutMessage(); 
    NotificationService.stop();
    // window.location.href = LOGOUT_PAGE;
    clearAllUnsavedChanges();
  }


  clearAuthSession() {
    clearInterval(this.renewalTimer)
    store.commit('SET_TOKEN', null);
    store.commit('SET_NONCE', null);
    store.commit('users/SET_USER', null);
    store.commit('SET_FORUM_TOKEN', null);
    store.commit('SET_CHAT_TOKEN', null);
    store.commit('SET_SID', null);
    store.commit('SET_FEED_TOKEN_SET', null);
    store.commit('SET_REGISTERED', false);
    store.commit('SET_AUTHORIZED', false);
    store.commit ('SET_FEATURE_SETTINGS', null)
    store.commit('SET_CHECKSUM', null);
    localStorage.removeItem(this.AUTH_SESSION);
    LocalStorageService.clearAppState();
    NotificationService.stop();
  }


  /**
   * Called if logout returns an error.
   * Still clears local authorization related data from teh store
   * and redirects to login page.
   */
  logoutFailed() {
    
    clearInterval(this.renewalTimer)
    store.commit('SET_TOKEN', null);
    store.commit('SET_NONCE', null);
    store.commit('users/SET_USER', null);
    store.commit('SET_FORUM_TOKEN', null);
    store.commit('SET_CHAT_TOKEN', null);
    store.commit('SET_SID', null);
    store.commit('SET_FEED_TOKEN_SET', null);
    store.commit('SET_REGISTERED', false);
    store.commit('SET_AUTHORIZED', false);
    store.commit ('SET_FEATURE_SETTINGS', null)
    store.commit('SET_CHECKSUM', null);
    // localStorage.removeItem(this.AUTH_SESSION);
    // LocalStorageService.clearAppState();
    this.sendLogoutMessage();
    NotificationService.stop();
    // window.location.href = LOGOUT_PAGE;
   
  }

  /**
   * Makes the actual logout call
   */
  logout() {
    localStorage.removeItem(this.AUTH_SESSION);
    LocalStorageService.clearAppState();
    if( store.state.users.user ) {
      APIService.post('/user/logout', {userId: store.state.users.user.userId, token: store.state.chatToken});
      // .then( 
      //   () => {this.loggedOut()},
      //   (error) => {this.logoutFailed(error)});
      // }
      // else {
       
      // }
    }
    this.loggedOut();
      
  }

  /**
   * Makes the actual reauthorization call.
   */
  renewAuthorization() {
    if( !this.renewalInprogress) {
      this.renewalInprogress = true;
      //console.debug("Attempting to reauthorize ");
      APIService.get('/user/reauthorize?sid='+store.state.sid).then(
        (result) => {this.reauthorizationSuccess(result)},
        (error) => {this.reauthorizationError(error)});
    }
    
  }

  
  
}

const AuthService = new AuthServiceImpl();

export default AuthService;