
const SecurityFormatter= {

    getFormattedExchange(exchangeSymbol) {
        if( exchangeSymbol == 'XNAS') {
            return "NASDAQ";
        }
        else if( exchangeSymbol == 'XNYS') {
            return "NYSE";
        }
        else if( exchangeSymbol == 'OOTC') {
            return "OTC";
        }
        else if( exchangeSymbol == 'XTSE') {
            return "TSX";
        }
        else if( exchangeSymbol == 'XTSX') {
            return "TSXV";
        }
        else if( exchangeSymbol == 'XCNQ') {
            return "CSE"
        }
        else if( exchangeSymbol == 'ARCX'){
            return "NYSE ARCA"
        }
        else {
            return exchangeSymbol;
        }
    },

    getQMExchangeSymbolSuffix(exchangeSymbol) {
        
        if( exchangeSymbol == 'XTSE') {
            return ":CA";
        }
        else if( exchangeSymbol == 'XTSX') {
            return ":CA";
        }
        else if( exchangeSymbol == 'XCNQ') {
            return ":CA"
        }
        else if( exchangeSymbol == 'AQL'){
            return ":AQL"
        }
        
        else {
            return "";
        }
    },

    getFormattedSecurity(security) {
        // let exchSymbol = "";
        // if( security.exchangeSymbol ) {
        //     exchSymbol = ":"+this.getFormattedExchange(security.exchangeSymbol);
        // }

        if( security.company) {
            return security.symbol.replace("/", ".")+" (" + security.company+")";
        }
        else {
            return security.symbol.replace("/", ".")+ " (" + security.name+")";
        }
    },

    getSymbolWithoutQMExchange(symbol) {
        //console.log("checking symbol ["+symbol+"]")
        if( symbol.indexOf(':') > 0 ) {
            return symbol.substring(0, symbol.indexOf(':') );
        }
        else {
            return symbol;
        }
    },

    getSymbolViaCdnExchanges(security) {
        const cdnExchanges = ['XTSE', 'XCNQ', 'XTSX']
        const ca = ':CA';
        
        if( cdnExchanges.includes(security.exchangeSymbol) && !security.symbol.includes(ca)) {

            return security.symbol + ca;
        }
        else if( security.exchangeSymbol == 'AQL') {
            return security.symbol +":AQL";
        }
        else {
            
            return security.symbol.replace('.PF.', '.P.').replace('.PR.', '.P.'); // preferred share symbol conversion for US exchanges
        }
    }
}

export default SecurityFormatter;