import { StreamChat } from 'stream-chat';
import APIService from '@/common/APIService'
import store from '../store/index';
import { STREAM_KEY } from "@/common/config";
import EventBus from '../event-bus';
import NotificationService from './NotificationService';

function messageHandler(chatNotification) {
    ChatService.onMessageNotification(chatNotification);
}

function mentionHandler(chatNotification) {
    ChatService.onMentionNotification(chatNotification);
}

function channelDeleteHandler(notification) {
    ChatService.onChannelDeleted(notification);
}

class ChatServiceImpl {
    
    chatClient = null;

    inUse = false;

    initGroupsChannelsStateAttempt = 0;
    initChatStateDMAttempt = 0;
    
    reactionMap = {
        'smile':'🙂',
        'happy':'😃',
       'lmfao':'🤣',
        'wink':'😉',
        'hmm':'🤨',
        'ugh':'🙄',
        'sad':'😪',
        'frown':'🙁',
        'like':'👍',
        'dislike':'👎',
        'ok':'👌',
        'clap':'👏',
        'wave':'👋',
        'hands':'🙌',
        'hot':'🔥',
        'award':'🏅',
        'heart':'❤️', //'💓',
        'money':'💲',
        'thanks':'🙏',
       'rocket':'🚀'

    }

    async init() {
        if ( this.isChatEnabled()) {
            
            EventBus.off('chat-message-notification', messageHandler)
            EventBus.on('chat-message-notification', messageHandler);

           
            EventBus.off('chat-mention-notification',  mentionHandler);
            EventBus.on('chat-mention-notification',  mentionHandler);
            

            EventBus.off('chat-channel-deleted-notification', channelDeleteHandler)
            EventBus.on('chat-channel-deleted-notification', channelDeleteHandler);
            this.initChatState();
            

        }
    }

    onChannelDeleted(notification) { //eslint-disable-line
       
        store.commit('MARK_CHANNELS_STALE');
    }

    onMessageNotification(chatNotification) {
        if( chatNotification.message.directMessage ) {
            // handle direct message notification
            
            store.commit('MARK_CHANNELS_STALE_DM');
            store.commit('ADD_CHAT_UNREAD_COUNTS', 1);
        }
        else {
            // let AppGroupMenu handle group notifications
            // handle group chat message notification
            //store.commit('ADD_GROUP_CHAT_UNREAD_COUNTS', chatNotification.message);

        }
            //store.commit('MARK_CHANNELS_STALE_DM');
            //store.commit('ADD_CHAT_UNREAD_COUNTS', 1);
           // this.updateUnread(chatNotification);
        
    }

    onMentionNotification(chatNotification) {
        store.commit('ADD_CHAT_MENTION_COUNTS', 1);
        this.updateMentions(chatNotification);
        
    }

    updateUnread( chatNotification) {
        if( store.state.chatState && store.state.chatState.myChannels ) {
           
            for( var i = 0; i <store.state.chatState.myChannels.length; ++i ) {
                let c = store.state.chatState.myChannels[i];
                if (c.data.chatChannelId == chatNotification.message.chatChannelId ) {
                    ++c.state.unreadCount;
                    break;
                }
            }
        }
    }
    updateMentions( chatNotification) {
        if( store.state.chatState && store.state.chatState.myChannels ) {
           
            for( var i = 0; i < store.state.chatState.myChannels.length; ++i ) {
                let c = store.state.chatState.myChannels[i];
                if (c.data.chatChannelId == chatNotification.message.chatChannelId ) {
                    
                    if( !c['mentionCount']) {
                        c['mentionCount'] = 1;
                    }
                    else {
                        ++c['mentionCount'];
                    }
                    
                    break;
                }
            }
        }
    }

    waitForClient() {
        
        return new Promise((resolve, reject) => {this.waitForFree(resolve, reject)});

        
    }

    waitForFree(resolve, reject) {

        if (!this.inUse)  { //eslint-disable-line
          
          resolve(this.inUse);
        
        }else{
            setTimeout(this.waitForFree.bind(this, resolve, reject), 30);
        }
    }

    async initChatState() {
       
        await this.initChatStateDM();
        await this.initGroupsChannelsState();
    
    }

    async initGroupsChannelsState() {
        await this.waitForClient().then(async () => {
            // console.log("initGroupsChannelsState");
            await this.getGroupsChannels(100, 0).then(resp => {
                if( resp.status == "success") {
                    const channels = resp.channels;
                    if(channels){
                        let groupsChannelsUnreadCount = {};
                        channels.forEach(channel => {
                            if(channel.data.groupId && channel.state.unreadCount){
                                if(groupsChannelsUnreadCount.unreadCount){
                                    groupsChannelsUnreadCount.unreadCount += channel.state.unreadCount;
                                } else {
                                    groupsChannelsUnreadCount["unreadCount"] = channel.state.unreadCount;
                                }
                                if(groupsChannelsUnreadCount[channel.data.groupId]?.unreadCount){
                                    groupsChannelsUnreadCount[channel.data.groupId].unreadCount += channel.state.unreadCount;
                                    groupsChannelsUnreadCount[channel.data.groupId][channel.data.chatChannelId] = {unreadCount: channel.state.unreadCount};
                                } else {
                                    groupsChannelsUnreadCount[channel.data.groupId] = {
                                        unreadCount: channel.state.unreadCount,
                                        [channel.data.chatChannelId]: {unreadCount: channel.state.unreadCount}
                                    };
                                }
                            }
                        });
                        // console.log("initGroupsChannelsState", groupsChannelsUnreadCount, resp.channels);
                        store.commit('SET_GROUPS_CHANNELS_UNREAD_COUNTS', groupsChannelsUnreadCount);
                    }
                } else {
                    console.log("error initGroupsChannelsState", resp);

                    this.initGroupsChannelsStateAttempt++;

                    if(this.initGroupsChannelsStateAttempt <= 2){
                        setTimeout(async () => {
                            await this.initGroupsChannelsState();
                        }, 1000);
                    }
                }
            });
        });
    }

    async initChatStateDM() {
        await this.waitForClient().then(async () => {
            // console.log("initChatStateDM");
            await this.getMyDMChannels(20, 0, null, null, null).then(  resp => {
            
                if( resp.status == "success") {
                    
                    let chatStateDM = { channelsAreStale: false, myChannels: resp.channels};
                    store.commit("SET_CHAT_STATE_DM", chatStateDM)
                    var unreadCount = 0;
                    resp.channels.forEach(c => {
                        unreadCount = unreadCount + c.countUnread();
                    });
                    store.commit('SET_CHAT_UNREAD_COUNTS', unreadCount);
                }else {
                    console.log("error initChatStateDM", resp);

                    this.initChatStateDMAttempt++;

                    if(this.initChatStateDMAttempt <= 2){
                        setTimeout(async () => {
                            await this.initChatStateDM();
                        }, 1500);
                    }
                }
            });
        });
    }
    
    

    isChatEnabled() {
        return (store.state.chatToken && store.state.chatToken.trim() != '');
    }

    async connectUserToChatClient(context) {//eslint-disable-line
        // console.log('connectUserToChatClient called from context: '+ context);
        if( this.chatClient == null || this.chatClient.user == null){
            //console.log("chatClient was null or disconnected - creating new client connection")
            this.chatClient = StreamChat.getInstance(STREAM_KEY);
            
            await this.chatClient.connectUser({
                id: store.state.users.user.userId.toString()
            }, store.state.chatToken);
        }
        // else {
        //     console.log("Reusing existing chatClient")
        // }
        this.inUse = true;
        return this.chatClient;
    }

    



    /**
     * 
     * @param {*} limit 
     * @param {*} offset 
     * @param { the name or part of name} query 
     * @param {field to sort by} sortField 
     * @param {asc or desc} sortDir 
     * @returns 
     */
    async getMyChannels(limit, offset, query, sortField, sortDir) {//eslint-disable-line
        this.inUse = true;
        let userIdParam = ''+store.state.users.user.userId;
        const filter = { type: {$in:['messaging','privateMessaging']}, members: { $in: [userIdParam] }, isDiscussion: false }; 
        const sort = [{name : 1}];
        let client= await this.connectUserToChatClient('getMyChannels')
        return new Promise( (resolve, reject) => {//eslint-disable-line
           
            client.queryChannels(filter, sort, {limit: limit, offset: offset}, {watch :false}).then(async channels => {
                
                let resp = {
                    status : 'success',
                    channels: channels
                }
                await this.disconnectUserFromChatClient('getMyChannels');
                resolve(resp);
               
                
            })
            .catch(async error => {
                console.log("error querying channels "+ error);
                let resp = {
                    status : 'error',
                    message: error
                }
                await this.disconnectUserFromChatClient('getMyChannels');
                resolve(resp);
                
                
            })
           
        });

       
    }

    async getGroupsChannels(limit, offset) {
        this.inUse = true;
        let userIdParam = ''+store.state.users.user.userId;//eslint-disable-line
        let filter = null;
        
        filter = { members: { $in: [userIdParam] }, type: {$in:['messaging','privateMessaging']}, isDiscussion: false};
        
        const sort = [{name : 1}];
        let client = await  this.connectUserToChatClient('getGroupsChannels');
        
        return new Promise( (resolve, reject) => {//eslint-disable-line
          
            client.queryChannels(filter, sort, {limit: limit, offset: offset}, {watch :false}).then(async channels => {
                
                let resp = {
                    status : 'success',
                    channels: channels
                }
                await  this.disconnectUserFromChatClient('getGroupsChannels');
                resolve(resp);
                
                
            })
            .catch(async error => {
                console.log("error querying channels "+ error);
                let resp = {
                    status : 'error',
                    message: error
                }
                this.disconnectUserFromChatClient('getGroupsChannels');
                resolve(resp);
                
                
            })
                
        });

    }

    async getGroupChannels(groupId, limit, offset, query, sortField, sortDir, isMember) {//eslint-disable-line
        this.inUse = true;
        let userIdParam = ''+store.state.users.user.userId;//eslint-disable-line
        let filter = null;
        
        if( isMember ) {
            filter = { type: {$in:['messaging','privateMessaging']}, isDiscussion: false,  ["groupId"] : {"$eq": groupId}, ["members"]: {$in: [userIdParam]} }; 
        }
        else {
            filter = { type: {$in:['messaging']}, isDiscussion: false,  ["groupId"] : {"$eq": groupId} }; //only query public channels if not a member
        }
        const sort = [{name : 1}];
        let client = await  this.connectUserToChatClient('getGroupChannels');
        return new Promise( (resolve, reject) => {//eslint-disable-line
          
            client.queryChannels(filter, sort, {limit: limit, offset: offset}, {watch :false}).then(async channels => {
                
                let resp = {
                    status : 'success',
                    channels: channels
                }
                await  this.disconnectUserFromChatClient('getGroupChannels');
                resolve(resp);
                
                
            })
            .catch(async error => {
                console.log("error querying channels "+ error);
                let resp = {
                    status : 'error',
                    message: error
                }
                this.disconnectUserFromChatClient('getGroupChannels');
                resolve(resp);
                
                
            })
                
            
        });

       
    }
    async removeReaction(channel, messageId, reaction) {
        await channel.deleteReaction(messageId, reaction);
    }

    async updateReaction(channel, messageId, userId, reaction) {
        await channel.sendReaction(
                messageId, 
                {
                    type: reaction,
                    user_id: ''+userId
                },
                { enforce_unique: false }
        
            );

        
    }




    async hideDMChannel(localChannel) {//eslint-disable-line
        this.inUse = true;
        let client = await this.connectUserToChatClient('hideDMChannel');
        return new Promise( async (resolve, reject) =>  {//eslint-disable-line
           
            try {
                const channel = await client.channel("privateMessaging", localChannel.id);
                await channel.watch();
                await channel.hide();
                await channel.stopWatching();
                
            }finally {
                //console.log("dm channel hidden")
                await this.disconnectUserFromChatClient('hideDMChannel');
                resolve();
            }
                
            
        });

       
    }

   

    async getMyDMChannels(limit, offset, query, sortField, sortDir, includeHidden, cids) {//eslint-disable-line
        this.inUse = true;
        let userIdParam = ''+store.state.users.user.userId;
        let filter = null;

        
        if( includeHidden ) {
           filter = { 
                type: 'privateMessaging', 
                members: { $in: [userIdParam] }, 
                isDiscussion: true, 
                "$or": [ {"hidden" : true}, {"hidden" : false}]
            }; 
        }
        else {
            filter = { 
                type: 'privateMessaging', 
                members: { $in: [userIdParam] }, 
                isDiscussion: true, "hidden" : false
            }; 
        }

        if( cids && cids.length > 0 ) {
            filter["cid"] = { $in: cids } 
        }

            
        //const filter = { members: { $in: [userIdParam] }}; 
        const sort = [{name : 1}];
        let client = await this.connectUserToChatClient('getMyDMChannels');
        return new Promise( (resolve, reject) => {//eslint-disable-line
            
            client.queryChannels(filter, sort, {limit: limit, offset: offset}, {watch :false}).then(async channels => {
                
                let resp = {
                    status : 'success',
                    channels: channels
                }
                
               
                await this.disconnectUserFromChatClient('getMyDMChannels');
                resolve(resp);
            })
            .catch( async error => {
                console.log("error querying channels "+ error);
                let resp = {
                    status : 'error',
                    message: error
                }
                resolve(resp);
               
                await this.disconnectUserFromChatClient('getMyDMChannels');
            })
                
            
        });
       
    }

    getMyDMRequestsSent(limit, offset) {
        return APIService.get('/chat/dm/requests/sent?limit='+limit+'&offset='+offset);
    }

    getMyDMRequestsReceived(limit, offset) {
        return APIService.get('/chat/dm/requests/recieved?limit='+limit+'&offset='+offset);
    }

    async getDMChannelIfExists(analystId) {
        let resp = await APIService.get('/chat/dm/' + analystId);
        
        
        if( resp.data.status == 'success') {
            if(resp.data.message == 'no such dm' ) {
                return new Promise( (resolve, reject) => {resolve( { //eslint-disable-line
                    status : 'success',
                    message: 'no such dm'
                });} ); 
            }
            else {
                //console.log("Found dm channel: "+JSON.stringify(resp));
                return await this.getDMChannel(resp.data.channel.extChannelId)
            }
        }
        else {
            return new Promise( (resolve, reject) => {resolve({//eslint-disable-line
                status : resp.data.status,
                message: resp.data.message
            });} ); 
        }
    
    
        
    }

    async getDMChannel(channelId) {//eslint-disable-line
        
        let userIdParam = ''+store.state.users.user.userId;
        const filter = {  cid: { $eq: 'privateMessaging:'+channelId }, members: { $in: [userIdParam] }, isDiscussion: true}; 
        const sort = [{name : 1}];
        let client = await this.connectUserToChatClient('getDmChannel');
        return new Promise( (resolve, reject) => {//eslint-disable-line
            client.queryChannels(filter, sort, {limit:1, offset:0}, {watch :false}).then( async channels => {
                //
                let resp = {
                    status : 'success',
                    channels: channels
                }
                await this.disconnectUserFromChatClient('getDmChannel');
                resolve(resp);
                
                
            })
            .catch( async error => {
                console.log("error querying channels "+ error);
                
                let resp = {
                    status : 'error',
                    message: error
                }
                await this.disconnectUserFromChatClient('getDmChannel');
                resolve(resp);
               
                
            })
                
                
           
        });
    }


    createDMRequest(targetAnalystId, message) {
        const dmRequest = {
            targetAnalystId: targetAnalystId,
            message : message
        };
        return APIService.post('/chat/dm/request', dmRequest );
    }

    acceptOrRejectDMRequest(dmRequestId, accepted) {
        const dmResponse = {
            dmRequestId: dmRequestId,
            accepted : accepted
        };
        return APIService.post('/chat/dm/accept', dmResponse );
    }

    deleteDMRequest(dmRequestId) {
       
        return APIService.delete('/chat/dm/'+dmRequestId );
    }


    updateDmEnabled(enabled) {
        const body = {
            enabled: enabled
        };
        return APIService.put('/chat/dm/enabled', body );
    }

    updateDmNotificationsEnabled(enabled) {
        const body = {
            enabled: enabled
        };
        return APIService.put('/chat/dm/notifications/enabled', body );
    }

    getChatProfile() {
        return APIService.get('/chat/profile');
    }

    getDmBlocks(query, limit, offset, sortField, sortDir) {
        return APIService.get('/chat/dm/blocked?limit='+limit+'&offset='+offset+ (query ? '&query='+query : '')
            + (sortField ? "&sortField="+sortField : '') + (sortDir ? "&sortDir="+sortDir : ''));
    }

    blockUser(userId) {
        return APIService.post('/chat/dm/block/'+userId, {});
    }

    unBlockUser(analystId) {
        return APIService.delete('/chat/dm/block/'+analystId);
    }

    getSuggestedChannels() {
        return APIService.get('/chat/channels/suggested'); 
    }

   

    searchPublicChannels(query, limit, offset, sortField, sortDir) {
        return APIService.get('/chat/channels/query?limit='+limit+'&offset='+offset+'&query='+query
            +"&sortField="+sortField+"&sortDir="+sortDir); // sortField, sortDir not implemented yet
    }

    searchDMChannels(query, limit, offset, sortField, sortDir) {
        return APIService.get('/chat/dms/query?limit='+limit+'&offset='+offset+'&query='+query
            +"&sortField="+sortField+"&sortDir="+sortDir); // sortField, sortDir not implemented yet
    }

    

    deleteChannel(chatChannelId) {
       
        return APIService.delete('/chat/channels/'+chatChannelId );
    }

    joinChannel(chatChannelId) {
        let joinChannelRequest = {
            requesterId: store.state.users.user.userId,
        }
        return APIService.post('/chat/channels/'+chatChannelId+"/join", joinChannelRequest );
    }

    leaveChannel(chatChannelId) {
        let leaveChannelRequest = {
            requesterId: store.state.users.user.userId,
        }
        return APIService.post('/chat/channels/'+chatChannelId+"/leave", leaveChannelRequest );
    }

    sendMessage(chatChannelId, chatMessage) { // chat message  should be { requesterId : userId, text: text, mentions: [array of mentions via PostUtils.gatherMentionsAndMedia()]}
        return APIService.post('/chat/channels/'+chatChannelId+"/message", chatMessage );
    }

    deleteMessage(messageId) {
        return APIService.delete('/chat/messages/'+messageId);
    }

    createGroupChannel(groupId, name, description, isPrivate, isDiscussion) {
        let createChannelRequest = {
            name: name,
            description : description,
            isPrivate: isPrivate,
            isDiscussion: isDiscussion,
            ownerId : store.state.users.user.userId
        };
        return APIService.post('/chat/'+groupId+'/channels', createChannelRequest );
    }

    searchGroupChannels(groupId, query, limit, offset, sortField, sortDir) {
        return APIService.get('/chat/'+groupId+'/channels/query?limit='+limit+'&offset='+offset+'&query='+query+"&sortField="+sortField+"&sortDir="+sortDir); // sortField, sortDir not implemented yet
    }

    /**
     * Call this when  you want to force other subsequent chat calls to use a new chat client.
     * @param {} context 
     */
    async disconnectUserFromChatClient(context) {
        try{
            //console.log("disconnectUserFromChatClient called from context " +context)
            if( this.chatClient ) {
                
                await this.chatClient.disconnectUser();
            }
        }finally {
           
            this.freeChatClient(context);
        }
    }

    /**
     * Call this when you want to allow other subsequent chat callers to reuse the existing chat client
     * @param {} context 
     */
    async freeChatClient(context) {//eslint-disable-line
        //console.log("freeChatClient from context " +context)
        this.inUse = false;
        
    }
    
    

  
    async connectToChannel(channelData) {
        let channelId = '';
        let channelType = 'messaging';

        if (channelData.data) {
            channelType =  channelData.data.type;
            
            channelId = channelData.data.id;
        } else {
            channelType = channelData.type;

            channelId  = channelData.extChannelId;
        }

        channelType = channelType ? channelType : 'messaging';
        //console.log("connecting to channel "+ channelType+"  with id "+ channelId);
        let client = await this.connectUserToChatClient('connectToChannel');
        return await client.channel(channelType, channelId);
    }
    
    setCurrentChannel(chatChannelId){
        if( chatChannelId )  {
            return APIService.put('/chat/'+store.state.users.user.analyst.analystId+"/current/channel/"+chatChannelId+"/"+NotificationService.endPointInfo.endPointId, null);
        }
        else {
            return APIService.put('/chat/'+store.state.users.user.analyst.analystId+"/current/channel/0"+"/"+NotificationService.endPointInfo.endPointId, null );
        }
    }

}


const ChatService = new ChatServiceImpl();

export default ChatService;