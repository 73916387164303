import { ANALYTICS_ENABLED, APP_ENV } from "@/common/config";
import mixpanel from 'mixpanel-browser';
import EventBus from '../event-bus.js';


class AnalyticsImpl {
    init() {
        if( ANALYTICS_ENABLED == 'true' ) {
            console.log("Analytics is enabled initializing Analytics Service in " + APP_ENV+ " environment.");
            mixpanel.init('1eecfe2fb947a83073814a0a797d6a45', {
                ignore_dnt: true,
                // debug: true,
            });

            

            EventBus.off('track-login');
            EventBus.off('track-signup');
            EventBus.off('track-logout');
            EventBus.off('track-overview');
            EventBus.off('track-financials');
            EventBus.off('track-compare');
            EventBus.off('track-insiders');
            EventBus.off('track-charting');
            EventBus.off('track-notes');
            EventBus.off('track-holdings');
            EventBus.off('track-equityNav');
            EventBus.off('track-fundNav');
            EventBus.off('track-comment');
            EventBus.off('track-postedNote');
            EventBus.off('track-post');
            EventBus.off('track-user-search');
            EventBus.off('track-security-search');
            EventBus.off('track-group-number');
            EventBus.off('deleted-group');
            EventBus.off('groups-click');
            EventBus.off('track-portfolio-visit');
            EventBus.off('track-linked-accounts');

            EventBus.off( 'track-user'); //<-- used to track info about user once they login
            EventBus.off('track-view-post');
            EventBus.off('track-read-post');
            EventBus.off('track-post-link-clicked');
            EventBus.off('track-like-post');
            EventBus.off('track-unlike-post');
            EventBus.off('track-like-comment');
            EventBus.off('track-unlike-comment');
            EventBus.off('track-view-profile');
            EventBus.off('track-follow');
            EventBus.off('track-unfollow');

            EventBus.off('track-create-paper-analysis-account');
            EventBus.off('track-create-paper-trading-account');
            EventBus.off('track-place-trade');
            EventBus.off('track-creating-group');
            EventBus.off('track-creating-team');
            EventBus.off('track-joining-group');
            EventBus.off('track-joining-team');
            EventBus.off('track-creating-group-portfolio');
            EventBus.off('track-sharing-portfolio-on-personal-profile');
            EventBus.off('track-adding-account-to-group-portfolio');
            EventBus.off('track-question');
            EventBus.off('track-join-competition');
            EventBus.off('track-start-join-course');
            EventBus.off('track-complete-join-course');
            
            

            EventBus.on('track-login', this.login);
            EventBus.on('track-signup', this.signup);
            EventBus.on('track-logout', this.logout)
            EventBus.on('track-overview', this.overview)
            EventBus.on('track-financials', this.financials)
            EventBus.on('track-compare', this.compare)
            EventBus.on('track-insiders', this.insiders)
            EventBus.on('track-charting', this.charting)
            EventBus.on('track-notes', this.notes)
            EventBus.on('track-holdings', this.holdings)
            EventBus.on('track-equityNav', this.equityNavEvent)
            EventBus.on('track-fundNav', this.fundNavEvent)
            EventBus.on('track-comment', this.comment)
            EventBus.on('track-postedNote', this.postedNote)
            EventBus.on('track-post', this.post)
            EventBus.on('track-user-search', this.searchUser)
            EventBus.on('track-security-search', this.searchSecurity);
            EventBus.on('track-group-number', this.trackGroupNumber);
            EventBus.on('deleted-group', this.deleteGroup);
            EventBus.on('groups-click', this.groups);
            EventBus.on('track-portfolio-visit', this.portfolioView);
            EventBus.on('track-linked-accounts', this.trackNumberOfLinkedAccounts);

            EventBus.on( 'track-user', this.trackUser);
            EventBus.on('track-view-post', this.trackViewPost);
            EventBus.on('track-read-post', this.trackReadPost);
            EventBus.on('track-post-link-clicked', this.trackClickPostLink);
            EventBus.on('track-like-post', this.trackLikePost);
            EventBus.on('track-unlike-post', this.trackUnLikePost);
            EventBus.on('track-like-comment', this.trackLikeComment);
            EventBus.on('track-unlike-comment', this.trackUnLikeComment);
            EventBus.on('track-view-profile', this.trackViewProfile);
            EventBus.on('track-follow', this.trackFollow);
            EventBus.on('track-unfollow', this.trackUnfollow);

            EventBus.on('track-create-paper-analysis-account', this.trackCreatePaperAnalysisAccount);
            EventBus.on('track-create-paper-trading-account', this.trackCreatePaperTradingAccount);
            EventBus.on('track-place-trade', this.trackPlaceTrade);
            EventBus.on('track-creating-group', this.trackCreatingGroup);
            EventBus.on('track-creating-team', this.trackCreatingTeam);
            EventBus.on('track-joining-group', this.trackJoiningGroup);
            EventBus.on('track-joining-team', this.trackJoiningTeam);
            EventBus.on('track-creating-group-portfolio', this.trackCreatingGroupPortfolio);
            EventBus.on('track-sharing-portfolio-on-personal-profile', this.trackSharingPortfolioOnPersonalProfile);
            EventBus.on('track-adding-account-to-group-portfolio', this.trackAddingAccountToGroupPortfolio);

            EventBus.on('track-question', this.question);
            EventBus.on('track-join-competition', this.trackJoinCompetition);
            EventBus.on('track-start-join-course', this.trackStartJoinCourse);
            EventBus.on('track-complete-join-course', this.trackCompleteJoinCourse);
           


        }
        else {
            console.log("Analytics is disabled skipping initializing Analytics Service");
        }
    }

    /**
     * Tracks some extra info about logged in user we don't get until after the login is authenticated
     */
    trackUser(user) {
        mixpanel.identify(user.username);
        let isSpammer = user.analyst.activityStats.numDeletedPosts > 0 ;
        mixpanel.people.set({ 'userName': user.analyst.name, 'spammer': isSpammer, 'analystId': user.analyst.analystId });
        
    }

    trackViewPost(post) {
        // post has activity and noteVersion
        if( post.noteVersion ) {
            mixpanel.track('view-note-post', {'postId': post.activity.foreign_id, 'postAuthor': post.activity.actor.data.name, 'postAuthorAnalystId': post.activity.actor.id,
                'title': post.activity.title});
        }
        else {
            mixpanel.track('view-status-post', {'postId': post.activity.foreign_id, 'postAuthor': post.activity.actor.data.name, 'postAuthorAnalystId': post.activity.actor.id});
        }
    }

    trackReadPost(postRead) {//eslint-disable-line
        // post has activity and noteVersion
       mixpanel.track('read-post', {'postId': postRead.post.activity.foreign_id, 'postAuthor': postRead.post.activity.actor.data.name, 
       'postAuthorAnalystId': postRead.post.activity.actor.id, 'postReadTimeSeconds' : postRead.readTimeSeconds});
        
    }

    trackClickPostLink(linkClick) {
       
        mixpanel.track('post-link-clicked', linkClick);
    }

    trackLikePost(post) {
        mixpanel.track('like-post', {'postId': post.activity.foreign_id, 'postAuthor': post.activity.actor.data.name, 'postAuthorAnalystId': post.activity.actor.id});
    }
    trackUnLikePost(post) {
        mixpanel.track('unlike-post', {'postId': post.activity.foreign_id, 'postAuthor': post.activity.actor.data.name, 'postAuthorAnalystId': post.activity.actor.id});
    }

    trackLikeComment(comment) {
        
        mixpanel.track('like-comment', {'postId': comment.data.related_post_id, 'commentAuthor': comment.user.data.name, 'commentAuthorAnalystId': comment.user.id, });
    }

    trackUnLikeComment(comment) {
        
        mixpanel.track('unlike-comment', {'postId': comment.data.related_post_id, 'commentAuthor': comment.user.data.name, 'commentAuthorAnalystId': comment.user.id, });
    }

    trackViewProfile(analyst) {
        let isSpammer = analyst.activityStats.numDeletedPosts > 0 ;
        mixpanel.track('view-profile', {'analystId': analyst.analystId, 'analystName': analyst.name, 'spammer': isSpammer });
    }

    trackFollow(analyst) {
        let isSpammer = analyst.activityStats.numDeletedPosts > 0 ;
        mixpanel.track('follow-analyst', {'analystId': analyst.analystId, 'analystName': analyst.name,'spammer': isSpammer  });
    }

    trackUnfollow(analyst) {
        let isSpammer = analyst.activityStats.numDeletedPosts > 0 ;
        mixpanel.track('unfollow-analyst', {'analystId': analyst.analystId, 'analystName': analyst.name, 'spammer': isSpammer  });
    }

    trackCreatePaperAnalysisAccount() {
        try {
            mixpanel.track('create-paper-analysis-account');
        } catch (err) {
            return;
        }
    }

    trackCreatePaperTradingAccount() {
        try {
            mixpanel.track('create-paper-trading-account');
        } catch (err) {
            return;
        }
    }

    trackPlaceTrade() {
        try {
            mixpanel.track('place-trade');
        } catch (err) {
            return;
        }
    }

    trackCreatingGroup(isPrivate) {
        try {
            if(isPrivate){
                mixpanel.track('creating-private-group');
            } else {
                mixpanel.track('creating-public-group');
            }
        } catch (err) {
            return;
        }
    }

    trackCreatingTeam(isPrivate) {
        try {
            if(isPrivate){
                mixpanel.track('creating-private-team');
            } else {
                mixpanel.track('creating-public-team');
            }
        } catch (err) {
            return;
        }
    }

    trackJoiningGroup() {
        try {
            mixpanel.track('joining-group');
        } catch (err) {
            return;
        }
    }

    trackJoiningTeam() {
        try {
            mixpanel.track('joining-team');
        } catch (err) {
            return;
        }
    }

    trackCreatingGroupPortfolio() {
        try {
            mixpanel.track('creating-group-portfolio');
        } catch (err) {
            return;
        }
    }

    trackSharingPortfolioOnPersonalProfile() {
        try {
            mixpanel.track('sharing-portfolio-on-personal-profile');
        } catch (err) {
            return;
        }
    }

    trackAddingAccountToGroupPortfolio() {
        try {
            mixpanel.track('adding-account-to-group-portfolio');
        } catch (err) {
            return;
        }
    }

    login(email) {
        const loginDate = new Date();
        try {
            mixpanel.identify(email);
            mixpanel.people.set({ email, 'last-login': loginDate });
            mixpanel.track('login', {'env': APP_ENV});
            mixpanel.time_event('track-logout');
        } catch (err) {
            return;
        }
    }

    signup(signUpResult) {
        try {
            //console.log("tracking signup", signUpResult);
            mixpanel.track('signup', {'env': APP_ENV});
            mixpanel.identify(signUpResult.email);
            mixpanel.alias(signUpResult.email);
            mixpanel.people.set(
                { 
                    'email': signUpResult.email, 
                    'userName': signUpResult.username, 
                    'experienceLevel': signUpResult.experienceLevel, 
                    'referringPostId': signUpResult.referringPostId,
                    'affiliateCode': signUpResult.affiliateCode
                });
        } catch (err) {
            return;
        }
    }

    logout() {
        try {
            mixpanel.track('track-logout', {'env': APP_ENV});
        } catch (err) {
            return;
        }
    }

    comment(postComment) {
        try {
            //console.log(JSON.stringify(postComment.post));
            mixpanel.track('comment', {'postId': postComment.post.activity.foreign_id, 'postAuthor': postComment.post.activity.actor.data.name, 'postAuthorAnalystId': postComment.post.activity.actor.id});
        } catch (err) {
            return;
        }
    }

    postedNote(type) {
        if (type === "group") {
            try {
                mixpanel.track('postedNoteGroup');
            } catch (err) {
                return;
            }
        }

        if (type === "feed") {
            try {
                mixpanel.track('postedNote')
            } catch (err) {
                return;
            }
        }
    }

    post(type) {
        if (type === "group") {
            try {
                mixpanel.track('postGroup')
            } catch (err) {
                return;
            }
        }

        if (type === "feed") {
            try {
                mixpanel.track('post')
            } catch (err) {
                return;
            }
        }
    }

    question(type) {
        if (type === "group") {
            try {
                mixpanel.track('groupQuestion')
            } catch (err) {
                return;
            }
        }

        if (type === "feed") {
            try {
                mixpanel.track('question')
            } catch (err) {
                return;
            }
        }
    }

    overview() {
        try {
            mixpanel.track('overview')
        } catch (err) {
            return;
        }
    }

    financials() {
        try {
            mixpanel.track('financials')
        } catch (err) {
            return;
        }
    }

    compare() {
        try {
            mixpanel.track('compare')
        } catch (err) {
            return;
        }
    }

    insiders() {
        try {
            mixpanel.track('insiders')
        } catch (err) {
            return;
        }
    }

    charting() {
        try {
            mixpanel.track('charting')
        } catch (err) {
            return;
        }
    }

    notes() {
        try {
            mixpanel.track('notes')
        } catch (err) {
            return;
        }
    }

    holdings() {
        try {
            mixpanel.track('holdings')
        } catch (err) {
            return;
        }
    }

    searchUser() {
        try {
            mixpanel.track('user-search')
        } catch (err) {
            return;
        }
    }

    searchSecurity() {
        try {
            mixpanel.track('security-search')
        } catch (err) {
            return;
        }
    }

    equityNavEvent(path) {
        if (path === '/equity/financials') {
            try {
                EventBus.emit('track-financials')
            } catch (err) {
                return;
            }
        } else if (path === '/equity/compare') {
            try {
                EventBus.emit('track-compare')
            } catch (err) {
                return;
            }
        } else if (path === '/equity/insiders') {
            try {
                EventBus.emit('track-insiders')
            } catch (err) {
                return;
            }
        } else if (path === '/equity/charting') {
            try {
                EventBus.emit('track-charting')
            } catch (err) {
                return;
            }
        } else if (path === '/equity/notes') {
            try {
                EventBus.emit('track-notes')
            } catch (err) {
                return;
            }
        } else return;
    }

    fundNavEvent(path) {
        if (path === '/fund/holdings') {
            try {
                EventBus.emit('track-holdings')
            } catch (err) {
                return;
            }
        } else if (path === '/fund/charting') {
            try {
                EventBus.emit('track-charting')
            } catch (err) {
                return;
            }
        } else if (path === '/fund/notes') {
            try {
                EventBus.emit('track-notes')
            } catch (err) {
                return;
            }
        } else return;
    }

    trackGroupNumber(groupNumber) {
        try {
            mixpanel.people.set({"group-number": groupNumber})
        } catch (err) {
            return;
        }
    }

    portfolioView() {
        try {
            mixpanel.track('portfolio-view')
        } catch (err) {
            return;
        }
    }

    deleteGroup() {
        try {
            mixpanel.track('deleted-group')
        } catch (err) {
            return;
        }
    }

    groups() {
        try {
            mixpanel.track("groups");
        } catch (err) {
            return;
        }
    }

    trackNumberOfLinkedAccounts(numberOfAccounts) {
        try {
            mixpanel.people.set({ 'connected-accounts': numberOfAccounts });
        } catch (err) {
            return;
        }
    }


    trackJoinCompetition(comp) {
        try {
            console.log('tracking join competition', comp)
            mixpanel.track('join-competition', {contestId: comp.contestId, name: comp.name});
        } catch (err) {
            console.log("error tracking join comp", err);
            return;
        }
    }

    trackStartJoinCourse(course) {
        try {
            console.log('tracking start join course', course)
            mixpanel.track('start-join-course', {courseId: course.courseId, name: course.name, groupId: course.groupId});
        } catch (err) {
            console.log("error tracking start join course", err);
            return;
        }
    }

    trackCompleteJoinCourse(courseId) {
        try {
            console.log('tracking complete join course', courseId)
            mixpanel.track('complete-join-course', {courseId: courseId});
        } catch (err) {
            console.log("error tracking complete join course", err);
            return;
        }
    }

   

}

const AnalyticsService = new AnalyticsImpl();

export default AnalyticsService;
