//import { ImageDrop } from "quill-image-drop-module";
//import ImageResize from "quill-image-resize-module";
//import BlotFormatter from 'quill-blot-formatter';
import {SHARE_URL} from '../../common/config';
import BlotFormatter from 'quill-blot-formatter-mobile'
import ImageSpec from 'quill-blot-formatter-mobile/dist/specs/ImageSpec';
import IframeVideoSpec from 'quill-blot-formatter-mobile/dist/specs/IframeVideoSpec';
//import {MOUSE_ENTER_ATTRIBUTE} from 'quill-blot-formatter-mobile/dist/specs/UnclickableBlotSpec';
import * as Emoji from "quill-emoji"; 
import * as mention from "quill-mention"
//import ResizeModule from "@botom/quill-resize-module";

//import QuillBetterTable from 'quill-better-table'
import CKEditorPluginService from '../feed/CKEditorPluginsService';
import ResearchService from '../../service/ResearchService'; 
import BrowserUtils from '../../utilities/BrowserUtils';
import LocalStorageService from '../../service/LocalStorageService';

import ImageUploader from "quill-image-uploader";
import { Quill } from "vue3-editor";

Quill.debug(false);
const MentionBlot = Quill.import("blots/mention");
const Link = Quill.import("formats/link");

/**
 * Bug fix for iframespec, need to set zindex otherwise the blot doesn't show up
 * and u can't resize video.
 */
class CustomIframeSpec extends IframeVideoSpec{
  getZIndex (e) {      
    if( e instanceof Element) {
      var z = window.getComputedStyle(e).getPropertyValue('z-index');
      if (isNaN(z)) return this.getZIndex(e.parentNode);
      return z; 
    }
    else {
      return 'auto';
    }
  }

  repositionProxyImage(unclickable) {
    //console.log("in repositino proxy immage, currenty style:", this.proxyImage.style)
    const rect = unclickable.getBoundingClientRect();
    //console.log("get bounding rec ", rect)
    Object.assign(
      this.proxyImage.style,
      {
        display: 'block',
        left: `${rect.left + window.scrollX}px`,
        top: `${rect.top + window.scrollY}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`,
        'z-index': this.getZIndex(unclickable),
      },
    );
  }
}

/**
 * The following overrides allow the alignment tool for images and videos to work.
 * otherwise when alignment is done, unless the user does some other action (like enter other text) the alignment styles
 * don't take.
 * solution from here: https://github.com/kensnyder/quill-image-resize-module/issues/43#issuecomment-390728983
 */
// BEGIN allow image alignment styles
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

const BaseImageFormat = Quill.import('formats/image');
class CustomImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(CustomImageFormat, true);
// END allow image alignment styles
// BEGIN allow video alignment styles
const BaseVideoFormat = Quill.import('formats/video');
class CustomVideoFormat extends BaseVideoFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(CustomVideoFormat, true);
// END allow video alignment styles

const shareUrl = SHARE_URL;
export const feedEditorModules = [
  {module: Emoji},
  {module: mention},
  {alias: "blotFormatter", module: BlotFormatter},
  {alias: "imageUploader", module: ImageUploader},
  //{alias: "resize", module: ResizeModule},
  //{alias: "better-table", module: QuillBetterTable},
];

export const groupLandingPageEditorModules = [
  {module: Emoji},
  {module: mention},
  {alias: "blotFormatter", module: BlotFormatter},
  {alias: "imageUploader", module: ImageUploader},
  //{alias: "resize", module: ResizeModule},
  //{alias: "better-table", module: QuillBetterTable},
];

export const noteEditorModules = [
  {module: Emoji},
  {module: mention},
  {alias: "blotFormatter", module: BlotFormatter},
  {alias: "imageUploader", module: ImageUploader},
  //{alias: "resize", module: ResizeModule},
  //{alias: "better-table", module: QuillBetterTable},
];

export const feedCommentEditorModules = [
  {module: Emoji},
  {module: mention},
 {alias: "blotFormatter", module: BlotFormatter},
  {alias: "imageUploader", module: ImageUploader},
  // {alias: "resize", module: ResizeModule},
  //{alias: "better-table", module: QuillBetterTable},
];

export const chatEditorModules = [
  {module: Emoji},
  {module: mention},
  {alias: "blotFormatter", module: BlotFormatter},
  //{alias: "resize", module: ResizeModule},
  {alias: "imageUploader", module: ImageUploader},
  //{alias: "better-table", module: QuillBetterTable},
];


export const emailEditorModules = [
  {module: Emoji},
  {alias: "blotFormatter", module: BlotFormatter},
  // {alias: "resize", module: ResizeModule},
  {alias: "imageUploader", module: ImageUploader},
  //{alias: "better-table", module: QuillBetterTable},
];


export const motionEditorModules = [
  {module: Emoji},
  {alias: "blotFormatter", module: BlotFormatter},
  //{alias: "resize", module: ResizeModule},
  {alias: "imageUploader", module: ImageUploader},
  //{alias: "better-table", module: QuillBetterTable},
];




const GroupLandingPageEditorToolbar = [
    
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  ["bold", "italic", "underline"],
 
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ["link", "image", "video"],
  ['emoji'],
  //['table']
];

const feedEditorToolbar = [
    
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline"],
   
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ color: [] }],
    ["link", "image", "video"],
    ['emoji'],
    //['table']
  ];

  const mobileFeedEditorToolbar = [
   
    ["bold", "italic"],
   
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ['emoji'],

  ];


  const feedCommentEditorToolbar = [
   
    
    [ "image"],
    ['emoji'],

  ];

  
  const mobileChatEditorToolbar = [
   
    [ "image"],
    ['emoji'],

  ];
  const chatEditorToolbar = [
   
    
    [ "image"],
     ['emoji'],

  ];

  
  const mobileFeedCommentEditorToolbar = [
   
    [ "image"],
    ['emoji'],

  ];

  const emailEditorToolbar = [
    
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline"],
   
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ color: [] }],
    ["link", "image"],
    ['emoji'],
    //['table']
  ];

  const motionEditorToolbar = [
    
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline"],
   
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ color: [] }],
    ["link", "image"],
    ['emoji'],
    //['table']
  ];


  const searchMentions = async function(searchTerm, renderList,mentionChar) {
    if (mentionChar === "@") {
        const matchedPeople = await CKEditorPluginService.searchAnalysts(searchTerm);
        renderList(matchedPeople);
      } else if(mentionChar === "#") {
        const matchedTags = await CKEditorPluginService.searchTags(searchTerm);
        renderList(matchedTags);
      }
      else {
        const matchedTags = await CKEditorPluginService.searchMarket(searchTerm);
        renderList(matchedTags);
      }
    
};

  const renderMentionSearch =  function(item, searchTerm) {//eslint-disable-line
    //console.log("item ", item);
    if( item.id ) {
      item['value'] = item.id;
      item['mention'] = item.id;
    }
    
    if( item.mentionType == 'analyst') {
      
        return CKEditorPluginService.analystRenderer(item);
    }
    else if( item.mentionType == 'tag'){
      
        return CKEditorPluginService.tagRenderer(item);
    }
    else {
        
        return CKEditorPluginService.securityRenderer(item);
    }
  
};
  

const mentionConfig = {
  minChars: 1,
  blotName: "custom-mention",
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ["@", "#", "$"],
  source: searchMentions,
  renderItem: renderMentionSearch,
  positioningStrategy: 'fixed',
  fixMentionsToQuill: false,
  showDenotationChar: false,
  dataAttributes: ['id', 'value', 'denotationChar', 'mentionType', 'mention', 'tagId', 'exchange', 'secType','link', 'target', 'disabled','username', 'analystId', 'securityId', 'fundId', 'sectorId', 'industryId', 'exchangeId'],

}

const matchImagUrls = function(node, delta) {
  var regex = /https?:\/\/[^\s]+/g;

  if(typeof(node.data) !== 'string') return;

  var matches = node.data.match(regex);

  if(matches && matches.length > 0) {
      var ops = [];
      var str = node.data;

      matches.forEach(function(match) {
          var split = str.split(match);
          if(match.match(/\.(png|jpg|jpeg|gif|webp)$/)!=null){
                let beforeLink = split.shift();                     
                ops.push({ insert: beforeLink });
                ops.push({ insert:{image: match}, attributes: { link: match } 
            });

          str = split.join(match);                    
          } else { //if link is not an image
              let beforeLink = split.shift();
              ops.push({ insert: beforeLink });                        
              ops.push({ insert: match, attributes: { link: match } }); 
              str = split.join(match);
          }                                                     
      });
      ops.push({ insert: str });
      delta.ops = ops;
  }                   
  return delta;
};

  export function createFeedEditorSettings(bounds) {

    return {
      debug: 'error',
      bounds: bounds,
      modules: {
          blotFormatter: {
            specs: [CustomIframeSpec, ImageSpec]
          },
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": false,
          mention: mentionConfig,
          toolbar: {
            container : (BrowserUtils.isMobile() ? mobileFeedEditorToolbar : feedEditorToolbar),
          },
          imageUploader: {
            upload: imageUpload
          },
          clipboard: {
            matchers: [
              [Node.TEXT_NODE, matchImagUrls]
            ]
          },
          // resize: {
           
          //   locale: {}
          // }

          
      }
    };
  }

  export function createGroupLandingPageEditorSettings(bounds) {

    return {
      debug: 'error',
      bounds: bounds,
      modules: {
          blotFormatter: { specs: [CustomIframeSpec, ImageSpec]},
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": false,
          mention: mentionConfig,
          toolbar: {
            container : GroupLandingPageEditorToolbar,
          },
          imageUploader: {
            upload: imageUpload
          },
          clipboard: {
            matchers: [
              [Node.TEXT_NODE, matchImagUrls]
            ]
          },
          // resize: {
           
          //   locale: {}
          // }

          
      }
    };
  }

  export function createNoteEditorSettings(bounds) {

    return {
      debug: 'error',
      bounds: bounds,
      modules: {
          blotFormatter: { specs: [CustomIframeSpec, ImageSpec]},
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": false,
          mention: mentionConfig,
          toolbar: {
            container : feedEditorToolbar,
          },
          imageUploader: {
            upload: imageUpload
          },
          clipboard: {
            matchers: [
              [Node.TEXT_NODE, matchImagUrls]
            ]
          },
          // resize: {
           
          //   locale: {}
          // }

          
      }
    };
  }

  export function createEmailEditorSettings(bounds) {
    return {
      debug: 'error',
      bounds: bounds,
      modules: {
          blotFormatter: { specs: [CustomIframeSpec, ImageSpec]},
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": false,
          toolbar: {
            container : emailEditorToolbar,
          },
          imageUploader: {
            upload: imageUpload
          },
          clipboard: {
            matchers: [
              [Node.TEXT_NODE, matchImagUrls]
            ]
          },
         
          

          
      }
    };
  }

  export function createMotionEditorSettings(bounds) {
    return {
      debug: 'error',
      bounds: bounds,
      modules: {
          blotFormatter: { specs: [CustomIframeSpec, ImageSpec]},
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": false,
          toolbar: {
            container : motionEditorToolbar,
          },
          imageUploader: {
            upload: imageUpload
          },
          clipboard: {
            matchers: [
              [Node.TEXT_NODE, matchImagUrls]
            ]
          },
          // resize: {
           
          //   locale: {}
          // }

          
      }
    };
  }


  export function createFeedCommentEditorSettings(bounds) {

    return {
      debug: 'error',
      bounds: bounds,
      modules: {
          blotFormatter: { specs: [CustomIframeSpec, ImageSpec]},
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": false,
          mention: mentionConfig,
          toolbar: {
            container : (BrowserUtils.isMobile() ? mobileFeedCommentEditorToolbar : feedCommentEditorToolbar),
          },
          imageUploader: {
            upload: imageUpload
          },
          clipboard: {
            matchers: [
              [Node.TEXT_NODE, matchImagUrls]
            ]
          },
         
        
      }
    };
  }

  export function createChatEditorSettings(bounds, enterHandler) {

    return {
      debug: 'error',
      bounds: bounds,
      modules: {
          blotFormatter: { specs: [CustomIframeSpec, ImageSpec]},
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": false,
          mention: mentionConfig,
          toolbar: {
            container : (BrowserUtils.isMobile() ? mobileChatEditorToolbar : chatEditorToolbar),
          },
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                altKey: false,
                handler: function () {
                  enterHandler();
                  return false;
                },
                
              }
            }
          },
          imageUploader: {
            upload: imageUpload
          },
          clipboard: {
            matchers: [
              [Node.TEXT_NODE, matchImagUrls]
            ]
          },
          
          
        
        
      }
    };
  }
/*
  export function handleImageAdded(file, Editor, cursorLocation) {//,resetUploader
    // An example of using FormData
    // NOTE: Your key could be different such as:
    // formData.append('file', file)


    ResearchService.uploadImage(file).then(resp => {
                   
      if( resp.data.status == "success"){
         // resolve({ "default": resp.data.url});
          const url = resp.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
      }
      else {
          console.log("error uploading file "+ resp.data.message);
          
      }
  });
  

}
*/

// Used by ImageUploader module
export async function imageUpload(file) {

  return new Promise((resolve,reject) => {
    ResearchService.uploadImage(file).then(resp => {
                 
      if( resp.data.status == "success"){
        // resolve({ "default": resp.data.url});
          resolve (resp.data.url); // Get url from response
          //Editor.insertEmbed(cursorLocation, "image", url);
      }
      else {
          console.log("error uploading file "+ resp.data.message);
          reject("resp.data.message");
          
      }
    });
  });


}


export function gatherMentionsAndMedia(editor) {
  let delta = editor.quill.getContents();
  let mentions = [];
  let mediaUrls = [];
  try {
  
        for( let op of delta.ops ){
          //console.log("op = ", op)
          if( op.insert?.image) {
            mediaUrls.push( {type: 'image', url:op.insert.image});
          }
          else if( op.insert?.video){
            mediaUrls.push( {type: 'video', url:op.insert.video});
          }
          else if( op.insert?.['custom-mention']) {
            if( op.insert['custom-mention'].mentionType) {  //TODO: currently updates can't update mentions
              mentions.push(op.insert['custom-mention']);
            }
          }
        }
    
  }catch(error) {
    console.log("error gathering mentions and media from editor", error);
   
  }
  return { mentions : mentions, mediaUrls: mediaUrls};
}

var autoSaveTimer = null;



export class QuillAutoSaveHandler {
  running = false;
  context = null;
  editor = null;
  doc = {
    type: null,
    noteId: null,
    title: null,
    content: null,
  };
  originalContent = null;
  dirty = false;
  

  

  constructor( context, contentProvider, originalDoc) {
    //console.log("new AutoSaveHandler created :"+context);
    this.context = context;
    this.contentProvider = contentProvider;
    this.originalDoc = originalDoc;
    this.stopAutoSaveTimer();
   
    this.dirty = false;
    this.doc = {
      noteId: null,
      type: null,
      title: null,
      content: null,
    
    };
   
    if(this.originalDoc) {
      this.doc.noteId = this.originalDoc.noteId;
      this.doc.type = this.originalDoc.type;
      this.doc.title = this.originalDoc.title;
      this.doc.content = this.originalDoc.content;
      
    }
    else {
      let storedDoc = LocalStorageService.getObject(this.context);
      if( storedDoc ){
        this.doc.noteId = storedDoc.noteId;
        this.doc.type = storedDoc.type;
        this.doc.title = storedDoc.title;
        this.doc.content = storedDoc.content;
       
       
        
     }
    }
    this.running = true;
    this.startAutoSaveTimer();
    
  }

  pause() {
    this.running = false;
    
  }
   resume() {
    this.running = true;
   }

  dispose() {
    this.running = false;
    this.dirty = false;
    this.clearUnsavedChanges();
    this.stopAutoSaveTimer();
    //console.log("AutoSaveHandler disposed :"+this.context);
  }

  hasUnsavedChanges() {
    if( this.doc.content ) {
      
      if( this.originalDoc){
        //console.log( "comparing ["+this.originalDoc.content+"] with ["+this.doc.content+"]")
        return !(this.originalDoc.content === this.doc.content);
      }
      else {
        return true;
      }
     
    }
    else {
      return false;
    }
  }

  hasUnsavedNoteChanges() {
    if( this.doc.type != 'note') {
      return false;
    }
    else {
      return this.hasUnsavedChanges();
    }
  }

  
  getUnsavedChanges(){
    
    return this.doc;
  }

  persistChanges(){
      if( this.running ) {
        if( this.dirty  ){ //|| !(this.contentProvider.getContent() === this.doc.content)) {
          //this.doc.content = this.contentProvider.getContent();
          LocalStorageService.storeObject(this.context, this.doc);
          //console.log("persisting unsaved changes for context "+this.context+" : ", this.doc.content)
        }
        //console.log("persisting unsaved changes for context "+this.context+" : ", this.content), 
        //LocalStorageService.storeObject(this.context, this.doc);
        
        this.dirty = false;
      }
      

   
  }

  
  cacheUnsavedContentChange(content){
    if( this.running ){
      
      if( !(content === this.doc.content)){
        //console.log( "cache compare found diff between following");
        //console.log(content);
        //console.log(this.doc.content);
        this.doc.content = content;
        this.dirty = true;
      }
    }
  }
  

  cacheUnsavedTitleChange(title){
    if( this.running ){
      this.doc.title = title;
      this.dirty = true;
      
    }
  }

  cacheUnsavedTypeChange(type){
    this.doc.type = type;
    this.dirty = true;
  }

  clearUnsavedChanges() {
    //console.log("clearning unsaved change cache ("+this.context+")");
    this.doc  = {
      noteId: null,
      type: null,
      title: null,
      content: null,
    }
    LocalStorageService.removeObject(this.context);
    this.dirty = false;

  }

  startAutoSaveTimer() {
    autoSaveTimer = setInterval(()=>{this.persistChanges()}, 5000);
    //console.log("started autosave timer "+autoSaveTimer)
  }

  stopAutoSaveTimer() {
    if( autoSaveTimer != null ){
      //console.log("stopping autosave timer "+autoSaveTimer)
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    }
  }


}

export function clearAllUnsavedChanges() {
  //console.log("clearning all unsaved changes ");
  LocalStorageService.removeObject("statusEditorDialog");
  LocalStorageService.removeObject("notes");
  
}
class CustomLink extends Link {

 
  static create(url) {
    let node = super.create(url);
    if( url ) {
      if( url.startsWith(shareUrl)) {
        node.setAttribute('onclick', 'window.CKEditorPluginService.onSharedPostLinkClicked(event)');
      }
      else if(!url.startsWith(shareUrl) && !url.startsWith('/index.html#')){
        node.setAttribute('onclick', 'window.CKEditorPluginService.onNonSharedPostLinkClicked(event)');
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
      }
      else {
        // internal app link
        node.removeAttribute('target');
        node.removeAttribute('rel');
      }
    }
    
    return node;
  }
}

/*
analsyt
<a class="mention analyst-mention" data-mention="@adampelcher26" data-analyst-id="322" data-analyst-username="adampelcher26" href="#" onclick="window.CKEditorPluginService.onMentionClicked(event)" data-sec-type="" data-security-id="" data-sector-id="" data-sector-name="" data-industry-id="" data-industry-name="" data-exchange-id="" data-exchange="" data-mention-type="analyst" data-tag-id="" data-fund-id="" data-index-id="">@adampelcher26</a>

tag
<a class="mention tag-mention" data-mention="#Industrial Conglomerates" data-analyst-id="" data-analyst-username="" href="/index.html#/tag/profile/93" onclick="" data-sec-type="" data-security-id="" data-sector-id="" data-sector-name="" data-industry-id="" data-industry-name="" data-exchange-id="" data-exchange="" data-mention-type="tag" data-tag-id="93" data-fund-id="" data-index-id="">#Industrial Conglomerates</a>

security
<a class="mention security-mention" data-mention="$AAPL" data-analyst-id="" data-analyst-username="" href="#" onclick="window.CKEditorPluginService.onMentionClicked(event)" data-sec-type="equity" data-security-id="2755" data-sector-id="12" data-sector-name="Electronic Technology" data-industry-id="372" data-industry-name="Telecommunications Equipment" data-exchange-id="6" data-exchange="XNAS" data-mention-type="security" data-tag-id="" data-fund-id="" data-index-id="">$AAPL</a>

*/
class CustomMentionBlot extends MentionBlot {
  static render(data) {
    //console.log("rendering: ", data);
    const element = document.createElement('a');
    element.dataset.mentionType = data.mentionType;
    element.dataset.mention = data.mention;
    if( data.mentionType == 'analyst'){
      element.setAttribute('class', 'mention analyst-mention');
      element.setAttribute('onclick',  'window.CKEditorPluginService.onMentionClicked(event)');
      element.href = "#";
      element.dataset.analystUsername = data.username;
      
    }else if( data.mentionType == 'tag') {
      element.setAttribute('class', 'mention tag-mention');
      element.href = "/index.html#/tag/profile/"+data.tagId;
    }
    else {
      element.setAttribute('class','mention security-mention');
      //element.onclick = window.CKEditorPluginService.onMentionClicked;
      element.setAttribute('onclick',  'window.CKEditorPluginService.onMentionClicked(event)');
      element.href = "#";
      element.dataset.secType = data.secType;
      element.dataset.exchange = data.exchange;
      
    }
    element.innerText = data.value;
   // element.style.color = data.color;
    return element;
  }
}
CustomMentionBlot.blotName = "custom-mention";

Quill.register(CustomMentionBlot);
Quill.register(CustomLink);