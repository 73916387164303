import APIService from '@/common/APIService'
import {LOGIN_URL, SIGNUP_URL} from '@/common/config'
import store from '../store';
import CacheService from './CacheService';
import CryptoJS  from 'crypto-js'


class UserServiceImpl {
 
  decrypt(messageToDecrypt){//eslint-disable-line
    let start = store.state.token.length - 16;
    var secret = btoa(store.state.token.substring(start));//CryptoJS.enc.Base64.parse(encryptedBase64Key);
    //console.log("encoded key:", secret);
    //console.log("cipher text:"+ messageToDecrypt);
    
    var decryptedData = CryptoJS.AES.decrypt( messageToDecrypt, CryptoJS.enc.Base64.parse(secret), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
      } );


    // this is the decrypted data as a string
    var decryptedText = decryptedData.toString( CryptoJS.enc.Utf8 );
    //console.log( "DecryptedText = " + decryptedText );
    return decryptedText;

    
}



  

  queryAnalysts(query, limit, page, followersOnly) { //eslint-disable-line
    let url = '/user/query/analysts?limit='+limit+'&page='+page+'&q='+query;
    return CacheService.get(url, 60000);// make sure the cache is refresh every minute
    
   
  }

  getAnalystByUsername(name){
    return APIService.get('/user/analysts/name/'+name);
  }

  checkUserNameAvailable(username) {
    return APIService.get('/user/username/check?username='+username);
  }

  checkFullNameValid(fullname) {
    return APIService.get('/user/fullname/check?fullName='+fullname);
  }


  createAccount(data) {
    return APIService.post(SIGNUP_URL, data);
   
  }

  
  verifyCaptcha(token, email, password) {
    return APIService.post('/user/captcha/verify', { token: token, email: email, password: password});
  }

  login( email, password){
    return APIService.post(LOGIN_URL, {email: email, password: password});
  }

  socialLogin(socialAuthCode, socialAccountType){
    return APIService.post(LOGIN_URL, {socialAuthCode: socialAuthCode, socialAccountType: socialAccountType});
  }

  loginAffiliate( email, password){
    return APIService.post('/user/affiliate/login', {email: email, password: password});
  }
  getAffiliateStats() {
    return APIService.get('/user/affiliate/'+store.state.affiliate.affiliateId+"/stats");
  }

  completeRegistration(userId) {
    return APIService.put('/user/'+userId+'/register');
  }

  updateAnalystProfile(analystId, data) {
    return APIService.put('/user/analyst/'+analystId, data);
  }
  

  getSignupStatus() {
    return APIService.get('/user/signup/status');
  }

  requestPasswordReset(email){
    return APIService.post('/user/password/reset/start', { email: email});
  }
  resetPassword(email, password, token) {
    return APIService.post('/user/password/reset/complete', { email: email, password: password, token: token});
  }

  submitSupportTicket(ticket) {
    return APIService.post('/user/support/ticket',ticket);
  }

  submitReport(report) {
    return APIService.post('/user/support/report',report);
  }


  getSessionCount() {
    return APIService.get('/user/session/count');
  }

  queryFollowers(analystId, query, limit, offset)  {
    return APIService.get('/user/'+analystId+'/followers?limit='+limit+'&page='+offset+'&q='+query);
   
  }

  queryFollowing(analystId, query, limit, offset)  {
    return APIService.get('/user/'+analystId+'/following?limit='+limit+'&page='+offset+'&q='+query);
   
  }

  getPresence(analystId){
    return  APIService.get('/user/'+analystId+'/presence');
  }
  
  kick(analystId) {
    return APIService.post('/user/'+analystId+'/kick', {});
  }

  getUserLink(analystId) {
    return APIService.get('/user/'+analystId+'/links');

  }

  getLatestUserRecord() {
    return APIService.get('/user/latest');
  }

  generateAvatar() {
    return APIService.get('/user/avatar/generate');
}

  updateUserPreference(prefId, value) {
    return APIService.put('/user/preference/' + prefId, {'value': value});

  }

  switchToAccount(analystId) {
    return APIService.post('/user/analyst/'+analystId+'/switch', {});
  }

  switchToGroupOwnerAccount(groupId) {
    return APIService.post('/user/group/'+groupId+'/switch', {});
  }

  switchBack(analystId) {
    return APIService.post('/user/analyst/'+analystId+'/unswitch', {});
  }

  getQuoteOfTheDay() {
    return APIService.get('/user/quote');
  }

  deleteAccount() {
    return APIService.delete('/user/account/delete');
  }

  registerForContest(userId, contestId, code) {
    return APIService.post('/user/register/contest/' + contestId, {userId: userId, registrationCode: (code ? code : null)});
  }

  withdrawFromContest(contestId){
    return APIService.delete('/user/register/contest/' + contestId);
  }

  removeFromContest(comp, analyst){
    return APIService.delete(`/user/register/contest/${comp.contestId}/user/${analyst.userId}`);
  }

  checkAnalysisToolsUsageRate(tool, assetId) {
    
    return APIService.post('/user/tools/usage/check', {tool: tool, assetId: (assetId? assetId : 0)});
  }

  getDecodedGroupContestId(encodedContestId){
    return APIService.get(`/user/register/contest/${encodedContestId}/decode`);
  }
}

const UserService = new UserServiceImpl();
export default UserService;