import APIService from '@/common/APIService'

import cache from 'memory-cache';

const REFETCH_INTERVAL = 60000; // check for refetch requests every minute
/**
 * Provides an in memory only  caching service where you can optionally specify a ttl for the cache items.
 * Each time the cache has a hit it checks the ttl for that cache item and queues a refetch
 * if the ttl is too old while still immediately returning.
 */
class CacheServiceImpl {
    cache = null;
    refetchQueue = null;
    refetching = false;

    constructor() {
      this.cache =  new cache.Cache();
      this.refetchQueue = new Map();
      this.refetching = false;
      //setInterval(() => {this.refetch()}, REFETCH_INTERVAL);
    }

    /**
     * 
     * @param {the rest api url used as the key to the cache} url 
     * @param {(optional) time to live for results in cache, after which a refetch is done to freshing it up} ttl 
     * @returns 
     */
    get( url, ttl ) {
        return new Promise((resolve, reject) => { //eslint-disable-line
     
            let now = new Date();
           
            let actTtl = (ttl? ttl : REFETCH_INTERVAL);
           
            let results = this.cache.get(url);
            if( results ) {
              
              if( now.getTime() - results.lastRefetch  > results.ttl ) {
                APIService.get(url).then( response => {
                    if( response.data ) {
                      this.cache.put(url, {lastRefetch: now.getTime(), response: response, ttl:actTtl});
                     
                    }
                    resolve(response);
                    
                  });
              }
              else {
                resolve(results.response);
              }
            }
            else {
              APIService.get(url).then( response => {
                if( response.data ) {
                  this.cache.put(url, {lastRefetch: now.getTime(), response: response, ttl:actTtl});
                 
                }
                resolve(response);
                
              });
            }
          });
    }

    queueRefetch(url, ttl) {
        if( !this.refetchQueue.has(url) ) {
           
            
            this.refetchQueue.set(url,ttl);
        }
    }

    refetch() {
        if( !this.refetching ) {
            this.refetching = true;
            
            for (const url of this.refetchQueue.keys()) {
                try {
                    let ttl = this.refetchQueue.get(url);
               
                    let now = new Date();
                    
                    
                    APIService.get(url).then( response => {
                        if( response.data ) {
                            
                            this.cache.put(url, {lastRefetch: now.getTime(), response: response, ttl:ttl});
                        
                        }
                    })
                    .catch(err => {
                        console.warn("refetch failed (" + err+") for cache of "+ url + " clearing cache to be safe");
                        this.cache.del(url) 
                        
                        
                    } );
                        
                   
                }catch( error ) {
                    console.warn( "error during refetch cycle " + error);
                }
                finally {
                    this.refetchQueue.delete(url);
                }
                
                
            }
           
            this.refetching = false;
        }
        
    }


}

const CacheService = new CacheServiceImpl();
export default CacheService;