import ImpressionsDetector,  { ImpressionEventType } from "@benshi.ai/impressions-detector";


const statusImpressionOptions = {
    triggerInterval: 3000, // how often it posts look updates
    keepVisibleTimeout: 1000, // how long status post must be visible to count as impression
    intersectionThreshold: 0.5 // how much of status post must be visible to count as impression
}

class StatusImpressionsDetectorImpl {

   
    statusImpressionsDetector = null;
    currenImpressionCallback = null;

    constructor() {
        if (StatusImpressionsDetectorImpl._instance) {
            return StatusImpressionsDetectorImpl._instance
        }
        StatusImpressionsDetectorImpl._instance = this;
      
        this.statusImpressionsDetector = new ImpressionsDetector(statusImpressionOptions)
        
    }

    getInstance() {
        return new StatusImpressionsDetectorImpl();
    }

    start(listingClass, itemClass, impressionCallback) {
        try {
            this.statusImpressionsDetector.start(listingClass, itemClass, {});
            if( this.currenImpressionCallback != null ) {
                try {
                    this.statusImpressionsDetector.off(ImpressionEventType.Impression, this.currenImpressionCallback);
                }catch( stopErr) {
                    // ignore
                }
            }
            this.currenImpressionCallback = impressionCallback
            try {
                this.statusImpressionsDetector.off(ImpressionEventType.Impression, this.currenImpressionCallback);
            }catch( offErr) {
                // ignore
            }
            this.statusImpressionsDetector.on(ImpressionEventType.Impression, this.currenImpressionCallback );
        }catch( err) {
            //console.warn("error detected starting status impression detector (ignoring): "+ err);
        }
    }

    stop() {
        try {
            this.statusImpressionsDetector.stop();
            
        }catch( err) {
            //console.warn("error detected stopping status impression detector (ignoring): "+ err);
        }
        try {
            if( this.currenImpressionCallback != null ) {
                this.statusImpressionsDetector.off(ImpressionEventType.Impression, this.currenImpressionCallback);
            }
        }catch(offErr) {
            //console.warn("error detected turning off impression detector (ignoring): "+ offErr);
        }
    }
}

const StatusImpressionsDetector = new StatusImpressionsDetectorImpl();
export default StatusImpressionsDetector;