
import ResearchService from '../../service/ResearchService'; 

export default class ImageUploader {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    async upload() {
        
        return this.loader.file.then(file => new Promise((resolve, reject) => {//eslint-disable-line
                ResearchService.uploadImage(file).then(resp => {
                   
                    if( resp.data.status == "success"){
                        resolve({ "default": resp.data.url});
                    }
                    else {
                        console.log("error uploading file "+ resp.data.message);
                        reject(resp.data.url);
                    }
                });
               


            }));
    }


}





