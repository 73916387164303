import { createApp, h, reactive, defineAsyncComponent } from 'vue';

import router from './router';
import store from './store';

import PrimeVue from 'primevue/config';
/* Some global imports of common ui components we'll need right away */
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import SelectButton from 'primevue/selectbutton';
import Ripple from 'primevue/ripple';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import simplebar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import "quill-emoji/dist/quill-emoji.css";
import 'quill-image-uploader/dist/quill.imageUploader.min.css';

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    next();
});

import { ObserveVisibility } from 'vue-observe-visibility';
import { LOGOUT_PAGE } from '@/common/config'; // eslint-disable-line
// import VueSSE from 'vue-sse';
import EventBus from './event-bus';

import HighchartsVue from 'highcharts-vue';

import Highcharts from 'highcharts';
import 'highcharts/css/annotations/popup.css';
import 'highcharts/css/stocktools/gui.css';

import Stock from 'highcharts/modules/stock';

import HIndicatorsAll from 'highcharts/indicators/indicators-all';
import HDragPanes from 'highcharts/modules/drag-panes';
import HAnnotations from 'highcharts/modules/annotations';
import HAnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import HPriceIndicator from 'highcharts/modules/price-indicator';
import HFullScreen from 'highcharts/modules/full-screen';
import HStockTools from 'highcharts/modules/stock-tools';

import 'video.js/dist/video-js.min.css';

Stock(Highcharts);
HIndicatorsAll(Highcharts);
HDragPanes(Highcharts);
HAnnotations(Highcharts);
HAnnotationsAdvanced(Highcharts);
HPriceIndicator(Highcharts);
HFullScreen(Highcharts);
HStockTools(Highcharts);

import Moment from 'moment'; //eslint-disable-line
import MomentTimeZone from 'moment-timezone';
MomentTimeZone();

import BadgeDirective from 'primevue/badgedirective';
import AppService from './service/AppService'; // eslint-disable-line
import LocalStorageService from './service/LocalStorageService';
import NotificationService from './service/NotificationService'; // eslint-disable-line
import ChatService from './service/ChatService';
import AuthService from './service/AuthService';
import AnalyticsService from './service/AnalyticsService';

import VueClickAway from 'vue3-click-away';
import Vue3TouchEvents from 'vue3-touch-events';
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

import VueConfetti from 'vue-confetti';

import { install } from "resize-observer";

import VueCryptojs from 'vue-cryptojs'
import VueGtag from "vue-gtag";
// import Vue3Marquee from 'vue3-marquee'
// import 'vue3-marquee/dist/style.css';

// import Flicking from "../dist/flicking.esm";
// import "@egjs/vue3-flicking/dist/flicking.css";

//import App from './App.vue';
const App = defineAsyncComponent(() => import('./App.vue'));

class INVRSImpl {
   
    app = null;

    constructor() {
        let path = window.location.pathname + window.location.hash;
        
        AnalyticsService.init();

        install();

        if (this.requiresAuthentication(path)) {
            console.debug('Checking Authorization...');
            AuthService.waitForAuthorization(60000).then(
                () => {
                    try{
                        LocalStorageService.restoreAppState();
                        this.initApp();
                        NotificationService.initPostAuth();
                        if( !store.state.hasProxy ) {
                            NotificationService.start(store.state.users.user.analyst.analystId);
                        }
                        ChatService.init();
                    }catch(err){
                        console.warn("error during initialization", err)
                        AuthService.logout();
                    }
                    
                },
                error => {
                    console.debug('error attempting to authorize ' + error + '- redirecting to login');

                    window.location.href = LOGOUT_PAGE;
                }
            );
        } else {
            //console.log("no auth needed initializing app");
            this.initApp();
            NotificationService.initPreAuth();
           
        }
    }

    requiresAuthentication(path) {
        return (
            !path.startsWith('/index.html#/login') &&
            !path.startsWith('/index.html#/signup') &&
            !path.startsWith('/index.html#/affiliate') &&
            !path.startsWith('/index.html#/reset') &&
            !path.startsWith('/index.html#/shared') &&
            !path.startsWith('/index.html#/emailConfirmed') &&
            !path.startsWith('/index.html#/groupPreview') 
        );
    }

    initApp() {
        const app = createApp({
            computed: {
                ViewComponent() {
                    //console.log("path is "+ JSON.stringify(this.$route.fullPath));
                    if (this.$route.path.startsWith('/signup')) {
                        return defineAsyncComponent(() => import('./pages/Signup.vue'));
                    } else if (this.$route.path.startsWith('/groupPreview') || !AppService.isAppDomain()) {
                        return defineAsyncComponent(() => import('./pages/GroupPreview.vue'));
                    } else if (this.$route.path.startsWith('/emailConfirmed')) {
                        return defineAsyncComponent(() => import('./pages/EmailConfirmed.vue'));
                    } else if (this.$route.path.startsWith('/login')) {
                        return defineAsyncComponent(() => import('./pages/Login.vue'));
                    } else if (this.$route.path.startsWith('/affiliate/login')) {
                        return defineAsyncComponent(() => import('./pages/AffiliateLogin.vue'));
                    } else if (this.$route.path.startsWith('/affiliate/home')) {
                        return defineAsyncComponent(() => import('./pages/AffiliateHome.vue'));
                    } else if (this.$route.path.startsWith('/reset')) {
                        return defineAsyncComponent(() => import('./pages/ResetPassword.vue'));
                    } else if (this.$route.path.startsWith('/shared')) {
                        return defineAsyncComponent(() => import('./pages/SharedPost.vue'));
                  
                    } else {
                        
                        return App;//defineAsyncComponent(() => import('./App.vue'));
                    }
                },
            },
            render() {
                return h(this.ViewComponent);
            },

            beforeCreate() {
                // console.debug("in before create");
                //console.log("creating view component ")
            },
        });

        app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });
        app.config.globalProperties.$primevue = reactive({ ripple: true });
        app.config.globalProperties.EventBus = EventBus;
        app.use(PrimeVue);
        app.use(HighchartsVue);
        app.use(ToastService);
        app.use(router);
        app.use(store);
      
        app.use(VueClickAway);
        app.use(Vue3TouchEvents);
        app.use(VueShepherdPlugin);
        app.use(VueConfetti);
        app.use(VueCryptojs);

        app.use(VueGtag, {
            includes: [
                { id: 'G-4GL1H5N03X' }
            ],
            config: { id: "AW-10876880460" } //gtag('config', 'AW-10876880460');
          });
          //

        //console.log(app);
        //app.use(Vue3Marquee);

        // app.use(VueSSE, {
        //     format: 'json',
        //     polyfill: true,
        //     withCredentials: true,
        // });

        app.directive('ripple', Ripple);
        app.directive('observe-visibility', {
            beforeMount: (el, binding, vnode) => {
                vnode.context = binding.instance;
                ObserveVisibility.bind(el, binding, vnode);
            },
            update: ObserveVisibility.update,
            unmounted: ObserveVisibility.unbind,
        });

        app.directive('badge', BadgeDirective);
        app.directive('tooltip', Tooltip);

        app.component(simplebar);
        app.component('AutoComplete', AutoComplete);
        app.component('Breadcrumb', Breadcrumb);

        app.component('Button', Button);
        app.component('Card', Card);
        app.component('InputText', InputText);
        app.component('MegaMenu', MegaMenu);
        app.component('Menu', Menu);
        app.component('Menubar', Menubar);
        app.component('Panel', Panel);
        app.component('PanelMenu', PanelMenu);
        app.component('SelectButton', SelectButton);
        app.component('Toast', Toast);
        
        //app.component("Flicking", Flicking);

        app.mount('#app');
        this.app = app;
    }
}
const INVRS = new INVRSImpl();
export default INVRS;
