import { WS_URL, LOGOUT_PAGE } from "@/common/config";//eslint-disable-line
import store from '../store/index'
import { ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE, ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE, ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE } from "../common/constants";
import Sarus from '@anephenix/sarus';
import EventBus from '../event-bus';
import { BroadcastChannel } from 'broadcast-channel';
import LocalStorageService from "./LocalStorageService";
import FeedService from "./FeedService";
import INVRS from '../main';
import AuthService from "./AuthService";






const MAX_RETRIES = 10;
const KEEPALIVE_INTERVAL = 45*1000;

/**
 * Manages a websocket connection to the server to allow
 * server events to be delivered to the client.
 */
class NotificationServiceImpl{
  AUTH_SESSION = 'INVRS_AUTH_SESSION';

  retryCount = 0;
  ws = null;
  running = false;
  sseServer = null;
 
  wealthicaChannel = null;
  wealthMsgHandler = null;

  qmChannel = null;
  qmMsgHandler = null;

  socialAuthChannel = null;
  socialAuthMsgHandler = null;

  invrsAuthSessionChannel = null;
  invrsAuthSessionMsgHandler = null;

  endPointInfo;

  initPostAuth() {
    this.EventBus = INVRS.app.config.globalProperties.EventBus;
    this.initPostAuthChannels();
  }

  initPostAuthChannels() {
    this.initWealthicalChannel();
  
   this.initQMChannel();

   this.initInvrsAuthSessionChannel();

  }

  initPreAuth() {
    const tryToInit = setInterval(() => {
      if (INVRS) {
        clearInterval(tryToInit);

        this.EventBus = INVRS.app.config.globalProperties.EventBus;
        this.initPreAuthChannels();
      }
    }, 50);

    setTimeout(() => {
      //clear tryToInit interval after 2 seconds
      clearInterval(tryToInit);
    }, 2000);
  }

  initPreAuthChannels() {
    this.initSocialAuthChannel();
  }

  initSocialAuthChannel() {

    this.socialAuthChannel = new BroadcastChannel('social_auth_channel');
    this.socialAuthMsgHandler = (messageEvent) => {

      const data = JSON.parse(messageEvent);
      if (data.type == 'auth') {

        this.EventBus.emit('social-auth-complete', data.code);
      }
      else {
        console.error("Unknown broadcast message received : " + JSON.stringify(data));
      }
    }
    this.socialAuthChannel.addEventListener('message', this.socialAuthMsgHandler);
  }

  initQMChannel() {
    
    this.qmChannel = new BroadcastChannel('qm_channel');
    this.qmMsgHandler  = (messageEvent) => {
        
        const data = JSON.parse(messageEvent);
        if( data.type == 'auth') {
            
            this.EventBus.emit('registration-complete');
        }
        else {
            console.error("Unknow broadcast message received : " + JSON.stringify(data));
        }
    }
    this.qmChannel.addEventListener('message',this.qmMsgHandler );
  }
  initWealthicalChannel() {
   
    this.wealthicaChannel = new BroadcastChannel('wealthica_channel');
    this.wealthMsgHandler  = (messageEvent) => {
        
        const data = JSON.parse(messageEvent);
        if( data.type == 'auth') {
            
            this.EventBus.emit('wealthica-token-received', data.token);
        }
        else {
            console.error("Unknow broadcast message received : " + JSON.stringify(data));
        }
    }
    this.wealthicaChannel.addEventListener('message',this.wealthMsgHandler );
  }
  initInvrsAuthSessionChannel() {
    this.invrsAuthSessionChannel = new BroadcastChannel('invrs_auth_session_channel');
    this.invrsAuthSessionMsgHandler = (messageEvent) => {
      const data = JSON.parse(messageEvent);

      if (data.type === 'renew') {
        let local = JSON.parse(localStorage.getItem(this.AUTH_SESSION));

        const tokens = JSON.parse(data.tokens);

        local['accessToken'] = tokens.accessToken;
        local['sid'] = tokens.sid;
        local['chatToken'] = tokens.chatToken;

        localStorage.setItem(this.AUTH_SESSION, JSON.stringify(local));
      } else if (data.type === 'logout') {
        localStorage.removeItem(this.AUTH_SESSION);
        LocalStorageService.clearAppState();
        window.location.href = LOGOUT_PAGE;
      } else {
        console.error("Unknow broadcast message received : " + JSON.stringify(data));
      }
    }

    this.invrsAuthSessionChannel.addEventListener('message', this.invrsAuthSessionMsgHandler);
  }


  start(userId) { //eslint-disable-line

    this.retryCount = 0;

    
    this.ws =  new Sarus({
      url: WS_URL+"/"+userId+"/"+store.state.token,
      eventListeners: {
        open: [() => { this.onOpen();}],
        message: [(msg) => {this.onMessageRecieved(msg)}],
        close: [(msg) => {this.onClose(msg)}],
        error: [(err) => {this.onError(err)}]
      },
      reconnectAutomatically: true,
      retryConnectionDelay: 10000,
    });
    
    this.running = true;
    
  
    
  }
 

  onOpen() {
			console.debug("Notifications channel successfully opened.");
			this.running = true;
      this.stopWSKeepAlive();
      this.startWSKeepAlive();
		}

  startWSKeepAlive() {
    this.keepAliveTimer = setInterval(() => {this.ws.send('{type : "ping"}');}, KEEPALIVE_INTERVAL);
  }
  
  stopWSKeepAlive() {
    if( this.keepAliveTimer ) {
      clearInterval(this.keepAliveTimer);
    }
  }

		// Javascript callback function when messages is received from server.
	onMessageRecieved(msg) {
    
    let data = JSON.parse(msg.data);

    // console.log("Notifications got ws message : ", data);
    if( data.type == "endPointInfo"){
        //console.log(" got endpoint info : "+ data.content);
        this.endPointInfo = JSON.parse(data.content);

    }
    else if( data.type == "qm.auth") {
      
      // console.log("Notifications got ws message qm.auth : ", data);
      const n = JSON.parse(data.content);
      AuthService.onSidReceived(n.payload);
    }
    else if( data.type == "feed") {
      this.handleFeedMessage(data);
    }
    else if( data.type == "system") {
      this.EventBus.emit('system-notification', JSON.parse(data.content))
    }
    else if (data.type == "pong") {
      // normal just to keep channel alive
    }
    else if( data.type == "chat.message") {
      let  n = JSON.parse(data.content);

      if(n?.message?.directMessage){
        this.EventBus.emit('play-notification-sound', ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE);
        this.EventBus.emit('chat-message-notification', n);
      } else {
        this.EventBus.emit('play-notification-sound', ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE);
        store.commit('ADD_GROUP_CHANNEL_UNREAD_COUNTS', {
          groupId: n.message.groupId,
          chatChannelId: n.message.chatChannelId
        });
        this.EventBus.emit('channel-notification-group-channel-list', n);
      }
    }
    else if( data.type == "chat.message.mention") {
      let  n = JSON.parse(data.content);
      
      if(n?.message?.directMessage){
        this.EventBus.emit('play-notification-sound', ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE);
        this.EventBus.emit('chat-mention-notification', n);
      } else {
        this.EventBus.emit('play-notification-sound', ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE);
        store.commit('ADD_GROUP_CHANNEL_UNREAD_COUNTS', {
          groupId: n.message.groupId,
          chatChannelId: n.message.chatChannelId
        });
        this.EventBus.emit('channel-notification-group-channel-list', n);
      }
    }
    else if( data.type == "chat.channel.deleted") {
      
      let  n = JSON.parse(data.content);
      
      this.EventBus.emit('chat-channel-deleted-notification', n)
    }

    else if (data.type == 'payment.complete') {
      let n = JSON.parse(data.content);
      this.EventBus.emit('payment-complete-notification', n);
    }

    else if (data.type == 'subscription.cancelled') {
      let n = JSON.parse(data.content);
      this.EventBus.emit('subscription-cancelled-notification', n);
    }

    else if (data.type == "portfolio.import") {
      let  n = JSON.parse(data.content);
      // console.log('import complete', n);

      if (n.institutionImport.status === "Import Complete") {
        this.EventBus.emit("portfolio-imported", n.institutionImport);
        this.EventBus.emit("portfolio-imported-reload", n.institutionImport);
      }
     else if (n.institutionImport.status.indexOf("failed") >= 0) {
        this.EventBus.emit("portfolio-import-failed", n.institutionImport);
      }
      else {
        this.EventBus.emit("portfolio-import-update", n.institutionImport);
      }
    }

    else if( data.type == "portfolio.update") {
      let  n = JSON.parse(data.content);
      this.EventBus.emit("portfolio-update", n.investmentAccount);
    }
    
    else {
      console.log("Unsupported message recived" +JSON.stringify(data));
      //pong
    }
    
   
			
		}

    handleFeedMessage(data) {
      let notifications = JSON.parse(data.content);
      
      var likes = []
      var comments = [];
      var reposts = [];
      var mentions = [];
      var follows = [];
      var groupInvites = [];
      var groupJoins = [];
      let dmRequests = [];
      let dmAcceptances = [];
      let alerts = [];
      let groupPosts = [];
      let joinedGroup = [];
      let actionReward = []; 
      let pollReward = []; 
      let contestPosts = [];

      let motionsSubmitted = [];
      let motionsPassed = [];
      let motionsFailed = [];
      let motionsWithdrawn = [];
      let motionsVetoed = []; 

      let fundsTransferred = [];


      notifications.forEach(wsMessage => {
        let n = JSON.parse(wsMessage.payload);
          if( n.verb.startsWith("like")){
              //console.log("incrementing likes");
              this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
              likes.push(n);
          }
          else if(n.verb.startsWith("comment")){
              //console.log("incrementing num comments");
              this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
              comments.push(n);
          }
          else if(n.verb.startsWith("repost")){
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
              //console.log("incrementing num reposts");
              reposts.push(n);
          }
          else if(n.verb.startsWith("mention")){
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
              //console.log("incrementing num mentions");
              mentions.push(n);
          }
          else if(n.verb.startsWith("follow")){
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
              //console.log("increment num follows");
              follows.push(n);
          }
          else if(n.verb.startsWith("groupInvite")){
            //console.log("increment num follows");
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
            groupInvites.push(n);
          }
          else if(n.verb.startsWith("groupJoinAcceptance")){
            //console.log("increment num follows");
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
            groupJoins.push(n);
            EventBus.emit('refresh-app-group-menu')
          }
          else if(n.verb.startsWith("dmRequest")){
            //console.log("increment num follows");
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
            dmRequests.push(n);
          }
          else if(n.verb.startsWith("dmAcceptance")){
            //console.log("increment num follows");
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
            dmAcceptances.push(n);
          } 
          else if (n.verb.startsWith('alert')) {
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
            alerts.push(n);
          } else if (n.verb.startsWith('joinedGroup')) {
            this.EventBus.emit('play-notification-sound', ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE);
            joinedGroup.push(n);
          } 
          else if (n.verb.startsWith('groupPost')) {
            groupPosts.push(n);
          } else if (n.verb.startsWith('motionSubmitted')) {
            motionsSubmitted.push(n);
          } else if (n.verb.startsWith('motionPassed')) {
            motionsPassed.push(n);
          } else if (n.verb.startsWith('motionFailed')) {
            motionsFailed.push(n);
          } else if (n.verb.startsWith('motionWithdrawn')) {
            motionsWithdrawn.push(n);
          } else if (n.verb.startsWith('motionVetoed')) {
            motionsVetoed.push(n);
          } else if (n.verb.startsWith('actionReward')) {
            actionReward.push(n);
          } else if (n.verb.startsWith('pollReward')) {
            pollReward.push(n);
          } else if (n.verb.startsWith('fundsTransferred')) {
            fundsTransferred.push(n);
          }else if (n.verb.startsWith('contestPost')) {
            contestPosts.push(n);
          }
      });
      if( likes.length > 0 ){
          store.commit("ADD_LIKE_COUNTS", likes);
          
      }
      if( comments.length > 0 ){
          store.commit("ADD_COMMENT_COUNTS", comments);
      }
      if( reposts.length > 0 ){
        store.commit("ADD_REPOST_COUNTS", reposts);
      }
      if( mentions.length > 0 ){
          store.commit("ADD_MENTION_COUNTS", mentions);
      }
      if( follows.length > 0 ) {
          store.commit("ADD_FOLLOW_COUNTS", follows);
      }
      if( groupInvites.length > 0 ) {
        store.commit("ADD_GROUP_INVITE_COUNTS", groupInvites);
      }
      if( groupJoins.length > 0 ) {
        store.commit("ADD_GROUP_JOIN_COUNTS", groupJoins);
      }
      if( dmRequests.length > 0 ) {
        store.commit("ADD_DM_REQUEST_COUNTS", dmRequests);
      }
      if( dmAcceptances.length > 0 ) {
        store.commit("ADD_DM_ACCEPTANCE_COUNTS", dmAcceptances);
      }
      if (alerts.length > 0) {
        store.commit('ADD_ALERT_COUNTS', alerts);
      }
      if (joinedGroup.length > 0) {
        store.commit('ADD_JOINED_GROUP_COUNTS', joinedGroup);
      }
      if (groupPosts.length > 0) {
        FeedService.getGroupNotification(groupPosts[0].id).then((resp) => {
          // console.log("FeedService.getGroupNotification",groupPosts[0], resp.data.notification);
          store.commit('ADD_GROUP_POST_ACTIVITY', resp.data.notification);
        });
      }
      if (motionsSubmitted.length > 0) {
        store.commit('ADD_MOTIONS_SUBMITTED_COUNTS', motionsSubmitted);
      }
      if (motionsPassed.length > 0) {
        store.commit('ADD_MOTIONS_PASSED_COUNTS', motionsPassed);
      }
      if (motionsFailed.length > 0) {
        store.commit('ADD_MOTIONS_FAILED_COUNTS', motionsFailed);
      }
      if (motionsWithdrawn.length > 0) {
        store.commit('ADD_MOTIONS_WITHDRAWN_COUNTS', motionsWithdrawn);
      }
      if (motionsVetoed.length > 0) {
        store.commit('ADD_MOTIONS_VETOED_COUNTS', motionsVetoed);
      }
      if (actionReward.length > 0) {
        store.commit('ADD_ACTION_REWARD_COUNTS', actionReward);
        EventBus.emit('refresh-cash-accounts-balance');
      }
      if (pollReward.length > 0) {
        store.commit('ADD_POLL_REWARDS_COUNTS', pollReward);
        EventBus.emit('refresh-cash-accounts-balance');
      }

      if (fundsTransferred.length > 0) {
        store.commit('ADD_FUNDS_TRANSFERRED_COUNTS', fundsTransferred);
      }

      if (contestPosts.length > 0) {
        store.commit('ADD_CONTEST_POSTS_COUNTS', contestPosts);
      }
    }

		// Javascript callback function when connection is closed.
	onClose(msg) {
      
      console.debug("Closing notifications channel");
      this.stopWSKeepAlive();
      ++this.retryCount;
      if( this.retryCount > MAX_RETRIES) {
        // todo: need to notify AuthService as they may need to refresh the acces token.
        this.stop();
        EventBus.emit('notification-stopped', msg);
      }
		
		}
  
  onError(err) {
    console.error("There was an error reported on the notifications channel." +err);
    this.stopWSKeepAlive();
  }


  



  stop() {
    if( this.ws) {
      console.debug("stopping Notification service");
     
      
      this.ws.disconnect();
    }
    this.stopWSKeepAlive();

    if( this.sseServer) {
      this.sseServer.disconnect();
    }
    this.running = false;

    if( this.qmChannel && this.qmMsgHandler) {
        this.qmChannel.removeEventListener('message',this.qmMsgHandler );
    }
    if( this.wealthicaChannel && this.wealthMsgHandler) {
        this.wealthicaChannel.removeEventListener('message', this.wealthMsgHandler);
    }
  }

  isRunning() {
    return this.running;
  }
  
}
const NotificationService = new NotificationServiceImpl();
export default NotificationService;