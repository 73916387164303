import {createRouter, createWebHashHistory} from 'vue-router';
// import {defineAsyncComponent} from 'vue';
//const MarketScreener = () =>  import('./components/QuoteMedia/MarketScreener.vue');
//mport MarketScreener  from './components/QuoteMedia/MarketScreener.vue';

const routes = [
    {
        path: '/',
        component: () => import('./views/Home.vue'),
    },
    {
        path: '/groupPreview',
        component: () => import('./pages/GroupPreview.vue')
    },
    {
        path: '/emailConfirmed',
        component: () => import('./pages/EmailConfirmed.vue')
    },
    {
        path: '/signup',
        component: () => import('./pages/Signup.vue'),
        children: [
            {
                path: '/signup/1',
                name: 'signupStepOne',
                component: () => import('./views/Empty.vue'),
            
            },
            {
                path: '/signup/2',
                name: 'signupStepTwo',
                component: () => import('./views/Empty.vue'),
            
            },
            {
                path: '/signup/3',
                name: 'signupStepThree',
                component: () => import('./views/Empty.vue'),
            
            },
            {
                path: '/signup/4',
                name: 'signupStepFour',
                component: () => import('./views/Empty.vue'),
            
            },
            {
                path: '/signup/5',
                name: 'signupStepFive',
                component: () => import('./views/Empty.vue'),
            
            },
        ]
    },
    {
        path: '/login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/reset/:token',
        component: () => import('./pages/ResetPassword.vue')
    },
    {
        path: '/shared/:feedPostId',
        component: () => import('./pages/SharedPost.vue')
    },
    
    {
        path: '/home',
        meta: {
            breadcrumb: [{ label: 'Home' }],
        },
        component: () => import('./views/Home.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/Empty.vue'),
                
            },
            {
                path: 'home',
                component: () => import('./views/Empty.vue'),
                
            },
            
        ]
    },

    {
        path: '/economy',
        meta: {
            breadcrumb: [{ label: 'Economy' }],
        },
        component: () => import('./views/Economy.vue')
    },

    {
        path: '/affiliate/login',
        component: () => import('./pages/AffiliateLogin.vue')
    },
    {
        path: '/affiliate/home',
        component: () => import('./pages/AffiliateHome.vue')
    },
   
   
    {
        path: '/equityLookup/:selectedSymbol?',
        
        component: () => import('./views/SecLookup.vue'),
    },
    {
        path: '/equity',
        
        component: () => import('./views/Equity.vue'),
        meta: {
            breadcrumb: [{ label: 'Equity' }],
        },
        children: [
            {
                path: '',
                component: () => import('./views/Empty.vue'),
               
            },
            {
                path: '/equity/financials',
                name: 'equityFinancials',
                component: () => import('./components/equity/Financials.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Financials' }],
                },
               
            },
            {
                path: '/equity/compare',
                name: 'equityCompare',
                component: () => import('./components/equity/Compare.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Compare' }],
                },
               
            },
            {
                path: '/equity/analysts',
                name: 'equityAnalysts',
                component: () => import('./views/Empty.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Analysts' }],
                },
               
            },
            {
                path: '/equity/insiders',
                name: 'equityInsiders',
                component: () => import('./views/Empty.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Insiders' }],
                },
               
            },
            {
                path: '/equity/charting',
                name: 'equityCharting',
                component: () => import('./components/equity/Charting.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Charting' }],
                },
               
            },
            {
                path: '/equity/dividends',
                name: 'equityDividends',
                component: () => import('./views/Empty.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Dividends' }],
                },
            },
            {
                path: '/equity/trades',
                name: 'equityTrades',
                component: () => import('./views/Empty.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Trades' }],
                },
            },
            {
                path: '/equity/filings',
                name: 'equityFilings',
                component: () => import('./views/Empty.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Filings' }],
                },
            },
            {
                path: '/equity/performance',
                name: 'equityPerformance',
                component: () => import('./views/Empty.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Performance' }],
                },

            },
            {
                path: '/equity/notes',
                name: 'equityNotes',
                component: () => import('./components/equity/Notes.vue'),
                meta: {
                    breadcrumb: [{ label: 'Equity Notes' }],
                },
               
            },

        ]
       
    },
    {
        path: '/fund',
        
        component: () => import('./views/Fund.vue'),
        meta: {
            breadcrumb: [{ label: 'Fund' }],
        },
        children: [
            {
                path: '',
                component: () => import('./views/Empty.vue'),
               
            },
            {
                path: '/fund/holdings',
                name: 'fundHoldings',
                component: () => import('./views/Empty.vue'),
                meta: {
                    breadcrumb: [{ label: 'Fund Holdings' }],
                },
               
            },
            {
                path: '/fund/charting',
                name: 'fundCharting',
                component: () => import('./components/fund/Charting.vue'),
                meta: {
                    breadcrumb: [{ label: 'Fund Charting' }],
                },
               
            },
            {
                path: '/fund/dividends',
                name: 'fundDividends',
                component: () => import('./views/Empty.vue'),
                meta: {
                    breadcrumb: [{ label: 'Fund Dividends' }],
                },
               
            },
            {
                path: '/fund/notes',
                name: 'fund',
                component: () => import('./components/fund/Notes.vue'),
                meta: {
                    breadcrumb: [{ label: 'Fund Notes' }],
                },
               
            },
        ]
       
    },
    {
        path: '/marketIndex',
        
        component: () => import('./views/MarketIndex.vue'),
        meta: {
            breadcrumb: [{ label: 'MarketIndex' }],
        },
        children: [
            {
                path: '',
                component: () => import('./views/Empty.vue'),
               
            },
            

        ]
       
    },
    {
        path: '/sandbox',
        name: 'sandbox',
        component: () => import('./views/Sandbox.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/Empty.vue'),
               
            },
            {
                path: 'screeners',
                component: () => import('./views/Empty.vue'),
                //component: MarketScreener,
               
            },
            {
                path: 'economy',
                component: () => import('./views/Empty.vue'),
               
            },
            {
                path: 'lists',
                component: () => import('./views/Lists.vue'),
               
            },
            {
                path: 'earningsCalendar',
                component: () => import('./views/Empty.vue'),
               
            },
            {
                path: 'dividendsCalendar',
                component: () => import('./views/Empty.vue'),
               
            },
            {
                path: 'chartTool',
                component: () => import('./views/ChartTool.vue'),
               
            },
            {
                path: 'Alerts',
                component: () => import('./components/sandbox/Alerts.vue'),
               
            },

        ]
        
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('./views/Empty.vue'),
    },
    {
        path: '/groups',
        name: 'groups',
        component: () => import('./views/Groups.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/Empty.vue'),
               
            },
            {
                path: 'profile/:groupId',
                component: () => import('./views/Empty.vue'),
                
               
            },
            {
                path: 'profile/:groupId/competitions',
                component: () => import('./views/Empty.vue'),
                
            },
            {
                path: 'profile/:groupId/course/:courseId',
                component: () => import('./views/Empty.vue'),
                
            },
            {
                path: 'profile/:groupId/landing',
                component: () => import('./views/Empty.vue'),
                
            },
        
        ]     
    },

    {
        path: '/education',
        name: 'education',
        component: () => import('./views/Education.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/Empty.vue'),
               
            },
            {
                path: '/education/course/:groupId',
                component: () => import('./views/Empty.vue'),
               
               
            }
        
        ]     
    },

    {
        path: '/contests',
        name: 'contests',
        component: () => import('./views/Contests.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/Empty.vue'),
            },
            {
                path: '/contests/competitions',
                component: () => import('./views/Empty.vue'),
            },
            {
                path: '/contests/competitions/:contestId',
                component: () => import('./views/Empty.vue'),
            },
            {
                path: '/contests/:contestId',
                component: () => import('./views/Empty.vue'),
            },
            {
                path: '/contests/:contestId/stats',
                component: () => import('./views/Empty.vue'),
            },
            {
                path: '/contests/:contestId/manage',
                component: () => import('./views/Empty.vue'),
            },
            {
                path: '/contests/:contestId/manage/:puzzleId',
                component: () => import('./views/Empty.vue'),
            },
        ]     
    },

    

    {
        path: '/portfolios',
        name: 'portfolios',
        component: () => import('./views/Portfolios.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/portfolio/Overview.vue'),
            },
            {
                path: '/portfolios/cash-flow',
                component: () => import('./components/portfolio/CashFlow.vue'),
            },
            {
                path: '/portfolios/performers',
                component: () => import('./components/portfolio/overview/TopPerformersTable.vue'),
            },
            {
                path: '/portfolios/cash',
                component: () => import('./components/portfolio/CashAccounts.vue'),
            },
        ]
    },
    {
      path: '/profile',
      meta: {
          breadcrumb: [{ label: 'Profile' }],
      },
      component: () => import('./views/Profile.vue'),
      children: [
          {
              path: '',
              component: () => import('./views/Empty.vue'),
              
          },
          {
              path: 'settings',
              component: () => import('./views/Empty.vue'),
              
          },
          {
            path: 'seller',
            component: () => import('./views/Empty.vue'),
            
        },
      ]
    },
   {
        path: '/billing',
        name: 'billing',
        component: () => import('./views/Billing.vue')
   },
    {
        path: '/advertising',
        name: 'advertising',
        component: () => import('./views/Advertising.vue')
    },
   {
       path: '/analysts/profile/:user',
       name: 'userProfile',
       component: () => import('./views/Profile.vue')
   },

   {
    path: '/notes',
    name: 'notes',
    component: () => import('./views/Notes.vue')
    },

    {
        path: '/tag/profile/:tagId',
        name: 'tag',
        component: () => import('./views/Tag.vue')
    }
   
    
    
   
    
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;