import * as stream from 'getstream'
import { STREAM_KEY, STREAM_APP_ID } from "@/common/config";
import store from '../store/index'
import APIService from '@/common/APIService'
import EventBus from '../event-bus';
import StatusImpressionDetector from './StatusImpressionDetector';


const USER_FEED = "user";
const TIMELINE_FEED = "timeline";
const NOTIFICATIONS_FEED = "notifications";
const TIMELINE_ANALYST_FEED = "analyst-";
// const EQUITY_FEED = "equity-";
// const EQUITY_NOTES_FEED = "equity_notes-";
// const FUND_NOTES_FEED = "fund_notes-";
// const FUND_FEED = "fund-";
const GLOBAL_FEED = "global";
const GROUP_FEED = "group"; //eslint-disable-line
const INDEX_FEED = "index-";
const NOTES_FEED = "notes"
const LIKE = 1;




class FeedServiceImpl {

    notificationSubscription = null;  

    reactionMap = {
        'smile':'🙂',
        'happy':'😃',
       'lmfao':'🤣',
        'wink':'😉',
        'hmm':'🤨',
        'ugh':'🙄',
        'sad':'😪',
        'angry':'😠',
        'frown':'🙁',
        'dislike':'👎',
        'ok':'👌',
        'clap':'👏',
        'wave':'👋',
        'hands':'🙌',
        'hot':'🔥',
        'tada':'🎉',
        'award':'🏅',
        'heart':'❤️',
        'money':'💲',
        'thanks':'🙏',
       'rocket':'🚀',
       'poopy': '💩',
       'devil':'👿',
       'bear': '🐻',
       'bull':'🐮',
       'diamond': '💠',
       'skull': '💀'
    }

    statusImpressionListener = (dataset) => { 
        try{
           
            let activityIdIndex = dataset.logId.indexOf('activityContent-')+"activityContent-".length;
            let activityId = dataset.logId.substring(activityIdIndex);
            let feedPostId = dataset.feedPostId;
            
            if( dataset.isOwnPost != "true" ) {
              
               
                let feedPostLook = {
                    feedPostId : feedPostId,
                    activityId : activityId,
                    
                }
                APIService.post('/feed/post/look', feedPostLook);
                
            }
        }catch( err) {
            console.warn("error detecting impression " + err);
        }
        
    };

    constructor() {
        EventBus.on("feed-notifications-seen", () => {
                this.markNotificationsSeen();
        });
    }

    startStatusImpressionDetector(context) {//eslint-disable-line
        //console.log("startStatusImpressionDetector called from "+context)
        
        setTimeout( () => {
            
            StatusImpressionDetector.getInstance().stop();
           // console.log("setting up impression detector from call context "+ context)
            StatusImpressionDetector.getInstance().start("impression-list", "activity-card-container", this.statusImpressionListener);
            
        }, 3000);
            
        
   }

   stopStatusImpressionDetector(context) {//eslint-disable-line
        //console.log("stop impression detector called from "+ context);
           
        
        StatusImpressionDetector.getInstance().stop();
       
   }

    async getCurrentNotifications(activityId = null) {
        let feedToken = store.state.feedTokenSet["timeline"];
        let client = stream.connect( STREAM_KEY,  feedToken, STREAM_APP_ID );
        
        const notificationFeed = client.feed(NOTIFICATIONS_FEED, store.state.users.user.analyst.analystId);


        let results = [];
        await notificationFeed.get({id_lt: activityId, limit: 10}).then((data) => {
            results = data.results;
        });

        return results;
    }

    async markNotificationsSeen() {
        
        if( store.state.feedNotificationCounts ) {
            store.commit("MARK_FEED_NOTIFICATIONS_SEEN");
            let feedToken = store.state.feedTokenSet["timeline"];
            let client = await stream.connect( STREAM_KEY,  feedToken, STREAM_APP_ID );
            
            const notificationFeed = client.feed(NOTIFICATIONS_FEED, store.state.users.user.analyst.analystId); 
            notificationFeed.get({mark_seen:true})
        }

    }

    async markNotificationsSeenByIds(notificationIds) {
        let feedToken = store.state.feedTokenSet["timeline"];
        let client = await stream.connect( STREAM_KEY,  feedToken, STREAM_APP_ID );
        
        const notificationFeed = client.feed(NOTIFICATIONS_FEED, store.state.users.user.analyst.analystId); 
        notificationFeed.get({mark_seen: notificationIds});
    }

    async markNotificationsReadByIds(notificationIds) {
        let feedToken = store.state.feedTokenSet["timeline"];
        let client = await stream.connect( STREAM_KEY,  feedToken, STREAM_APP_ID );
        
        const notificationFeed = client.feed(NOTIFICATIONS_FEED, store.state.users.user.analyst.analystId); 
        notificationFeed.get({mark_read: notificationIds});
    }

    async markNotificationRead(id) {
        let feedToken = store.state.feedTokenSet["timeline"];
        let client = await stream.connect( STREAM_KEY,  feedToken, STREAM_APP_ID );
        
        const notificationFeed = client.feed(NOTIFICATIONS_FEED, store.state.users.user.analyst.analystId); 
        notificationFeed.get({mark_read: [id]});
    }
    
    getGroupNotification(id) {
        return APIService.get('/feed/group/notification?id='+id);
    }

    getGroupFeedToken(groupId, groupSubFeedId) {
        return APIService.get('/feed/group/token?groupId='+groupId+(groupSubFeedId ? '&groupSubFeedId='+groupSubFeedId : ''));
    }
    
    createGroupFeedUserId(groupId,  groupSubFeedId) {
        if( !groupId ){
           
           return "groups-"+store.state.users.user.analyst.analystId+'-all';
            
        }
        else {
            if( groupSubFeedId ) {
                return "groups-"+store.state.users.user.analyst.analystId+'-'+groupId+'-group_sub_feed-'+groupSubFeedId;
            }
            else {
                
                return "groups-"+store.state.users.user.analyst.analystId+'-'+groupId;
            }
        }
    }

    async getGroupActivities(groupId, offset, groupSubFeedId, notesOnly) {
    

        let groupFeedUserId = this.createGroupFeedUserId(groupId, groupSubFeedId);
        //console.log("groupFeedUserId = "+ groupFeedUserId)
        let tokenKey = TIMELINE_FEED+":"+groupFeedUserId;
        
        let feedToken = store.state.feedTokenSet[tokenKey] ;
        //console.log("feed token = ["+feedToken+"]")
        if( feedToken == null ) {
            //console.log("no token found for "+ tokenKey +" requesting one now.")
            let resp = await this.getGroupFeedToken(groupId, groupSubFeedId);
            if( resp.data.status == 'success') {
       
               //console.log("received token for "+ tokenKey);
                store.commit("ADD_FEED_TOKEN", resp.data.token);
                feedToken = resp.data.token.token;
            }
            else {
               // console.log("unable to get feed token for  "+ tokenKey, resp.data.message);
                return [];
            }
        }
        // else {
        //     //console.log("using local cached token for "+ tokenKey);
        // }
        let feedClient = await stream.connect( STREAM_KEY,  feedToken, STREAM_APP_ID );

        let params = null;
        if( notesOnly ) {
            params ={'limit': 15, 'offset': offset, 'enrich': true,  ranking: 'notes-only', reactions: {own: true, counts: true, recent: true} };
        }
        else {
            params = {'limit': 15, 'offset': offset, 'enrich': true ,  ranking: 'basic', reactions: {own: true, counts: true, recent: true}};
        }
            
        let feed = feedClient.feed(TIMELINE_FEED, groupFeedUserId, feedToken)
       
        let results = [];
        try { // 'id_lt': lastId,
            
            await feed.get(params).then(response => {
            
                results = response.results;
            });
            if( results && results.length > 0 ){
                await this.populateOwnReactions(results);
            }
        }catch( error ){
            console.log("error getting activities: "+error);
        }
        return results;
        
    }

    

    async populateOwnReactions(activities){
        //console.log(JSON.stringify(activities))
        let feedPostIds = "";
        for (var i = 0; i < activities.length; ++i) {
            if( activities[i].verb == 'repost'){
                feedPostIds += activities[i].object.foreign_id;
            }
            else {
                feedPostIds += activities[i].foreign_id;
            }
            if( i < activities.length-1){
                feedPostIds += ",";
            }
        }

        let resp = await APIService.get('/feed/likes/own?ids='+feedPostIds);
        if( resp.data.status == "success"){
            //console.log("own likes: "+JSON.stringify(resp.data.likes));
            resp.data.likes.forEach(like =>  {
                for (var j = 0; j < activities.length; ++j) {
                    let act = null;
                    if(  activities[j].verb == "repost") {
                        act = activities[j].object;
                    }
                    else {
                        act = activities[j];
                    }
                    //console.log("comparing "+act.foreign_id+" with "+ like.relatedPostId)
                    if( act.foreign_id == like.relatedPostId) {
                        let likes = [];
                        likes.push( {
                                   
                            "id" : like.reactionId,
                            "user_id": like.analystId,
                            "kind": "like",
                            "activity_id": like.relatedActivityId,
                            "user": {
                                "id" : like.analystId
                            },
                            "data": {
                                "related_post_id": like.relatedPostId
                            },
                        });
                        try {
                            act["own_reactions"] =  {"like": likes }
                            //console.log(JSON.stringify(act));
                        }catch( error) {
                            console.log("error setting own_reactions: "+ JSON.stringify(act));
                        }
                        break;
                    }
                }
            });

            //console.log(JSON.stringify(activities))

            

        }
    }

    async getGroupPosts(groupId, limit, offset, notesOnly, groupSubFeedId) {
    
        let resp = await APIService.get('/feed/group/'+groupId+'/posts?limit='+limit+'&offset='+offset+"&notesOnly="+notesOnly+(groupSubFeedId ? "&groupSubFeedId="+groupSubFeedId: ""));
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting group posts "+ resp.data.message);
            return  [];
        }
        
    }

    async getPopularNotes(limit, offset, range) {
        let feedClient = await stream.connect( STREAM_KEY,  store.state.feedTokenSet["timeline"], STREAM_APP_ID );
        
        let ranking = 'popular_notes_30d';
        if( range == '1W') {
            ranking = 'popular_notes_7d';
        }
        else if( range == 'YTD') {
            ranking = 'popular_notes_365d';
        }
        let feed = feedClient.feed(GLOBAL_FEED, NOTES_FEED, store.state.feedTokenSet["timeline"])
        
        let results = [];
        try{
            await feed.get({'limit': limit, 'offset': offset, 'enrich': true, 'ranking': ranking, reactions: {own: true, counts: true, recent: true} }).then(response => {
            
                results = response.results;
            
            });
        }catch( error ){
            console.log("error getting global notes activities: "+error);
        }
        return results;
    }

    async getTagPosts( tag, limit, offset, notesOnly, groupId) {
        let resp = await APIService.get('/feed/tags/'+tag.tagId+'/posts?limit='+limit+'&offset='+offset+"&notesOnly="+notesOnly+(groupId ? "&groupId="+groupId : ""));
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting tag posts "+ resp.data.message);
            return  [];
        }
        
    }

    async getEquityActivities(offset = 0, notesOnly = false, groupId) {
        let limit = 15;
        let resp = await APIService.get('/feed/equity/'+store.state.selectedEquity.securityId+'/posts?limit='+limit+'&offset='+offset+"&notesOnly="+notesOnly+(groupId ? "&groupId="+groupId : ""));
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting tag posts "+ resp.data.message);
            return  [];
        }
        
    }

    async getLatestPosts(offset, notesOnly) {
        let limit = 15;
        let resp = await APIService.get('/feed/latest/posts?limit='+limit+'&offset='+offset+"&notesOnly="+notesOnly);
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting latest posts "+ resp.data.message);
            return  [];
        }
        
    }

    async getGroupContestPosts(offset, groupId, contestId, cycleStartDate) {
        let limit = 15;
        let resp = await APIService.get(`/feed/group/${groupId}/${contestId}/posts/${cycleStartDate}?limit=${limit}&offset=${offset}`);
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting questions "+ resp.data.message);
            return  [];
        }
        
    }

    async getQuestions(offset) {
        let limit = 15;
        let resp = await APIService.get('/feed/questions?limit='+limit+'&offset='+offset);
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting questions "+ resp.data.message);
            return  [];
        }
        
    }

    async getMotions(offset) {
        let limit = 15;
        let resp = await APIService.get('/feed/motions?limit='+limit+'&offset='+offset);
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting motions "+ resp.data.message);
            return  [];
        }
        
    }



    async getFeaturedFeedActivities(offset) {
        let limit = 15;
        let resp = await APIService.get('/feed/featured/posts?limit='+limit+'&offset='+offset);
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting featured group posts "+ resp.data.message);
            return  [];
        }
        
    }

    async getFundActivities( offset, notesOnly, groupId) {
        let limit = 15;
        let resp = await APIService.get('/feed/fund/'+store.state.selectedFund.fundId+'/posts?limit='+limit+'&offset='+offset+"&notesOnly="+notesOnly+(groupId ? "&groupId="+groupId : ""));
        if( resp.data.status == "success"){
            //console.log(JSON.stringify(resp.data.activities));
            let results = this.deNormalizeActivities(resp.data.activities);//
            //console.log(JSON.stringify(results));
            return results;
            //return resp.data.activities;
        }
        else {
            console.log("error getting tag posts "+ resp.data.message);
            return  [];
        }
        
    }


    // async getEquityActivities(offset, notesOnly) {
    //     let feedClient = await stream.connect( STREAM_KEY,  store.state.feedTokenSet["timeline"], STREAM_APP_ID );
        
    //     let feedIdPrefix = (notesOnly ?   EQUITY_NOTES_FEED : EQUITY_FEED);
        
    //     let feed = feedClient.feed(GLOBAL_FEED, feedIdPrefix+store.state.selectedEquity.securityId, store.state.feedTokenSet["timeline"])
        
    //     let results = [];
    //     try{
    //         await feed.get({'limit': 15, 'offset': offset, 'enrich': true, reactions: {own: true, counts: true, recent: true}}).then(response => {
            
    //             results = response.results;
            
    //         });
    //     }catch( error ){
    //         console.log("error getting equity activities: "+error);
    //     }
    //     return results;
        
    // }
    // async getFundActivities(offset, notesOnly) {
        
    //     let feedClient = await stream.connect( STREAM_KEY,  store.state.feedTokenSet["timeline"], STREAM_APP_ID );
        

    //     let feedIdPrefix = (notesOnly ?   FUND_NOTES_FEED : FUND_FEED);
    //     let feed = feedClient.feed(GLOBAL_FEED, feedIdPrefix+store.state.selectedFund.fundId, store.state.feedTokenSet["timeline"])
        
    //     let results = [];
    //     try {
    //         await feed.get({'limit': 15, 'offset': offset, 'enrich': true, reactions: {own: true, counts: true, recent: true} }).then(response => {
            
    //             results = response.results;
            
    //         });
    //     }catch( error ){
    //         console.log("error getting fund activities: "+error);
    //     }
    //     return results;
        
    // }

    deNormalizeActivities(objActivities) {
        let activities = [];
        for (var i = 0; i < objActivities.length; ++i) {
            let activity = objActivities[i];
            let activityData = activity.extra;
            
            activityData['actor'] = activity.actor;
            activityData['time'] = activity.time;
            activityData['id'] = activity.id;
            activityData['verb'] = activity.verb;
            
            if(activity.object.data.verb != undefined) {
                activityData['object'] = activity.object.data;
                if( !activity.object.data.id ){
                    activityData.object['id'] = activity.object.id;
                }
            } else {
                activityData['object'] = activity.object;
            }

            activityData['foreign_id'] = activity.foreignID;
            activityData['target'] = activity.target;
            activityData['score'] = activity.score;
            activityData['reaction_counts'] = (activity.reactionCounts ? activity.reactionCounts :{})
            activityData['own_reactions'] = (activity.ownReactions ? this.deNormalizeReactions(activity.ownReactions): {}); //need to see why latest reactions and own reactions are equivalent
            activityData['latest_reactions'] = (activity.latestReactions? this.deNormalizeReactions(activity.latestReactions): {});
            activities.push(activityData);
        }
        return activities;
    }

    deNormalizeReactions(reactionObj){
        let reactions = {}
        if( reactionObj?.comment){
            let comments = []
            for( var i = 0; i < reactionObj.comment.length; ++i ) {
                let commentObj = reactionObj.comment[i];
                let comment = commentObj;
                
                comment["user"] = commentObj.userData;
                comment["data"] = commentObj.activityData;
               
                comments.push(comment);
                
            }
            reactions['comment'] = { 'comment': comments };
        }
        if( reactionObj?.like){
            let likes = []
            for( var j = 0; j < reactionObj.like.length; ++j ) {
                let likeObj = reactionObj.like[j];
                let like = likeObj;
                
                like["user"] = likeObj.userData;
                like["data"] = likeObj.activityData;
               
                likes.push(like);
                
            }
            reactions['like'] = { 'like': likes };
        }
        return reactionObj;
    }


    async getProfilePosts( analyst, limit, offset, notesOnly) {
        let feedClient = await stream.connect( STREAM_KEY,  store.state.feedTokenSet["timeline"], STREAM_APP_ID );
        
        var params = null;
        if( notesOnly ) {
            params ={'limit': limit, 'offset': offset, 'enrich': true,  ranking: 'notes-only', reactions: {own: true, counts: true, recent: true} };
        }
        else {
            params = {'limit': limit, 'offset': offset, 'enrich': true,  reactions: {own: true, counts: true, recent: true} };
        }
       
        
        let feed = feedClient.feed(USER_FEED, analyst.analystId, store.state.feedTokenSet["timeline"])
       
        
        let results = [];
        try{
            await feed.get(params).then(response => {
                
                results = response.results;
                //console.log(JSON.stringify(results));
            
            });
        }catch( error ){
            console.log("error getting profile activities: "+error);
        }
        return results;
    }

    async getSingleActivity(id) {
        let resp = await APIService.get('/feed/activity?activityId=' + id);
        if (resp.data.status == "success") {
            let results = this.deNormalizeActivities([resp.data.activityResp.activity]);
            let comment;
            if (resp.data.activityResp.comments && resp.data.activityResp.comments.length > 0) {
                comment = resp.data.activityResp.comments[0];
                comment["user"] = comment.userData;
                comment["data"] = comment.activityData;
            }
            results[0]["latest_reactions"] = { comment: [comment] };
            return results;
        }
        else {
            console.log("error getting latest posts " + resp.data.message);
            return [];
        }
    }

    async getActivities(offset, notesOnly) {
        
       

        let feedIdPrefix = (store.state.showFollowedUserFeedsOnly ? TIMELINE_ANALYST_FEED: "" )
        let feedToken = (store.state.showFollowedUserFeedsOnly ? store.state.feedTokenSet["timeline:analyst"] : store.state.feedTokenSet["timeline"] );
        let feedClient = await stream.connect( STREAM_KEY,  feedToken, STREAM_APP_ID );

        let params = null;
        if( notesOnly ) {
            params ={'limit': 15, 'offset': offset, 'enrich': true,  ranking: 'notes-only', reactions: {own: true, counts: true, recent: true} };
        }
        else {
            params = {'limit': 15, 'offset': offset, 'enrich': true ,  ranking: 'basic', reactions: {own: true, counts: true, recent: true}};
        }
            
        let feed = feedClient.feed(TIMELINE_FEED, feedIdPrefix+store.state.users.user.analyst.analystId, feedToken)
       
        let results = [];
        try { // 'id_lt': lastId,
            
            await feed.get(params).then(response => {
            
                results = response.results;
            });
        }catch( error ){
            console.log("error getting activities: "+error);
        }
        return results;
        
    }

  
    async getSharedPost(feedPostId) {
        return APIService.get('/feed/shared/post?feedPostId='+feedPostId);
    }

    async getInAppSharedPost(feedPostId) {
        console.log("looking up feedPost id "+ feedPostId);
        return APIService.get('/feed/shared/app/post?feedPostId='+feedPostId).then((resp) => {
            if (resp.data.status === 'success') {
                console.log("got back feed post", resp.data.activityResp.activity);
                const activities = this.deNormalizeActivities([resp.data.activityResp.activity])

                return activities[0];
            } else {
                return null;
            }
        });
    }

    async getActivityComments(activityId, lastReactionId) { // eslint-disable-line
        let client =  await stream.connect(
            STREAM_KEY,  
            store.state.feedTokenSet["timeline"],
            STREAM_APP_ID
        );
       
        return client.reactions.filter({ 
            'activity_id': activityId, 
            'kind': 'comment', 
            'limit': 10 ,
            id_gt: lastReactionId
          }); 

        
    }

    async getChildComments(parentComment, lastReactionId){
        let client =  await stream.connect(
            STREAM_KEY,  
            store.state.feedTokenSet["timeline"],
            STREAM_APP_ID
        );
       
        return client.reactions.filter({ 
            'reaction_id': parentComment.id, 
            'kind': 'comment', 
            'limit': 10 ,
            id_gt: lastReactionId
          }); 

    }

    

    async getIndexActivities(offset) {
        
        let feedClient = await stream.connect( STREAM_KEY,  store.state.feedTokenSet["timeline"], STREAM_APP_ID );
        

        let feedIdPrefix = INDEX_FEED;
        let feed = feedClient.feed(GLOBAL_FEED, feedIdPrefix+ store.state.selectedMarketIndex.marketIndexId, store.state.feedTokenSet["timeline"])
        
        let results = [];
        try {
            await feed.get({'limit': 15, 'offset': offset, 'enrich': true }).then(response => {
                results = response.results;
                
            });
        
        }catch( error ){
            console.log("error getting index activities: "+error);
        }
        return results;
        
    }

    

    async getAnalystToFollow() {
        
        return APIService.get('/feed/analysts?analystId='+ store.state.users.user.analyst.analystId);
        
    }

    async getPromotedAnalysts() {
        
        return APIService.get('/feed/analyst/promoted');
        
    }

    async getCuratedTags() {
        
        return APIService.get('/feed/tags/curated');
        
    }

    async getUncuratedTags() {
        
        return APIService.get('/feed/tags/uncurated');
        
    }

    async getAllTags() {
        
        return APIService.get('/feed/tags/all');
        
    }

    async getFollowedTags(analystId) {
        return APIService.get('/feed/tags/followed?analystId='+ analystId);
    }


    async uploadImage(userId, file) {// eslint-disable-line
      
        let resp =  await this.client.images.upload(file);
        
        return resp.file;
        
    }

    

    postStatus(statusPost) {
       
        return APIService.post('/feed/post/status', {'analystId' : store.state.users.user.analyst.analystId, 'status': statusPost});

        
    }

    postQuestion(questionPost) {
       
        return APIService.post('/feed/post/question', {'analystId' : store.state.users.user.analyst.analystId, 'question': questionPost});

        
    }

    postNote(postNote) {
        return APIService.post('/feed/post/note', {'analystId' : store.state.users.user.analyst.analystId, 'postNote': postNote});
    }

    updateNotePost(notePostUpdate) {
        return APIService.put('/feed/post/note', {'analystId' : store.state.users.user.analyst.analystId, 'notePostUpdate': notePostUpdate});
    }

    updateQuestionPost(questionPostUpdate) {
        return APIService.put('/feed/post/question', {'analystId' : store.state.users.user.analyst.analystId, 'questionPostUpdate': questionPostUpdate});
    }

    updateStatusPost(statusPostUpdate) {
        return APIService.put('/feed/post/status', {'analystId' : store.state.users.user.analyst.analystId, 'statusPostUpdate': statusPostUpdate});
    }

    followUser(target){
        return APIService.post('/feed/follow/user', {'analystId' : store.state.users.user.analyst.analystId, 'target': target});
       
    }

    unfollowUser(target){
        return APIService.post('/feed/unfollow/user', {'analystId' : store.state.users.user.analyst.analystId, 'target': target});
       
    }

    followFund(target){
        return APIService.post('/feed/follow/fund', {'analystId' : store.state.users.user.analyst.analystId, 'target': target});
       
    }
    unfollowFund(target){
        return APIService.post('/feed/unfollow/fund', {'analystId' : store.state.users.user.analyst.analystId, 'target': target});
       
    }
    followEquity(target){
        return APIService.post('/feed/follow/equity', {'analystId' : store.state.users.user.analyst.analystId, 'target': target});
       
    }
    unfollowEquity(target){
        return APIService.post('/feed/unfollow/equity', {'analystId' : store.state.users.user.analyst.analystId, 'target': target});
       
    }

    followTag(target){
        return APIService.post('/feed/follow/tag', {'analystId' : store.state.users.user.analyst.analystId, 'target': target.tagId});
       
    }
    unfollowTag(target){
        return APIService.post('/feed/unfollow/tag', {'analystId' : store.state.users.user.analyst.analystId, 'target': target.tagId
    });
       
    }


    likePost(activity){
        
        let feedPostReaction = {
            feedReactionTypeId : LIKE,
            analystId : store.state.users.user.analyst.analystId,
            relatedPostId : activity.foreign_id,
            relatedActivityId : activity.id,
            relatedAnalystId : activity.actor.id
        }
        if( feedPostReaction.analystId != feedPostReaction.relatedAnalystId) {
            return APIService.post('/feed/post/like', feedPostReaction);
        }
    }

    unlikePost(reactionId){
        
      
        return APIService.delete('/feed/post/like/'+reactionId);
        
    }

    deletePost( post, deletingAsSpam  ) {
       
        return APIService.delete('/feed/post/'+post.activity.foreign_id+"?userId="+store.state.users.user.userId+"&spam="+deletingAsSpam);
    }

    deleteRepost( post,deletingAsSpam ) {
       return APIService.delete('/feed/repost/'+post.activity.id+"?userId="+store.state.users.user.userId+"&spam="+deletingAsSpam);
        
    }

    deleteComment( comment ) {
        //console.log(JSON.stringify(comment));
        return APIService.delete('/feed/comment/'+comment.id+"?userId="+store.state.users.user.userId);
       
    }

    postCommentOnPost(activity, commentPosting){
        commentPosting.comment['analystId'] = store.state.users.user.analyst.analystId,
        commentPosting.comment['relatedPostId'] = activity.foreign_id;
        commentPosting.comment['relatedActivityId'] = activity.id;
        commentPosting.comment['relatedAnalystId'] = activity.actor.id;
        
        
        return APIService.post('/feed/post/comment',commentPosting);
    }

    postCommentOnComment(origComment, commentPosting){
        commentPosting.comment['analystId'] = store.state.users.user.analyst.analystId;
        commentPosting.comment['relatedPostId'] =  origComment.data.related_post_id;
        commentPosting.comment['relatedActivityId'] =  origComment.data.related_activity_id;
        commentPosting.comment['relatedAnalystId'] =  origComment.user_id;
        commentPosting.comment['relatedFeedCommentId'] =  origComment.data.feed_comment_id;
        commentPosting.comment['relatedReactionId'] =  origComment.id;
         
        
        return APIService.post('/feed/post/comment/comment',commentPosting);
    }

    likeComment(comment){
        
        let feedCommentReaction = {
            feedReactionTypeId : LIKE,
            analystId : store.state.users.user.analyst.analystId,
            relatedPostId : comment.data.related_post_id,
            relatedActivityId : comment.data.related_activity_id,
            relatedReactionId : comment.id,
            relatedFeedCommentId: comment.data.feed_comment_id,
            relatedAnalystId : comment.user_id
            
        }
        if( feedCommentReaction.analystId != feedCommentReaction.relatedAnalystId){
            return APIService.post('/feed/comment/like', feedCommentReaction);
        }
    }

    unlikeComment(reactionId){
        
      
        return APIService.delete('/feed/comment/like/'+reactionId);
        
    }

    getCommentLikes(feedPostId) {
        return APIService.get('/feed/posts/'+feedPostId+'/comments/likes?analystId='+store.state.users.user.analyst.analystId);
    }

    async getMostRecentComment(activity){
        let client =  await stream.connect(
            STREAM_KEY,  
            store.state.feedTokenSet["timeline"],
            STREAM_APP_ID
        );
        return client.reactions.filter({ 
            'activity_id': activity.id, 
            'kind': 'comment', 
            'limit': 1 
          }); 
    }

    queryTags(searchTerm, limit, offset ) {
        return APIService.get('/feed/tags/all/query?limit='+limit+"&page="+offset+"&q="+searchTerm);
    }

    getTag(tagId) {
        return APIService.get('/feed/tags/'+tagId);
    }
        

    repost(activity, comment, postToMainFeed, groupId, groupSubFeedId){
        return APIService.post('/feed/post/'+activity.foreign_id+'/repost', 
        {'analystId' : store.state.users.user.analyst.analystId, 'comment': comment, postToMainFeed: postToMainFeed, groupId: groupId, groupSubFeedId:groupSubFeedId});
    }

    promotePost( feedPostId, score) {
        return APIService.put('/feed/post/promote', {feedPostId : feedPostId, score: score,  actorUserId : store.state.users.user.userId});
    }


    queryPostLikers(feedPostId, query, limit, offset) {
        return APIService.get('/feed/post/'+feedPostId+'/liked?limit='+limit+"&page="+offset+"&q="+query);
    }

    getFollowedSecurities(query, limit, offset) {
        return APIService.get('/user/assets/following?limit='+limit+'&page='+offset+(query? '&q='+query : ''));
    }

    updateSpammerStatus(analystId, spammer){
        return APIService.put('/feed/spam/'+analystId+'?spammer='+spammer);
    }

    recordLook(activity) {
       
        let feedPostLook = {
            feedPostId : activity.foreign_id,
            activityId : (activity.id ? activity.id : null),
            
        }
        APIService.post('/feed/post/look', feedPostLook);
        
        
    }

    recordRead(activity) {
       
        let feedPostRead = {
            feedPostId : activity.foreign_id,
            activityId : (activity.id ? activity.id : null),
            
        }
        return APIService.post('/feed/post/read', feedPostRead);
    }

    async removeReaction(reactionId) {
        let client =  await stream.connect(
            STREAM_KEY,  
            store.state.feedTokenSet["timeline"],
            STREAM_APP_ID
        );

        return client.reactions.delete(reactionId);
    }

    reactToPost(activity, reaction) {
        let feedPostReaction = {
            analystId : store.state.users.user.analyst.analystId,
            feedPostId : Number(activity.foreign_id),
            activityId: activity.id,
            reactionKind: reaction
        }

        if (activity.actor.id !== feedPostReaction.analystId) {
            return APIService.post('/feed/post/emoji', feedPostReaction);
        }
    }

    updateModuleLessonOrdering(groupId, courseId, groupFeedId, postIds){
        return APIService.put(`/feed/group/${groupId}/course/${courseId}/module/${groupFeedId}/lessons/ordering`, {postIds : postIds});
    }

    mute(userId) {
        return APIService.post(`/feed/mute`, {userId : userId});
    }

    unmute(userId) {
        return APIService.post(`/feed/unmute`, {userId : userId});
    }

}

const FeedService = new FeedServiceImpl();
export default FeedService;