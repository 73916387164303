import axios from 'axios'
import { API_URL } from "@/common/config";
import store from '../store/index';

import EventBus from '../event-bus';


const UNAUTHORIZED = 401;

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 60000
});


apiClient.interceptors.request.use((config) => {
  // We are importing store before it is populated.
  // We intercept the request and use the current apiKey
  if( store.state.token != null ) {
    config.headers = {
      Accept: 'application/json',
      
      'Authorization': 'Bearer '+ store.state.token
    };
  }
  else {
    config.headers = {
      Accept: 'application/json',
     
      'Authorization': 'Bearer '+ store.state.nonce
    };
  }
  return config;
 });

 apiClient.interceptors.response.use(function (response) {
  
  let renewRequired = response.headers['renew-token'];
  //console.debug(JSON.stringify(response.headers));
  if( renewRequired ) {
   
      EventBus.emit('reauthorization-required');
    
  }

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if( error.response && error.response.status == UNAUTHORIZED) {
    
    EventBus.emit('authorization-required');
  }
  return Promise.reject(error);
});

const APIService = {
    
   addHeaders(xhr){
    xhr.setRequestHeader("Authorization", 'Bearer '+ store.state.token);
    xhr.setRequestHeader("Accept", "application/json");
   },
    
    getExternal(url) {
      let externalApiClient = axios.create();
      return externalApiClient.get(url);
      
    },
    get(resource) {
      return apiClient.get(resource);
    },

   
  
    post(resource, params) {
      return apiClient.post(`${resource}`, params);
    },

   

    postFile( resource, file) {
      let formData = new FormData();
      formData.append('file', file);
      return apiClient.post(`${resource}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
  
    update(resource, params) {
      return apiClient.put(resource, params);
    },
  
    put(resource, params) {
      return apiClient.put(resource, params);
    },
  
    delete(resource) {
      return apiClient.delete(resource);
    }
  };
  
  export default APIService;