import store from '../store/index';


class LocalStorageServiceImpl {
  APP_STATE_KEY = 'APP_STATE';
  GROUP_INVITE_CODE = "GROUP_INVITE_CODE";

  appState = {
    selectedFeedType: null,
    selectedEquity: null,
    selectedFund: null,
    selectedGroup: null,
    selectedMarketIndex: null,
    currentNote: null,
    selectedChartParams: null,
    feedTokenSet: null,
    chatToken: null,
    equityOverview: null,
    searchBarText: null,
    registered: false,
    authorized: false,
    sid: null,
    chatNotificationCounts: null,
    feedNotificationCounts: null,
    savedCompData: null,
    recentlyViewedList: null,
    featureSettings: null,
    user: {},
    hasProxy: false,
    featuredGroupFeed: null,
    checksum: null,
  };

  restoreAppState() {
    let appStateStr = localStorage.getItem(this.APP_STATE_KEY);
    if( appStateStr ) {
      console.debug("LocalStorageService attempting to restore app state from storage");
      this.appState = JSON.parse(appStateStr);
      this.updateVuexStateFromStorage();
    }
  }

  persistCurrentState() {
    this.updateAppStateFromVuex();
    this.storeAppState();
    
  }

  updateAppStateFromVuex() {
    this.appState.selectedFeedType = store.state.selectedFeedType;
    this.appState.selectedEquity = store.state.selectedEquity;
    this.appState.selectedFund = store.state.selectedFund;
    this.appState.selectedGroup = store.state.selectedGroup;
    this.appState.currentGroupPortfolios = store.state.currentGroupPortfolios;
    this.appState.selectedMarketIndex = store.state.selectedMarketIndex;
    this.appState.currentNote = store.state.currentNote;
    this.appState.searchBarText = store.state.searchBarText;
    this.appState.selectedChartParams = store.state.selectedChartParams;
    this.appState.feedTokenSet = store.state.feedTokenSet;
    this.appState.chatToken = store.state.chatToken;
    this.appState.equityOverview = store.state.equityOverview;
    this.appState.registered = store.state.registered;
    this.appState.authorized = store.state.authorized;
    this.appState.sid = store.state.sid;
    this.appState.chatNotificationCounts = store.state.chatNotificationCounts;
    this.appState.feedNotificationCounts = store.state.feedNotificationCounts
    this.appState.savedCompData = store.state.savedCompData;
   this.appState.recentlyViewedList = store.state.recentlyViewedList;
   this.appState.featureSettings = store.state.featureSettings
   this.appState.user = store.state.users?.user;
   this.appState.hasProxy = store.state.hasProxy;
   this.appState.featuredGroupFeed = store.state.featuredGroupFeed;
   this.appState.checksum = store.state.checksum;
  }

  storeAppState() {
    localStorage.setItem(this.APP_STATE_KEY, JSON.stringify( this.appState))
  }

  storeObject(key, obj) {
    localStorage.setItem(key, JSON.stringify( obj))
  }

  getObject(key){
    let item = localStorage.getItem(key)
    if( item ){
      return JSON.parse(item);
    }
    else {
      return null;
    }
  }

  removeObject(key){
    localStorage.removeItem(key);
  }

  clearAppState(){
    localStorage.removeItem(this.APP_STATE_KEY);
  }

  storeString(key, obj) {
    localStorage.setItem(key, obj);
  }
  getString(key) {
    return localStorage.getItem(key);
  }

  removeString(key) {
    localStorage.removeItem(key);
  }

  storeGroupInviteCode(code) {
    localStorage.setItem(this.GROUP_INVITE_CODE, code);
  }

  getGroupInviteCode() {
    return localStorage.getItem(this.GROUP_INVITE_CODE); 
  }

  removeGroupInviteCode() {
    localStorage.removeItem(this.GROUP_INVITE_CODE);
  }



  updateVuexStateFromStorage() {
   // if( this.appState.selectedEquity) {
     /* This whole block ensures that if something goes wrong with committing new data to the state, then make the data that was about 
     to be committed persistent onto the user's browser */
     try {
      store.commit('SET_AUTO_PERSIST', false); 
      store.commit('SET_SELECTED_FEED_TYPE', this.appState.selectedFeedType);
      store.commit('SET_SELECTED_EQUITY', this.appState.selectedEquity);
      store.commit('SET_SELECTED_FUND', this.appState.selectedFund);
      store.commit('SET_SELECTED_GROUP', this.appState.selectedGroup);
      store.commit('SET_CURRENT_GROUP_PORTFOLIOS', this.appState.currentGroupPortfolios);
      store.commit('SET_SELECTED_MARKET_INDEX', this.appState.selectedMarketIndex);
      store.commit('SET_CURRENT_NOTE', this.appState.currentNote);
      store.commit('SET_SEARCH_BAR_TEXT', this.appState.searchBarText);
      store.commit('SET_SELECTED_CHART_PARAMS', this.appState.selectedChartParams);
      if (this.appState.chatToken ){
        store.commit('SET_CHAT_TOKEN', this.appState.chatToken);
      }
      if( this.appState.feedTokenSet ) {
        store.commit('SET_FEED_TOKEN_SET', this.appState.feedTokenSet);
      }
      store.commit('SET_EQUITY_OVERVIEW', this.appState.equityOverview);
      store.commit('SET_REGISTERED', this.appState.registered);
      store.commit('SET_AUTHORIZED', this.appState.authorized);
      store.commit('SET_SID', this.appState.sid);
      store.commit('SET_CHAT_NOTIFICATION_COUNTS', this.appState.chatNotificationCounts);
      store.commit('SET_FEED_NOTIFICATION_COUNTS', this.appState.feedNotificationCounts);
      store.commit('SET_SAVED_COMP_DATA', this.appState.savedCompData);
      store.commit('SET_RECENTLY_VIEWED_LIST', this.appState.recentlyViewedList);
      store.commit('SET_FEATURE_SETTINGS', this.appState.featureSettings);
      store.commit('users/SET_USER', this.appState.user);
      store.commit('SET_HAS_PROXY', this.appState.hasProxy);
      store.commit('SET_FEATURED_GROUP_FEED', this.appState.featuredGroupFeed)
      store.commit('SET_CHECKSUM', this.appState.checksum);
     } finally {
      store.commit('SET_AUTO_PERSIST', true); 
     }
    //}
  }
  
}

const LocalStorageService = new LocalStorageServiceImpl();
export default LocalStorageService;