

import { createStore } from 'vuex'
import * as users from '@/store/modules/users.js'
import LocalStorageService from '../service/LocalStorageService';


let store = createStore({
  
  modules: {
    users,
   
  },
  state: {
    nonce: null,
    token: null,
    authorized: false,
    authExpiry: null,
    forumToken: null,
    feedTokenSet: new Map(),
    qmodReady: false,
    forumLoaded: false,
    showNotesOnly: true,
    selectedFeedType: null,
    selectedEquity: null,
    selectedChartSymbol: null,
    sid: null,
    chatToken: null,
    selectedFund: null,
    selectedMarketIndex: null,
    currentNote: null,
    searchBarText: null,
    autoPersist: true,
    selectedGroup: null,
    selectedContestPuzzle: null,
    

    selectedChartParams: null,
    equityOverview: null,
    showFollowedUserFeedsOnly: false,
    registered: false,
    chatNotificationCounts: null,
    feedNotificationCounts: null,
    groupChannelsUnreadCounts: {},

    savedCompData: null,
    recentlyViewedList: null,

    noteImageQueue: [],
    affiliate: null,
    systemNotifications: null,

    chatState: {channelsAreStale: true, myChannels : []},
    chatStateDM: {channelsAreStale: true, myChannels : []},
    chatMode: "groups",

    featureSettings: null,

    groupInviteCode: null,

    currentlySelectedPortfolio: null,
    recentlyViewedPortfolio: null,
    portfolioAllocationData: null,
    holdingsAllocationData: null,
    portfolioHoldingsData: null,
    portfolioPerformanceData: null,
    comparisonPerformanceData: null,
    accountList: null,
    transactionData: null,
    ordersData: null,
    topPerformers: null,
    groupHistory: {},
    selectedGroupConduit: null,
    lastSelectedGroupMenu: null,
    currentGroupPortfolios: null,

    currentTour: null,
    hasProxy: false,
    featuredGroupFeed: null,
    checksum: null,
    courseModuleLessons: [],
    openCourseModules: [],
    lastViewedModuleLessons:[],
    lastViewedCourse: [],
    compareMetric: null,
    listCompareMetrics: [],

  },
  getters: {
    feedItems: (state) => {
      return state.feed;
    },

    currentPortfolio(state) {
      return state.currentlySelectedPortfolio;
    },

    recentPortfolio(state) {
      return state.recentlyViewedPortfolio;
    },
    
    portfolioHoldingsData(state) {
      return state.portfolioHoldingsData;
    },

    portfolioAllocationData(state) {
      return state.portfolioAllocationData;
    },

    holdingsAllocationData(state) {
      return state.holdingsAllocationData;
    },

    portfolioPerformanceData(state) {
      return state.portfolioPerformanceData;
    },

    comparisonPerformanceData(state) {
      return state.comparisonPerformanceData;
    },

    accountList(state) {
      return state.accountList;
    },

    transactionData(state) {
      return state.transactionData;
    },

    ordersData(state) {
      return state.ordersData;
    },

    featureSettings(state) {
      return state.featureSettings;
    },

    roleList(state) {
      return state.users.user.roles;
    },

    groupRoleList(state) {
      return state.users.user.groupRoles; //Note: yes these are only set when u visit a group so they should be up to date.
    },

    subscriptionList(state) {
      return state.users.user.subscriptions;
    },

    
  },
  mutations: {
    SET_COMPARE_METRIC(state, val){
     // console.log("changing compare metric from ", state.compareMetric, " to ", val);
      state.compareMetric = val;
    },

    SET_LIST_COMPARE_METRICS(state, val){
      state.listCompareMetrics = val;
    },

    SET_CHECKSUM(state, val){
      state.checksum = val;
    },
    
    SET_FEATURED_GROUP_FEED( state, val) {
      state.featuredGroupFeed = val;
    },
    SET_HAS_PROXY( state, val) {
      state.hasProxy = val;
    },

    SET_LAST_SELECTED_GROUP_MENU( state, label) {
      state.lastSelectedGroupMenu = label;
    },
    
    SET_CHAT_MODE( state, mode) {
      state.chatMode = mode;
    },

    SET_SELECTED_GROUP_CONDUIT( state, conduit) {
      //console.log("setting group conduite to : ", conduit);
     // console.trace();
      state.selectedGroupConduit = conduit;
    },

    SET_CURRENT_GROUP_PORTFOLIOS( state, portfolios) {
      state.currentGroupPortfolios = portfolios;
    },

    SET_GROUP_ACCESS_HIST(state, entry) {
      // if( entry.type == "channel") {
      //   console.log("setting group access history for group " + entry.groupId + " type = "+ entry.type +" obj = " + entry.obj.channelTitle);
      // }
      let hist  = state.groupHistory[entry.groupId];
      if( hist == null ) {
        hist = {lastConduit : entry.conduit };
        state.groupHistory[entry.groupId] = hist;
      }
      else {
        hist.lastConduit = entry.conduit;
      }

      
    },
    SET_FEATURE_SETTINGS( state, featureSettings) {
      state.featureSettings = featureSettings;
    },

    SET_CHAT_STATE(state, chatState) {
      state.chatState.channelsAreStale = chatState.channelsAreStale;
      state.chatState.myChannels = chatState.myChannels;
      
    },

    SET_CHAT_STATE_DM(state, chatStateDM) {
      state.chatStateDM.channelsAreStale = chatStateDM.channelsAreStale;
      state.chatStateDM.myChannels = chatStateDM.myChannels;
      
    },

    MARK_CHANNELS_STALE(state) {
      state.chatState.channelsAreStale = true;
      
    },

    MARK_CHANNELS_STALE_DM(state) {
      state.chatStateDM.channelsAreStale = true;
      
    },

    ADD_IMAGE_TO_NOTE( state, url) {
      state.noteImageQueue.push(url);
    },
    CLEAR_NOTE_IMAGE_QUEUE(state) {
      state.noteImageQueue = [];
    },
    MARK_FEED_NOTIFICATIONS_SEEN(state){
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numFollows = 0;
        state.feedNotificationCounts.unseen.numLikes = 0;
        state.feedNotificationCounts.unseen.numMentions = 0;
        state.feedNotificationCounts.unseen.numComments = 0;
        state.feedNotificationCounts.unseen.numReposts = 0;
        state.feedNotificationCounts.unseen.numGroupInvites = 0;
        state.feedNotificationCounts.unseen.numGroupJoins = 0;
        state.feedNotificationCounts.unseen.numDmRequests = 0;
        state.feedNotificationCounts.unseen.numDmAcceptances = 0;
        state.feedNotificationCounts.unseen.numAlerts = 0;
        state.feedNotificationCounts.unseen.numTrades = 0;
        state.feedNotificationCounts.unseen.numJoinedGroup = 0;
        state.feedNotificationCounts.unseen.numMotionsSubmitted = 0;
        state.feedNotificationCounts.unseen.numMotionsPassed = 0;
        state.feedNotificationCounts.unseen.numMotionsFailed = 0;
        state.feedNotificationCounts.unseen.numMotionsWithdrawn = 0;
        state.feedNotificationCounts.unseen.numMotionsVetoed = 0;
        state.feedNotificationCounts.unseen.numActionRewards = 0;
        state.feedNotificationCounts.unseen.numPollRewards = 0;
        state.feedNotificationCounts.unseen.numFundsTransferred = 0;
        state.feedNotificationCounts.unseen.numContestPosts = 0;
      }
    },
    ADD_FOLLOW_COUNTS(state, val){
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numFollows += val.length;
        state.feedNotificationCounts.unread.numFollows += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
       
      }
    },
    ADD_LIKE_COUNTS(state, val){
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numLikes += val.length;
        state.feedNotificationCounts.unread.numLikes += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
      }
    },
    ADD_MENTION_COUNTS(state, val){
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numMentions += val.length;
        state.feedNotificationCounts.unread.numMentions += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
      }
    },
    ADD_COMMENT_COUNTS(state, val){
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numComments += val.length;
        state.feedNotificationCounts.unread.numComments += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
      }
    },
    ADD_REPOST_COUNTS(state, val){
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numReposts += val.length;
        state.feedNotificationCounts.unread.numReposts += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
      }
    },
    ADD_GROUP_INVITE_COUNTS(state, val){
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numGroupInvites += val.length;
        state.feedNotificationCounts.unread.numGroupInvites += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
      }
    },
    ADD_GROUP_JOIN_COUNTS(state, val) {
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numGroupJoins += val.length;
        state.feedNotificationCounts.unread.numGroupJoins += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
      }
    },
    ADD_DM_REQUEST_COUNTS(state, val) {
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numDmRequests += val.length;
        state.feedNotificationCounts.unread.numDmRequests += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
      }
    },
    ADD_DM_ACCEPTANCE_COUNTS(state, val) {
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numDmAcceptances += val.length;
        state.feedNotificationCounts.unread.numDmAcceptances += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups)
      }
    },
    ADD_ALERT_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numAlerts += val.length;
        state.feedNotificationCounts.unread.numAlerts += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_TRADE_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numTrades += val.length;
        state.feedNotificationCounts.unread.numTrades += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_JOINED_GROUP_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numJoinedGroup += val.length;
        state.feedNotificationCounts.unread.numJoinedGroup += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_ACTION_REWARD_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numActionRewards += val.length;
        state.feedNotificationCounts.unread.numActionRewards += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_POLL_REWARDS_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numPollRewards += val.length;
        state.feedNotificationCounts.unread.numPollRewards += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_FUNDS_TRANSFERRED_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numFundsTransferred += val.length;
        state.feedNotificationCounts.unread.numFundsTransferred += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },

    ADD_CONTEST_POSTS_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numContestPosts += val.length;
        state.feedNotificationCounts.unread.numContestPosts += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },

    ADD_GROUP_POST_ACTIVITY(state, notification) {
      // console.log("ADD_GROUP_POST_ACTIVITY", notification);
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numGroupPosts += 1;
      }

      const newAct = notification.activities[0];
      const feedPost = newAct.extra.feedPost;

      // todo courseId

      if(newAct && feedPost){
        
        const groupId = feedPost.groupId;

        if(groupId){
          const existingUnseenGroup = state.feedNotificationCounts.unseenGroupNotifications[groupId];
          state.feedNotificationCounts.unseenGroupNotifications[groupId] = addGroupPostActivity(feedPost, notification.id, existingUnseenGroup);

          const existingUnreadGroup = state.feedNotificationCounts.unreadGroupNotifications[groupId];
          state.feedNotificationCounts.unreadGroupNotifications[groupId] = addGroupPostActivity(feedPost, notification.id, existingUnreadGroup);

          if (state.autoPersist) {
            LocalStorageService.persistCurrentState();
          }
        }
      }
    },

    ADD_MOTIONS_SUBMITTED_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numMotionsSubmitted += val.length;
        state.feedNotificationCounts.unread.numMotionsSubmitted += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_MOTIONS_PASSED_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numMotionsPassed += val.length;
        state.feedNotificationCounts.unread.numMotionsPassed += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_MOTIONS_FAILED_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numMotionsFailed += val.length;
        state.feedNotificationCounts.unread.numMotionsFailed += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_MOTIONS_WITHDRAWN_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numMotionsWithdrawn += val.length;
        state.feedNotificationCounts.unread.numMotionsWithdrawn += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },
    ADD_MOTIONS_VETOED_COUNTS(state, val) {
      if (state.feedNotificationCounts) {
        state.feedNotificationCounts.unseen.numMotionsVetoed += val.length;
        state.feedNotificationCounts.unread.numMotionsVetoed += val.length;
        addActivityToGroup(val, state.feedNotificationCounts.activityGroups);
      }
    },

    REMOVE_GROUP_POST_NOTIFICATIONS_SEEN(state, groupId) {
      if( state.feedNotificationCounts ){
        state.feedNotificationCounts.unseen.numGroupPosts = 0;
        
        delete state.feedNotificationCounts.unseenGroupNotifications[groupId];

        if (state.autoPersist) {
          LocalStorageService.persistCurrentState();
        }
      }
    },

    REMOVE_GROUP_POST_FEED_NOTIFICATIONS_READ(state, feed) {
      if (state.feedNotificationCounts) {
        const unreadGroupNotifications = state.feedNotificationCounts?.unreadGroupNotifications[feed.groupId];
        if(feed.groupFeedId == 0){
          state.feedNotificationCounts.unreadGroupNotifications[feed.groupId].allCount = 
            unreadGroupNotifications.allCount - unreadGroupNotifications.latestCount;

          if(state.feedNotificationCounts.unreadGroupNotifications[feed.groupId].allCount > 0){
            state.feedNotificationCounts.unreadGroupNotifications[feed.groupId].latestCount = 0;
            state.feedNotificationCounts.unreadGroupNotifications[feed.groupId].latestNotificationIds = [];
          }
        } else {
          const groupFeedNotifications = state.feedNotificationCounts?.unreadGroupNotifications[feed.groupId]?.groupFeedNotifications;

          if(groupFeedNotifications){
            const groupFeedNotificationIndex = groupFeedNotifications.findIndex(groupFeedNotification => feed.groupFeedId == groupFeedNotification.feedId);
            state.feedNotificationCounts.unreadGroupNotifications[feed.groupId].allCount = 
              unreadGroupNotifications.allCount - groupFeedNotifications[groupFeedNotificationIndex]?.feedCount;
            state.feedNotificationCounts.unreadGroupNotifications[feed.groupId].groupFeedNotifications.splice(groupFeedNotificationIndex, 1);
          }
        }

        if(!state.feedNotificationCounts.unreadGroupNotifications[feed.groupId].allCount 
          || state.feedNotificationCounts.unreadGroupNotifications[feed.groupId].allCount <= 0){
          delete state.feedNotificationCounts.unreadGroupNotifications[feed.groupId];
        } 

        if (state.autoPersist) {
          LocalStorageService.persistCurrentState();
        }
      }
    },

    SET_FEED_NOTIFICATION_COUNTS(state, val){
      state.feedNotificationCounts = val;
    },
    CLEAR_CHAT_COUNTS(state){
      if( state.chatNotificationCounts) {
        state.chatNotificationCounts.numUnread = 0;
        state.chatNotificationCounts.numMentions = 0;
        state.chatNotificationCounts.numGroupMentions = 0;
      }
    },

    ADD_CHAT_UNREAD_COUNTS(state, val){
      if( !state.chatNotificationCounts) {
      
        state.chatNotificationCounts = {
          numUnread : 0,
          numMentions : 0,
          numGroupMentions : 0
        }
      }
      state.chatNotificationCounts.numUnread += val;
    },
    SET_CHAT_UNREAD_COUNTS(state, val){
      if( !state.chatNotificationCounts) {
      
        state.chatNotificationCounts = {
          numUnread : 0,
          numMentions : 0,
          numGroupMentions : 0
        }
      }
      state.chatNotificationCounts.numUnread = val;
    },
    ADD_CHAT_MENTION_COUNTS(state, val){
      if( !state.chatNotificationCounts) {
      
        state.chatNotificationCounts = {
          numUnread : 0,
          numMentions : 0,
          numGroupMentions : 0
        }
      }
      
      state.chatNotificationCounts.numMentions += val;
     
    },

    SET_CHAT_NOTIFICATION_COUNTS(state, val){
      state.chatNotificationCounts = val;
    },

    SET_GROUPS_CHANNELS_UNREAD_COUNTS(state, val){
      state.groupChannelsUnreadCounts = val ? val : {};
    },

    ADD_GROUP_CHANNEL_UNREAD_COUNTS(state, val){
      if(state.groupChannelsUnreadCounts.unreadCount){
        state.groupChannelsUnreadCounts.unreadCount += 1;
      } else {
        state.groupChannelsUnreadCounts["unreadCount"] = 1;
      }

      if(state.groupChannelsUnreadCounts[val.groupId]?.unreadCount){
        state.groupChannelsUnreadCounts[val.groupId].unreadCount += 1;
        if(state.groupChannelsUnreadCounts[val.groupId][val.chatChannelId]?.unreadCount){
          state.groupChannelsUnreadCounts[val.groupId][val.chatChannelId].unreadCount += 1;
        } else {
          state.groupChannelsUnreadCounts[val.groupId][val.chatChannelId] = {unreadCount: 1};
        }
      } else {  
        state.groupChannelsUnreadCounts[val.groupId] = {
          unreadCount: 1,
          [val.chatChannelId]: {unreadCount: 1}
        };
      }
    },

    REMOVE_GROUP_CHANNEL_UNREAD_COUNTS(state, val){
      if(state.groupChannelsUnreadCounts[val.groupId]?.[val.chatChannelId]){
        
        if(state.groupChannelsUnreadCounts[val.groupId]?.[val.chatChannelId]?.unreadCount){
          state.groupChannelsUnreadCounts.unreadCount -= state.groupChannelsUnreadCounts[val.groupId][val.chatChannelId].unreadCount;
          state.groupChannelsUnreadCounts[val.groupId].unreadCount -= state.groupChannelsUnreadCounts[val.groupId][val.chatChannelId].unreadCount;
        }
      
        delete state.groupChannelsUnreadCounts[val.groupId][val.chatChannelId];
      }
    },

    SET_REGISTERED(state, val) {
      state.registered = val;
    },

    SET_SHOW_FOLLOWED_USER_FEEDS_ONLY( state, val) {
      state.showFollowedUserFeedsOnly = val;
    },

    SET_EQUITY_OVERVIEW(state, overview) {
      state.equityOverview = overview;
      if (state.autoPersist)
        LocalStorageService.persistCurrentState();
    },

    

    SET_SELECTED_CHART_PARAMS(state, chartParams) {
     
      state.selectedChartParams = chartParams;
      
    },

    SET_AFFILIATE(state, affiliate) {
      state.affiliate = affiliate;
    },

    SET_TOKEN(state,  token) {
      state.token = token;
     
      if( token != null ) {
        state.parsedToken = parseJwt(state.token);
      
        state.authExpiry = new Date(state.parsedToken.exp*1000);
       
        state.authorized = true;
      }
      else {
        state.authExpiry = null;
        state.authorized = false;
      }
    },
    SET_FORUM_TOKEN(state, token) {
      state.forumToken = token;
     
    },
    SET_FEED_TOKEN_SET(state, tokenSet) {
   
      state.feedTokenSet = tokenSet;
    
    },

    ADD_FEED_TOKEN( state, token){
      state.feedTokenSet[token.feedId] =  token.token;
    
    },
    SET_CHAT_TOKEN(state, token) {
      state.chatToken = token;
     
    },
    SET_NONCE(state,  nonce) {
      state.nonce = nonce;
     
    },
    SET_AUTHORIZED(state,  trueOrFalse) {
      state.authorized = trueOrFalse;
     
    },
    SET_QMOD_READY(state, trueOrFalse) {
      state.qmodReady = trueOrFalse;
    },
    SET_FORUM_LOADED(state, trueOrFalse) {
      state.forumLoaded = trueOrFalse;
    },

    SET_SELECTED_EQUITY(state, selectedEquity) {

      
      state.selectedEquity = selectedEquity;
      
      if (state.autoPersist)
        LocalStorageService.persistCurrentState();

    },

    SET_SELECTED_FEED_TYPE(state, selectedFeedType){
      state.selectedFeedType = selectedFeedType;

      if (state.autoPersist)
        LocalStorageService.persistCurrentState();
    },

    SET_SHOW_NOTES_ONLY(state, showNotesOnly){
      state.showNotesOnly = showNotesOnly;
      
      if (state.autoPersist)
        LocalStorageService.persistCurrentState();
    },

    SET_SELECTED_FUND(state, selectedFund) {
      state.selectedFund = selectedFund;
      
      if (state.autoPersist)
        LocalStorageService.persistCurrentState();
    },
    SET_SELECTED_MARKET_INDEX(state, selectedMarketIndex) {
      state.selectedMarketIndex = selectedMarketIndex;
      
      if (state.autoPersist)
        LocalStorageService.persistCurrentState();
    },

    SET_CURRENT_NOTE(state, note) {
      state.currentNote= note;
     
      if (state.autoPersist)
       LocalStorageService.persistCurrentState();
    },
    SET_SEARCH_BAR_TEXT(state, text) {
      state.searchBarText = text;

      if (state.autoPersist) {
        LocalStorageService.persistCurrentState();
      }
    },
    SET_SELECTED_CHART_SYMBOL(state, symbol) {
      state.selectedChartSymbol = symbol;
     
      if (state.autoPersist)
        LocalStorageService.persistCurrentState();
    },

    SET_SAVED_COMP_DATA(state, data) {
      state.savedCompData = data;

      if (state.autoPersist)
        LocalStorageService.persistCurrentState();
    },

    SET_RECENTLY_VIEWED_LIST(state, data) {
      state.recentlyViewedList = data;

      if (state.autoPersist) {
        LocalStorageService.persistCurrentState();
      }
    },

    SET_SID( state, sid) {
      state.sid = sid;
    },

    SET_AUTO_PERSIST(state, val) {
      state.autoPersist = val;
    }, 

    SET_SYSTEM_NOTIFICATIONS(state, val) {
      state.systemNotifications = val;
    },

    SET_GROUP_INVITE_CODE(state, val) {
      state.groupInviteCode = val;
    },

    SET_SELECTED_GROUP(state, selectedGroup) {
      state.selectedGroup = selectedGroup;

      if (state.autoPersist) {
        LocalStorageService.persistCurrentState();
      }
    },

    SET_SELECTED_CONTEST_PUZZLE(state, val) {
      state.selectedContestPuzzle = val;
    },

    SET_CURRENTLY_SELECTED_PORTFOLIO(state, data) {
      state.currentlySelectedPortfolio = data;
    },
    
    SET_RECENTLY_VIEWED_PORTFOLIO(state, data) {
      state.recentlyViewedPortfolio = data;
    },

    SET_PORTFOLIO_ALLOCATION_DATA(state, data) {
      state.portfolioAllocationData = data;
    },

    SET_TOP_PERFORMERS(state, data) {
      state.topPerformers = data;
    },
    
    SET_HOLDINGS_ALLOCATION_DATA(state, data) {
      state.holdingsAllocationData = data;
    },

    SET_PORTFOLIO_HOLDINGS_DATA(state, data) {
      state.portfolioHoldingsData = data;
    },
    
    SET_PORTFOLIO_PERFORMANCE_DATA(state, data) {
      state.portfolioPerformanceData = data;
    },

    SET_COMPARISON_PERFORMANCE_DATA(state, data) {
      state.comparisonPerformanceData = data;
    },

    SET_ACCOUNT_LIST(state, data) {
      //console.log("*************************** setting account list to " +JSON.stringify(data));
      state.accountList = data;
      //LocalStorageService.persistCurrentState();
    },

    SET_TRANSACTION_DATA(state, data) {
      state.transactionData = data;
    },

    SET_ORDERS_DATA(state, data) {
      state.ordersData = data;
    },

    SET_CURRENT_TOUR(state, data) {
      state.currentTour = data;
    },

    SET_COURSE_MODULE_LESSONS(state, data) {
     
      state.courseModuleLessons[data.feedId] = data.lessons;
      //console.log("setting module lessions for module "+ data.feedId+" to ", data);
      
    },

    ADD_OPEN_COURSE_MODULE(state, groupFeedId) {
      state.openCourseModules[groupFeedId] = true;
    },

    REMOVE_OPEN_COURSE_MODULE(state, groupFeedId) {
      state.openCourseModules[groupFeedId] = false;
    },

    SET_LAST_VIEWED_MODULE_LESSON(state, item) {
      state.lastViewedModuleLessons[item.groupFeedId] = item.activity;
    },

    SET_LAST_VIEWED_COURSE(state, item){
      state.lastViewedCourse[item.groupId] = item.course;
    },


    CLEAR_PORTFOLIO_STATE(state) {
      state.recentlyViewedPortfolio = null;
      state.portfolioAllocationData = null;
      state.portfolioHoldingsData = null;
      state.portfolioPerformanceData = null;
      state.comparisonPerformanceData = null;
      state.accountList = null;
      state.transactionData = null;
      state.ordersData = null;
    }
  },

  actions: {
    setRecentPortfolio(context, payload) {
      context.commit('SET_RECENTLY_VIEWED_PORTFOLIO', payload);
    },

    setPortfolioAllocationData(context, payload) {
      context.commit('SET_PORTFOLIO_ALLOCATION_DATA', payload);
    },

    setPortfolioHoldingsData(context, payload) {
      context.commit('SET_PORTFOLIO_HOLDINGS_DATA', payload);
    },

    setPortfolioPerformanceData(context, payload) {
      context.commit('SET_PORTFOLIO_PERFORMANCE_DATA', payload);
    },

    setComparisonPerformanceData(context, payload) {
      context.commit('SET_COMPARISON_PERFORMANCE_DATA', payload);
    },

    setAccountList(context, payload) {
      context.commit('SET_ACCOUNT_LIST', payload);
    },

    setTransactionData(context, payload) {
      context.commit('SET_TRANSACTION_DATA', payload);
    },

    clearPortfolioState(context) {
      context.commit('CLEAR_PORTFOLIO_STATE');
    },

    setHoldingsAllocationData(context, payload) {
      context.commit('SET_HOLDINGS_ALLOCATION_DATA', payload);
    },

    setSelectedEquity(context, payload) {
      context.commit('SET_SELECTED_EQUITY', payload.selectedEquity);
    },

    setSelectedFeedType(context, payload) {
      context.commit('SET_SELECTED_FEED_TYPE', payload.selectedFeedType);
    }
  },

  methods : {

  },
  

  
 
})


function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  
  return JSON.parse(jsonPayload);
}

function addActivityToGroup(newActs, existingGroups){
  newActs.forEach(newAct => {
    existingGroups.forEach(group => {
      
      if( group.group == newAct.group ) {
        group.activities.push(newAct);
        ++group.actorCount;
      }
    });
  });
}

function addGroupPostActivity(feedPost, notificationId, existingGroup){
  const groupId = feedPost.groupId;
  const groupFeedId = feedPost.groupFeedId; // latest = undefined
  let feedNotificationIds = [];
  let latestNotificationIds = [];
  let groupFeedNotifications = [];
  let latestCount = 0;
  let allCount = 0;
  let feedCount = 0;

  // Init If Exists
  let existingGroupFeed;
  if(existingGroup){
    latestNotificationIds = existingGroup.latestNotificationIds;
    latestCount = existingGroup.latestCount;
    allCount = existingGroup.allCount;
    
    if(existingGroup.groupFeedNotifications && existingGroup.groupFeedNotifications.length>0){
      groupFeedNotifications = existingGroup.groupFeedNotifications;
      existingGroupFeed = groupFeedNotifications.find(feed=>feed.feedId==groupFeedId);
      if(existingGroupFeed){
        feedNotificationIds = existingGroupFeed.feedNotificationIds;
        feedCount = existingGroupFeed.feedCount;
      }
    }
  }

  // Update data
  if(groupFeedId == undefined) {
    latestNotificationIds.push(notificationId);
    latestCount++;
  } else {
    if(existingGroupFeed){
      const index = groupFeedNotifications.findIndex(feed=>feed.feedId==groupFeedId);
      groupFeedNotifications[index].feedCount++;
      groupFeedNotifications[index].feedNotificationIds.push(notificationId);
    } else {
      feedNotificationIds.push(notificationId);
      feedCount++;
      groupFeedNotifications.push({
        feedId: groupFeedId,
        feedCount: feedCount,
        feedNotificationIds:feedNotificationIds
      });
    }
  }
  allCount++;

  // Return new or updated activity
  return {
    groupId: groupId,
    latestCount: latestCount,
    allCount: allCount,
    latestNotificationIds: latestNotificationIds,
    groupFeedNotifications: groupFeedNotifications
  };
}

export default store
