export const WHO_TO_FOLLOW_TYPES = {
    'HOME_DISCOVER': 'HomeDiscover',
    'GROUP_PROFILE': 'GroupProfile'
}

export const USER_TYPES = {
    'USER': 'User',
    'GROUP': 'Group'
}

export const USER_TYPE_USER_ID = 1;
export const USER_TYPE_GROUP_ID = 2;

export const ASSET_TYPES = {
    'EQUITY': 1,
    'FIXED_INCOME': 2,
    'CASH': 3,
    'ETF': 4,
    'FUND': 5,
    'OTHER': 6,
    'DERIVATIVE': 7,
    'MUTUAL_FUND': 8,
    'LOAN': 9,
    'CRYPTO': 10,
    'REAL_ESTATE': 11
}

export const ALERT_TYPES_WITH_VALUES = [3, 4, 8, 9, 11, 12, 31, 32, 33, 34, 35];
export const ALERT_PRICE_TYPES = [4, 8, 11, 12, 13];

export const PREMIUM_SUBSCRIPTION_TYPES = {
    'PREMIUM_ANALYSIS': 'PremiumAnalysis',
    'PREMIUM_PORTFOLIO': 'PremiumPortfolio'
}

export const PREMIUM_PASS_IDS = [4, 5, 6, 11];

export const HOME_TOUR_NAME = 'Home';
export const SINGLE_STOCK_PAGE_TOUR_NAME = 'SingleStockPage';
export const EQUITY_OVERVIEW_TOUR_NAME = 'EquityOverview';
export const FUND_OVERVIEW_TOUR_NAME = 'FundOverview';
export const PORTFOLIO_TOUR_NAME = 'PortfolioOverview';

export const TOUR_ROUTE_MAP = {
    '/home': HOME_TOUR_NAME,
    '/equity': EQUITY_OVERVIEW_TOUR_NAME,
    '/fund': FUND_OVERVIEW_TOUR_NAME,
    '/portfolios': PORTFOLIO_TOUR_NAME
};

export const SHOW_OVERVIEW_PREFERENCE = 1;
export const SHOW_PORTFOLIO_OVERVIEW_PREFERENCE = 3;
export const ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE = 4;
export const ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE = 5;
export const ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE = 6;
export const ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE = 7;
export const DEFAULT_PORTFOLIO_SUMMARY_CURRENCY = 8;
export const SHOW_NOTES_ONLY = 9;
export const DEFAULT_FEED = 10;

export const TFSA_ACCOUNT_TYPE = {
    'ID': 2,
    'NAME': 'TSFA'
}
export const MARGIN_ACCOUNT_TYPE = {
    'ID': 6,
    'NAME': 'Margin'
}
export const MUTUAL_FUND_ACCOUNT_TYPE = {
    'ID': 53,
    'NAME': 'Mutual Fund'
}
export const CRYPTO_ACCOUNT_TYPE = {
    'ID': 72,
    'NAME': 'Crypto'
}
export const PAPER_ANALYSIS_ACCOUNT_TYPE = {
    'ID': 73,
    'NAME': 'Manual'
}
export const PAPER_TRADING_ACCOUNT_TYPE = {
    'ID': 74,
    'NAME': 'Paper Trading'
}
export const PAPER_CASH_ACCOUNT_TYPE = {
    'ID': 75,
    'NAME': 'Paper Cash Account'
}
export const COMPETITION_ACCOUNT_TYPE = {
    'ID': 76,
    'NAME': 'Competition'
}
export const EDUCATIONAL_ACCOUNT_TYPE = {
    'ID': 78,
    'NAME': 'Educational'
}
export const ACCOUNT_TYPES = {
    [TFSA_ACCOUNT_TYPE['ID']]: TFSA_ACCOUNT_TYPE['NAME'],
    [MARGIN_ACCOUNT_TYPE['ID']]: MARGIN_ACCOUNT_TYPE['NAME'],
    [MUTUAL_FUND_ACCOUNT_TYPE['ID']]: MUTUAL_FUND_ACCOUNT_TYPE['NAME'],
    [CRYPTO_ACCOUNT_TYPE['ID']]: CRYPTO_ACCOUNT_TYPE['NAME'],
    [PAPER_ANALYSIS_ACCOUNT_TYPE['ID']]: PAPER_ANALYSIS_ACCOUNT_TYPE['NAME'],
    [PAPER_TRADING_ACCOUNT_TYPE['ID']]: PAPER_TRADING_ACCOUNT_TYPE['NAME'],
    [COMPETITION_ACCOUNT_TYPE['ID']]: COMPETITION_ACCOUNT_TYPE['NAME'],
    [PAPER_CASH_ACCOUNT_TYPE['ID']]:PAPER_CASH_ACCOUNT_TYPE['NAME'],
    [EDUCATIONAL_ACCOUNT_TYPE['ID']]: EDUCATIONAL_ACCOUNT_TYPE['NAME'],
}

export const BUY_TRADE_TYPE = {
    'ID': 1,
    'NAME': 'Buy'
}
export const SELL_TRADE_TYPE = {
    'ID': 2,
    'NAME': 'Sell'
}

export const MARKET_ORDER_TYPE_ID = 1;
export const LIMIT_ORDER_TYPE_ID = 2;
export const STOP_LOSS_ORDER_TYPE_ID = 3;
export const STOP_LIMIT_ORDER_TYPE_ID = 4;
export const STOP_ENTRY_ORDER_TYPE_ID = 5;

export const CANADIAN_COUNTRY_ID = 1;
export const US_COUNTRY_ID = 2;

export const CANADIAN_CURRENCY_TYPE = {
    'ID': 1,
    'NAME': 'Canadian Dollar',
    'SYMBOL': 'CAD'
}

export const US_CURRENCY_TYPE = {
    'ID': 2,
    'NAME': 'US Dollar',
    'SYMBOL': 'USD'
}

export const CURRENCY_TYPES = {
    [CANADIAN_CURRENCY_TYPE['ID']]: {
        'NAME': CANADIAN_CURRENCY_TYPE['NAME'],
        'SYMBOL': CANADIAN_CURRENCY_TYPE['SYMBOL']
    },
    [US_CURRENCY_TYPE['ID']]: {
        'NAME': US_CURRENCY_TYPE['NAME'],
        'SYMBOL': US_CURRENCY_TYPE['SYMBOL']
    }
}

export const MARKET_CLOSE_DURATION_ID = 2;
export const UNTIL_CANCELLED_DURATION_ID = 1;
export const FILL_OR_KILL_DURATION_ID = 3;

export const GROUP_OWNER_ROLE_TYPE = {
    'ID': 5,
    'NAME': 'GROUP_OWNER'
};
export const GROUP_ADMIN_ROLE_TYPE = {
    'ID': 6,
    'NAME': 'GROUP_ADMIN'
};
export const GROUP_MOD_ROLE_TYPE = {
    'ID': 7,
    'NAME': 'GROUP_MOD'
}
export const GROUP_MEMBER_ROLE_TYPE = {
    'ID': 8,
    'NAME': 'GROUP_MEMBER'
}

export const AGGREGATE_PORTFOLIO_TYPE = {
    'ID': 1,
    'NAME': 'Aggregate'
}
export const COLLABORATIVE_PORTFOLIO_TYPE = {
    'ID': 2,
    'NAME': 'Collaborative'
}

export const MOTION_STATUS_ID = {
    draft: 1,
    submitted: 2,
    withdrawn: 3,
    passed: 4,
    failed: 5,
    vetoed: 6
}

export const MOTION_STATUS = {
    [MOTION_STATUS_ID.draft]:'Draft',
    [MOTION_STATUS_ID.submitted]:'Submitted',
    [MOTION_STATUS_ID.withdrawn]:'Withdrawn',
    [MOTION_STATUS_ID.passed]:'Passed',
    [MOTION_STATUS_ID.failed]:'Failed',
    [MOTION_STATUS_ID.vetoed]:'Vetoed'
}
export const SUBMITTED_MOTION_STATUS = 'submitted';
export const DRAFT_MOTION_STATUS = 'draft';
export const WITHDRAWN_MOTION_STATUS = 'withdrawn';
export const PASSED_MOTION_STATUS = 'passed';
export const FAILED_MOTION_STATUS = 'failed';
export const VETOED_MOTION_STATUS = 'vetoed';


export const YESNO_POLL = { name:'yesNo', pollTypeId:1};
export const MULTIPLE_CHOICE_POLL= { name:'multipleChoice', pollTypeId:2};
export const MULTIPLE_SELECT_POLL= { name: 'multipleSelection', pollTypeId:3};

export const ACTIONS_ID = {
    'submitMotion': 1,
    'motionPassed': 2,
    'voteOnMotion': 3,
    'createGeneralQuestion': 4,
    'answerGeneralQuestion': 5,
    'createYesNoQuestion': 6,
    'answerYesNoQuestion': 7,
    'createPoll': 8,
    'answerPoll': 9,
    'answerLiked': 10
}

export const COOKIE_SHARED_FEED_POST_ID = 'shared.feed.post.id';

export const COOKIE_SIGN_UP_CONTEST_ID = 'sign.up.contest.id';

export const COOKIE_CONTEST_FAILED_REG_MESSAGE = 'contest.failed.registration.message';

export const COOKIE_COURSE_REG_CODE = 'sign.up.course.reg.code';

export const GROUP_CONTEST_CATEGORIES = {
    portfolio: 1,
    research: 2,
    quiz: 3,
    puzzle: 4
};

export const RESET_COMPETITION_PRODUCT_ID = 10;

const ANNUAL = 1;
const QUARTERLY = 2;
const DAILY = 4;
export const BASIC_METRICS = [
{name: '1 Year Annual Revenue Growth Rate', measureId:33, valueType: 'Percent', periodTypes: [ANNUAL,QUARTERLY], description:"The year-over-year percent change in annual revenue."},
{name: 'Asset Turnover', measureId:79, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns asset turnover for the period and date(s) requested in local currency by default."},
{name: 'Beta', measureId:2, valueType: 'Actual', periodTypes: [DAILY], description: "Stock: The coefficient which measures the volatility of a stock's returns relative to the market."},
{name: 'Book Value Per Share', measureId:16, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Represents book value (proportioned common equity divided by outstanding shares) at the company's fiscal year end."},
{name: 'Current Ratio', measureId:3, valueType: 'Ratio', periodTypes: [ANNUAL,QUARTERLY], description: "Also known as Working Capital Ratio, this ratio measures short term debt paying ability.  Rule of Thumb - 2:1 is good."},
{name: 'Dividend Payout Ratio', measureId:17, valueType: 'Ratio', periodTypes: [ANNUAL,QUARTERLY], description: "The TTM dividend per share divided by TTM EPS, expressed as a percentage. The percentage indicates the percent of EPS that was paid out as a dividend."},
{name: 'Capital Expenditures Fixed Assets', measureId:105, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Represents the funds used to acquire fixed assets other than those associated with acquisitions."},
{name: 'Cash & Equivalents - Generic', measureId:108, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns cash and equivalents for the period and the dates requested in local currency by default. This represents Cash and Due from Banks excluding Restricted Cash for Banks, Cash excluding Restricted Cash for Insurance companies, and Cash and Short-Term Investments excluding Restricted Cash for all other industries."},
{name: 'Dividend Yield - Daily', measureId:155, valueType: 'Actual', periodTypes: [DAILY], description: "Returns the dividends per share divided by the most recent Last Close price, for the period and date(s) requested in local currency by default."},
{name: 'Depreciation & Amortization', measureId:145, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Depreciation represents the process of allocating the cost of a depreciable asset to the accounting periods covered during its expected useful life to a business. It is a non-cash charge for use and obsolescence of an asset."},
{name: 'Depreciation or Depletion (excluding Amortization)', measureId:147, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Depreciation represents the process of allocating the cost of a depreciable asset to the accounting periods covered during its expected useful life to a business. It is a non-cash charge."},
{name: 'Dividend Yield - Fiscal', measureId:156, valueType: 'Percent', periodTypes: [ANNUAL,QUARTERLY], description: "Stock: Returns the dividends per share divided by the fiscal period end price, multiplied by 100, for the period and date(s) requested in local currency by default. This value is available on an Annual basis, at the end of a company's fiscal calendar year. Exchange Traded Fund (ETF): Returns the sum of trailing 12 month (TTM) dividends per share divided by the last NAV. Closed-End Fund (CEF): Returns the aggregate dividend yields of the fund's holdings, weighted by market capitalization."},
{name: 'EBITDA (From Cash Flow)', measureId:162, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns earnings before interest, taxes, depreciation & amortization (EBITDA) with preference for depreciation & amortization from the Cash Flow statement for the period and date(s) requested in local currency by default."},
{name: 'Earnings Per Share - Fiscal Period', measureId:167, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Represents fiscal period earnings for the requested time period."},
{name: 'Free Cash Flow Per Share (Diluted)', measureId:194, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Returns free cash flow per share (diluted) for the period and date(s) requested."},
{name: 'Gross Income Margin', measureId:198, valueType: 'Percent', periodTypes: [ANNUAL,QUARTERLY], description: "Returns gross income margin for the period and date(s) requested. This is calculated as Gross Income divided by Net Sales or Revenue multiplied by 100."},
{name: 'Inventories (Cash Flow)', measureId:224, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Represents the change in the amount of inventories from one year to the next as reported in the cash flow statement."},
{name: 'Investments and Long-Term Receivables', measureId:231, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns investments and LT Receivables for the period and date(s) requested in local currency by default."},
{name: 'Market Capitalization - Fiscal', measureId:268, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns the market value using the fiscal period-end price for the period and date(s) requested in local currency by default."},
{name: 'Net Income - After Extraordinary Items', measureId:278, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns net income after extrardinary items for the period and date(s) requested in local currency by default."},
{name: 'Net Income From Total Operations', measureId:282, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Net income from total operations."},
{name: 'Net Investing Cash Flow', measureId:286, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns net cash from investing activities for the period and date(s) requested in local currency by default."},
{name: 'PE Ratio', measureId:319, valueType: 'Ratio', periodTypes: [DAILY], description: "Stock: Returns price divided by earnings per share for the period requested, using the most recent close price within that period. Exchange Traded Fund (ETF): Returns the fund weighted average ratio of prices of a fund's stocks to the trailing earnings of underlying stocks. Aggregate prices are scaled by the sum of individual earnings, including negative earnings. Closed-End Fund (CEF): Returns the market capitalization weighted average price/earnings ratio for the securities in the fund's portfolio."},
{name: 'PE Ratio - Fiscal', measureId:320, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Returns the close price divided by earnings per share using the fiscal period-end price for the period and date(s) requested in local currency by default. For quarterly or semi-annual periodicities, this formula uses a 12-month Earnings Per Share figure."},
{name: 'Pre Tax Interest Coverage Ratio', measureId:341, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Returns EBIT interest coverage for the period and date(s) requested."},
{name: 'Pre Tax Profit Margin', measureId:342, valueType: 'Percent', periodTypes: [ANNUAL,QUARTERLY], description: "Returns pretax margin for the period and date(s) requested."},
{name: 'Price To Book', measureId:343, valueType: 'Ratio', periodTypes: [DAILY], description:"Stock: The latest closing price divided by the latest book value per share (TTM). Exchange Traded Fund (ETF): Returns the fund weighted average ratio of prices of a fund's stocks to the book value of underlying stocks. Aggregate prices are scaled by the sum of individual book values. Closed-End Fund (CEF): Returns the market capitalization weighted average price/book ratio for the securities in the fund's portfolio."},
{name: 'Price To Book - Fiscal', measureId:344, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Returns price to book value using price as of the last trading day for the period and date(s) requested in local currency by default."},
{name: 'Price To Cash Flow', measureId:345, valueType: 'Ratio', periodTypes: [ANNUAL,QUARTERLY], description: "Returns price to cash flow ratio for the period and date(s) requested."},
{name: 'Price to Earnings Growth Ratio', measureId:318, valueType: 'Ratio', periodTypes: [DAILY], description: "The trailing PEGRatio is the PERatio divided by the annual trailing EPS growth rate as of the AsOfDate."},
{name: 'Price To Free Cash Flow', measureId:346, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Returns price to free cash flow for the period and date(s) requested in local currency by default."},
{name: 'Price To Sales - Fiscal', measureId:347, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Returns the close price divided by sales per share using the fiscal period-end price for the period and date(s) requested in local currency by default."},
{name: 'Price To Tangible Book', measureId:349, valueType: 'Ratio', periodTypes: [DAILY], description: "The latest close price divided by latest tangible book value per share."},
{name: 'Price To Tangible Book - Fiscal', measureId:348, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Price to tangible book value as reported in the company's balance sheet."},
{name: 'Quick Ratio', measureId:4, valueType: 'Ratio', periodTypes: [ANNUAL,QUARTERLY], description: "Returns the quick ratio, a measure of short term liquidity, for the period and date(s) requested. It is equal to Cash & Equivalents plus Receivables divided by Current Liabilities."},
{name: 'Receivables Turnover', measureId:358, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns sales divided by receivables for the period and date(s) requested."},
{name: 'Research & Development', measureId:359, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: ""},
{name: 'Research and Development As Percent Of Sales', measureId:355, valueType: 'Percent', periodTypes: [ANNUAL,QUARTERLY], description: "Represents all direct and indirect costs related to the creation and development of new processes, techniques, applications and products with commercial possibilities."},
{name: 'Retained Earnings', measureId:361, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Represents the accumulated after tax earnings of the company which have not been distributed as dividends to shareholders or allocated to a reserve account."},
{name: 'Return on Assets', measureId:13, valueType: 'Percent', periodTypes: [ANNUAL,QUARTERLY], description: "This is calculated as Net Income divided by Total Assets."},
{name: 'Return On Average Total Equity', measureId:363, valueType: 'Percent', periodTypes: [ANNUAL,QUARTERLY], description: "This is calculated as Net Income divided by the two fiscal period average of Total Shareholders' Equity. If you request data on a quarterly or semiannual frequency, the formula uses the LTM value for Net Income."},
{name: 'Return On Common Equity', measureId:364, valueType: 'Percent', periodTypes: [ANNUAL,QUARTERLY], description: "Returns the return on common equity for the period and date(s) requested."},
{name: 'Revenue Per Share', measureId:365, valueType: 'Actual', periodTypes: [ANNUAL,QUARTERLY], description: "Returns sales per share for the period and date(s) requested in local currency by default."},
{name: 'Total Cash', measureId:393, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Represents money available for use in the normal operations of the company. It is the most liquid of all of the company's assets."},
{name: 'Total Debt To Equity Ratio', measureId:400, valueType: 'Ratio', periodTypes: [ANNUAL,QUARTERLY], description: "Returns total debt as a percent of total equity for the period and date(s) requested in local currency by default."},
{name: 'Total Enterprise Value', measureId:402, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Stock: Returns enterprise value for the period and date(s) requested in local currency by default. Exchange Traded Fund (ETF): This value represents an ETF's AUM, the market value of total assets that a fund manages on behalf of investors."},
{name: 'Invested Capital - Total', measureId:406, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns total invested capital for the period and date(s) requested in local currency by default."},
{name: 'Total Operating Expenses', measureId:411, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Represents the sum of all expenses related to operations."},
{name: 'Working Capital', measureId:430, valueType: 'Millions', periodTypes: [ANNUAL,QUARTERLY], description: "Returns total working capital for the period and date(s) requested in local currency by default."},
{name: 'Get More Metrics...', measureId:-1}
]

export const PE_RATIO = {name: 'PE Ratio', measureId:319, valueType: 'Ratio', periodTypes: [DAILY], description: "Stock: Returns price divided by earnings per share for the period requested, using the most recent close price within that period. Exchange Traded Fund (ETF): Returns the fund weighted average ratio of prices of a fund's stocks to the trailing earnings of underlying stocks. Aggregate prices are scaled by the sum of individual earnings, including negative earnings. Closed-End Fund (CEF): Returns the market capitalization weighted average price/earnings ratio for the securities in the fund's portfolio."};
