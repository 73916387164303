
export const APP_ENV = process.env.NODE_ENV;
export const appProtocol = process.env.VUE_APP_PROTOCOL;
const appHost = process.env.VUE_APP_HOST;
const appPort = process.env.VUE_APP_PORT;


const apiProtocol = process.env.VUE_APP_API_PROTOCOL;
const apiHost = process.env.VUE_APP_API_HOST;
const wsProtocol = process.env.VUE_APP_WS_PROTOCOL;

const indexPage = '/index.html#/'


export const API_URL = apiProtocol + apiHost + "/rest";
export const LOGIN_URL = process.env.VUE_APP_LOGIN_URL; //apiProtocol + apiHost;
export const SIGNUP_URL = process.env.VUE_APP_SIGNUP_URL;
export const LOGIN_PAGE = indexPage + 'login'
export const SIGNUP_PAGE = indexPage + 'signup'
export const APP_URL =appProtocol + appHost + (appPort? ":"+appPort : "");
export const APP_DOMAIN_PREFIX = process.env.VUE_APP_APP_DOMAIN_PREFIX;
export const GROUP_DOMAIN_SUFFIX = process.env.VUE_APP_GROUP_DOMAIN_SUFFIX;

export const WS_URL = wsProtocol + apiHost + "/ws/notifications";
export const SSE_URL = process.env.VUE_APP_SSE_URL;

export const FORUM_URL = process.env.VUE_APP_FORUM_URL;//"https://invrs.discussion.community/js/mb/embed.js?authtoken=";
export const FORUM_LOGOUT_URL = process.env.VUE_APP_FORUM_LOGOUT_URL;//"https://invrs.websitetoolbox.com/register/logout?authtoken=";

export const LARGE_CHART_URL = appProtocol+appHost+(appPort ? ":"+appPort : "")+ "/assets/pages/chart.html";
export const OVERVIEW_URL = appProtocol+appHost+(appPort ? ":"+appPort : "")+ "/assets/pages/overview.html";
export const APP_PROTOCOL = appProtocol;

export const INVRSIBOT_INVITE_URL = process.env.VUE_APP_INVRSIBOT_INVITE_URL;
export const WIDGETBOT_INVITE_URL = process.env.VUE_APP_WIDGETBOT_INVITE_URL;

export const INVRS_DISCORD_SERVER_NAME = process.env.VUE_APP_INVRS_DISCORD_SERVER_NAME;

export const WEALTHICA_LINK = process.env.VUE_APP_WEALTHICA_LINK;

export const QMOD_VERSION = process.env.VUE_APP_QMOD_VERSION;
export const QMODII_VERSION = process.env.VUE_APP_QMODII_VERSION;
export const QM_WM_ID = process.env.VUE_APP_QM_WM_ID;
export const QM_API_URL = process.env.VUE_APP_QM_API_URL;
export const QM_ENV = process.env.VUE_APP_QM_ENV;

export const STREAM_KEY = process.env.VUE_APP_STREAM_KEY;
export const STREAM_APP_ID = process.env.VUE_APP_STREAM_APP_ID;

export const CHAT_URL = process.env.VUE_APP_CHAT_URL;

export const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

export const QM_REGISTRATION_URL = process.env.VUE_APP_QM_REGISTRATION_URL;
export const QM_COMPLETE_PRODUCT_KEY = process.env.VUE_APP_QM_COMPLETE_PRODUCT_KEY;
export const QM_COMPLETE_PAGE_URL = process.env.VUE_APP_QM_COMPLETE_PAGE_URL;

export const HOME = process.env.VUE_APP_HOME;
export const HOME_URL = process.env.VUE_APP_HOME +(appPort ? ":"+appPort : "");
export const APP_PAGE = '/index.html';

export const LOGOUT_PAGE = '/logout.html';

export const GIPHY_KEY = process.env.VUE_APP_GIPHY_KEY;
export const GIPHY_URL = process.env.VUE_APP_GIPHY_URL;

export const SHARE_URL = process.env.VUE_APP_SHARE_URL;

export const ANALYTICS_ENABLED = process.env.VUE_APP_ANALYTICS_ENABLED

export const SOCIAL_AUTH_STATE = process.env.VUE_APP_SOCIAL_AUTH_STATE;
export const SOCIAL_AUTH_GOOGLE_CLIENT_ID = process.env.VUE_APP_SOCIAL_AUTH_GOOGLE_CLIENT_ID;
export const SOCIAL_AUTH_GOOGLE_URL_BASE = process.env.VUE_APP_SOCIAL_AUTH_GOOGLE_URL_BASE;
export const SOCIAL_AUTH_GOOGLE_SCOPES = process.env.VUE_APP_SOCIAL_AUTH_GOOGLE_SCOPES;

export const EDUCATION_GROUP_ID = process.env.VUE_APP_EDUCATION_GROUP_ID;

export const APP_STATUS_URL = process.env.VUE_APP_STATUS_URL;