import AuthService from '../service/AuthService';
import {APP_DOMAIN_PREFIX} from "../common/config";


class AppServiceImpl {

    

    cleanUpApp() {
        AuthService.clearAuthSession();
    }


    isAppDomain() {
        let hostname =  new URL(window.location).hostname;
       
        return hostname.startsWith(APP_DOMAIN_PREFIX);// if the host name doesn't start with   dev. test-app. or app. then assume its a group domain
    }
}


const AppService = new AppServiceImpl();

export default AppService;