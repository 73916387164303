import {SHARE_URL} from '../../common/config';

import ResearchService from '../../service/ResearchService';

import UserService from '../../service/UserService';
import SecurityFormatter from '../../common/SecurityFormatter';

import FeedService from '../../service/FeedService';
import router from '../../router';
import store from '../../store/index'
import sanitizeHtml from 'sanitize-html';
import ImageUploader from './ImageUploader';

//import URLUtils from '../../utilities/URLUtils';
import EventBus from '../../event-bus';
//import { uid } from 'ckeditor5/src/utils';
// const IFRAME_SRC = '//cdn.iframe.ly/api/iframe';
// const API_KEY = 'd2180344d2780130b0044a'; 
/**
 * Important, we no longer use CKEditor, this class doesn't actually use any ckeditor functionality
 * however it is still in use for the quill editor as we re-used some of the functions
 * for searching mentions ect
 * if we ever remove or rename it we need to ensure server side content policy is adjusted as well
 */
class CKEditorPluginServiceImpl {

  constructor() {

    window.CKEditorPluginService = {

      onMentionClicked: (event) => {this.mentionHandler(event);},
      onNonSharedPostLinkClicked: (event) => {
        event.stopPropagation();
      },
      onInAppLinkClicked:(event) => {
        //EventBus.emit('cancel-post-dialog');
        event.stopPropagation();
        //event.preventDefault();
        //router.push(event.target.href);
        
      }, 

      onSharedPostLinkClicked: async (event) => {
        event.stopPropagation();
        event.preventDefault();

        let href = event.target.href.replace(SHARE_URL + '/', "").replace('.xhtml', "");

        const activity = await FeedService.getInAppSharedPost(Number(href));

        if (activity) {
          EventBus.emit('trigger-dialog-for-single-activity', activity);
        }
      },
    };

  }

mentionHandler(event) {
    event.stopPropagation(); // Prevents triggering parent div's click event, doesn't bubble up and only triggers it's own click event
    event.preventDefault();
    const mention = event.target;

    if (mention.dataset.mentionType == 'security') {
      if (mention.dataset.secType == 'equity') {
        this.lookupEquity(mention.dataset);
      }
      else if (mention.dataset.secType == 'fund') {
        this.lookupFund(mention.dataset);
      }
      else {
        this.lookupIndex(mention.dataset);
      }
    } else {
      router.push({ name: 'userProfile', params: {user: mention.dataset.analystUsername}});
    }
  }

  

  initializePlaceholder(placeholder) {
    let config = this.getNoteEditorConfig();

    config['placeholder'] = placeholder;

    return config;
  }

  

  

  


  


  getTagFinderConfig() {
    return {
      // The configuration of the tag finder.
      placeholder: 'Add upto 5 tags (e.g. #Real Estate)...',
      extraPlugins: [this.mentionCustomization],
      mention: {
        feeds: [
          {
            marker: '#',
            feed: this.searchTags,
            minimumCharacters: 1,
            itemRenderer: this.tagRenderer
          }
        ]
      },

      htmlEmbed: {
        showPreviews: true,
        sanitizeHtml: (inputHtml) => {
          // Strip unsafe elements and attributes, e.g.:
          // the `<script>` elements and `on*` attributes.
          const outputHtml = sanitizeHtml(inputHtml, {
            allowedTags: sanitizeHtml.defaults.allowedTags,


          });

          return {
            html: outputHtml,
            // true or false depending on whether the sanitizer stripped anything.
            hasChanged: true
          };
        }
      }
    }
  }

  


  /**
   * 
   * This is what takes care of turning the rendered html back into a mention object and vice versa.
   * 
   */
  mentionCustomization(editor) {
    
    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'a',
        key: 'data-mention',
        classes: ['mention'],
        attributes: {
          href: true,
          'data-analyst-id': true,
          'data-analyst-username': true,
          'data-mention-type': true,
          'data-tag-id': true,
          'data-sec-type': true,
          'data-security-id': true,
          'data-sector-id': true,
          'data-sector-name': true,
          'data-industry-id': true,
          'data-industry-name': true,
          'data-exchange-id': true,
          'data-exchange': true,
          'data-fund-id': true,
          'data-index-id': true,
          onclick: true
        }
      },
      model: {
        key: 'mention',
        value: viewItem => {
          
          // The mention feature expects that the mention attribute value
          // in the model is a plain object with a set of additional attributes.
          // In order to create a proper object use the toMentionAttribute() helper method:
          return editor.plugins.get('Mention').toMentionAttribute(viewItem, {
            // Add any other properties that you need.
            link: viewItem.getAttribute('href'),
            analystId: viewItem.getAttribute('data-analyst-id'),
            username: viewItem.getAttribute('data-analyst-username'),
            clickHandler: viewItem.getAttribute('onclick'),
            securityId: viewItem.getAttribute('data-security-id'),
            secType: viewItem.getAttribute('data-sec-type'),
            mentionType: viewItem.getAttribute('data-mention-type'),
            tagId: viewItem.getAttribute('data-tag-id'),
            sectorId: viewItem.getAttribute('data-sector-id'),
            sectorName: viewItem.getAttribute('data-sector-name'),
            industryId: viewItem.getAttribute('data-industry-id'),
            industryName: viewItem.getAttribute('data-industry-name'),
            exchangeId: viewItem.getAttribute('data-exchange-id'),
            exchange: viewItem.getAttribute('data-exchange'),
            fundId: viewItem.getAttribute('data-fund-id'),
            indexId: viewItem.getAttribute('data-index-id'),

          });



        }
      },
      converterPriority: 'high'
    });

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for('downcast').attributeToElement({
      model: 'mention',
      view: (modelAttributeValue, { writer }) => {
        // Do not convert empty attributes (lack of value means no mention).
        if (!modelAttributeValue) {
          return;
        }

        const mentionClassMap = {
          'security': 'security-mention',
          'tag': 'tag-mention',
          'analyst': 'analyst-mention'
        }

        return writer.createAttributeElement('a', {
          class: 'mention ' + mentionClassMap[modelAttributeValue.mentionType],
          'data-mention': modelAttributeValue.id,
          'data-analyst-id': (modelAttributeValue.analystId ? modelAttributeValue.analystId : ""),
          'data-analyst-username': (modelAttributeValue.username ? modelAttributeValue.username : ""),
          'href': (modelAttributeValue.link ? modelAttributeValue.link : "#"),
          onclick: (modelAttributeValue.clickHandler ? modelAttributeValue.clickHandler : ""),
          'data-sec-type': (modelAttributeValue.secType ? modelAttributeValue.secType : ""),
          'data-security-id': (modelAttributeValue.securityId ? modelAttributeValue.securityId : ""),
          'data-sector-id': (modelAttributeValue.sectorId ? modelAttributeValue.sectorId : ""),
          'data-sector-name': (modelAttributeValue.sectorName ? modelAttributeValue.sectorName : ""),
          'data-industry-id': (modelAttributeValue.industryId ? modelAttributeValue.industryId : ""),
          'data-industry-name': (modelAttributeValue.industryName ? modelAttributeValue.industryName : ""),
          'data-exchange-id': (modelAttributeValue.exchangeId ? modelAttributeValue.exchangeId : ""),
          'data-exchange': (modelAttributeValue.exchange ? modelAttributeValue.exchange : ""),
          'data-mention-type': (modelAttributeValue.mentionType ? modelAttributeValue.mentionType : ""),
          'data-tag-id': (modelAttributeValue.tagId ? modelAttributeValue.tagId : ""),
          'data-fund-id': (modelAttributeValue.fundId ? modelAttributeValue.fundId : ""),
          'data-index-id': (modelAttributeValue.indexId ? modelAttributeValue.indexId : "")
        }, {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid
        });

      },
      converterPriority: 'highest'
    });
  }


  uploadImage(file) {
    
    return FeedService.uploadImage(store.state.users.user.userId, file);

  }

  lookupEquity(mentionData) {
    let symbol = mentionData.mention.replace('$', '');
   
    ResearchService.setSelectedEquity(mentionData.exchange, symbol, () => {
      
      router.push({ path: '/equity' });
    });
  }

  lookupFund(mentionData) {
    let symbol = mentionData.mention.replace('$', '');
    
    ResearchService.setSelectedFund(mentionData.exchange, symbol, () => {
      
      router.push({ path: '/fund' });
    });
  }

  lookupIndex(mentionData) {
    let symbol = mentionData.mention.replace('$', '');
    
    ResearchService.setSelectedIndex(symbol, () => {
      
      router.push({ path: '/marketIndex' });
    });
  }


  searchAnalysts(searchTerm) {
    return new Promise(resolve => {
      try {
        UserService.queryAnalysts(searchTerm, 10, 0).then(resp => {
          
          const matches = [];

          for (let i = 0; i < resp.data.analysts.length; ++i) {
            let analyst = resp.data.analysts[i];
            let fullName = '';

            if (analyst.firstName || analyst.lastName) {
              fullName = `${analyst.firstName} ${analyst.lastName}`;
            }

            let option =
            {
              id: '@' + analyst.name,
              avatar: analyst.avatar,
              username: analyst.name,
              fullName: fullName,
              clickHandler: "window.CKEditorPluginService.onMentionClicked(event)",
              analystId: analyst.analystId,
              mentionType: 'analyst',
            };

            matches.push(option);

          }
          resolve(matches);
        });
      } catch (err) {
        console.debug("Error attempting to query analysts with search term " + searchTerm + ", error: " + err);
        resolve([]);
      }
    });

  }


  searchTags(searchTerm) {
    return new Promise(resolve => {
      try {
        FeedService.queryTags(searchTerm, 10, 0).then(resp => {
          
          const matches = [];

          for (let i = 0; i < resp.data.length; ++i) {
            let tag = resp.data[i];

            let option =
            {
              id: '#' + tag.name,
              name: tag.name,
              link: '/index.html#/tag/profile/' + tag.tagId,
              tagId: tag.tagId,
              mentionType: 'tag',
            };

            matches.push(option);

          }
          resolve(matches);
        });
      } catch (err) {
        console.debug("Error attempting to query tags with search term " + searchTerm + ", error: " + err);
        resolve([]);
      }
    });
  }


  searchMarket(searchTerm) {
    return new Promise(resolve => {
      try {
        ResearchService.queryMarket('All', searchTerm, 5, 0).then(resp => {
          const matches = [];

          matches.push({
            type: 'header',
            value: 'Equities'
          });

          for (let i = 0; i < resp.data.securities.length; ++i) {
            let sec = resp.data.securities[i];

            matches.push(
              {
                type: 'item',
                id: '$' + sec.symbol,
                securityId: sec.securityId,
                securityName: sec.company,
                securitySymbol: sec.symbol,
                value: SecurityFormatter.getFormattedSecurity(sec),
                secType: 'equity',
                exchange: sec.exchangeSymbol,
                exchangeId: sec.exchangeId,
                clickHandler: "window.CKEditorPluginService.onMentionClicked(event)",
                mentionType: 'security',
                sectorId: sec.sectorId,
                sectorName: sec.sectorName,
                industryId: sec.industryId,
                industryName: sec.industryName

              });

          }

          matches.push({
            type: 'header',
            value: 'Funds'
          });

          for (let i = 0; i < resp.data.funds.length; ++i) {
            let f = resp.data.funds[i];

            matches.push(
              {
                type: 'item',
                id: '$' + f.symbol,
                fundId: f.fundId,
                fundName: f.name,
                fundSymbol: f.symbol,
                value: SecurityFormatter.getFormattedSecurity(f),
                secType: 'fund',
                exchange: f.exchangeSymbol,
                exchangeId: f.exchangeId,
                clickHandler: "window.CKEditorPluginService.onMentionClicked(event)",
                mentionType: 'security'
              });

          }

          matches.push({
            type: 'header',
            value: 'Indices'
          });

          for (let i = 0; i < resp.data.indices.length; ++i) {
            let ind = resp.data.indices[i];

            matches.push(
              {
                type: 'item',
                id: '$' + ind.symbol,
                indexId: ind.marketIndexId,
                indexSymbol: ind.symbol,
                indexName: ind.name,
                value: SecurityFormatter.getFormattedSecurity(ind),
                secType: 'index',
                clickHandler: "window.CKEditorPluginService.onMentionClicked(event)",
                mentionType: 'security'
              });

          }


          resolve(matches);
        });
      } catch (err) {
        console.debug("Error attempting to query marke twith search term " + searchTerm + ", error: " + err);
        resolve([]);
      }

    });
  }

  analystRenderer(item) {
    const itemElement = document.createElement('div');

    itemElement.classList.add('custom-item-analyst');
    itemElement.id = `mention-list-item-id-${item.analystId}`;
    // itemElement.textContent = `${item.name} `;

    const rowElement = document.createElement('div');
    rowElement.classList.add('item-row');
    itemElement.appendChild(rowElement);

    const roundedAvatarEl = document.createElement('div');
    roundedAvatarEl.classList.add('rounded-image');

    const profileImageEl = document.createElement('img');
    profileImageEl.classList.add('profile-image');
    profileImageEl.src = (item.avatar && item.avatar !== undefined) ? item.avatar : '../Default-Avatar.jpeg';
    roundedAvatarEl.appendChild(profileImageEl);
    rowElement.appendChild(roundedAvatarEl);

    const namesContainerEl = document.createElement('div');
    namesContainerEl.classList.add('names-container');
    rowElement.appendChild(namesContainerEl);

    if (item.fullName !== '') {
      const fullNameEl = document.createElement('span');
      fullNameEl.classList.add('full-name');
      fullNameEl.textContent = item.fullName;
      namesContainerEl.appendChild(fullNameEl);
    }

    const usernameEl = document.createElement('span');
    usernameEl.classList.add('username');
    usernameEl.textContent = item.id;
    namesContainerEl.appendChild(usernameEl);

    return itemElement;
  }

  tagRenderer(item) {

    const itemElement = document.createElement('div');

    itemElement.classList.add('custom-item-tag');
    const tagNameEl = document.createElement('span');

    tagNameEl.classList.add('item-name');
    tagNameEl.textContent = item.name;
    itemElement.appendChild(tagNameEl);
    //itemElement.classList.add('item-name');
    itemElement.id = `mention-list-item-id-${item.tagId}`;
    //itemElement.textContent = `${item.name} `;


    return itemElement;
  }

  securityRenderer(item) {

    const itemElement = document.createElement('div');

    itemElement.classList.add('custom-item-security');

    if (item.type === 'item') {
      const id = item.securityId || item.fundId || item.indexId;

      itemElement.id = `mention-list-item-id-${id}`;
      //itemElement.textContent = `${item.value} `;

      const topChildEl = document.createElement('span');

      topChildEl.classList.add('item-symbol');
      topChildEl.textContent = item.securitySymbol || item.fundSymbol || item.indexSymbol;
      itemElement.appendChild(topChildEl);

      const bottomChildEl = document.createElement('span');

      const name = item.securityName || item.fundName;

      bottomChildEl.classList.add('item-name');

      if (item.secType === 'equity' || item.secType === 'fund') {
        bottomChildEl.textContent = `${name} - ${item.exchange}`;
      } else {
        bottomChildEl.textContent = item.indexName;
      }

      itemElement.appendChild(bottomChildEl);
    } else {
      itemElement.classList.add('header');

      const headerEl = document.createElement('span');
      headerEl.classList.add('item-header');
      headerEl.textContent = item.value;

      itemElement.appendChild(headerEl);
    }

    return itemElement;
  }




  StreamUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new ImageUploader(loader);
    };
  }


}



const CKEditorPluginService = new CKEditorPluginServiceImpl();
export default CKEditorPluginService;