import LocalStorageService from "../../service/LocalStorageService";

export const namespaced = true;




export const state = {
      
     
      user: null
    }

export const mutations = {
      SET_USER(state,  user) {
        if (user?.type) {
          const followed = state.user.analyst.followed;
          state.user = user.user;
          if (!state.user.analyst.followed) {
            state.user.analyst.followed = followed;
          }
        } else {
          state.user = user;
        }
      },

      SET_ANALYST(state, analyst){
        if( state.user != null ) {
          state.user.analyst.name = analyst.name;
          state.user.analyst.firstName = analyst.firstName;
          state.user.analyst.middleName = analyst.middleName;
          state.user.analyst.lastName = analyst.lastName;
          state.user.analyst.avatar = analyst.avatar;
          state.user.analyst.bio = analyst.bio;

          if (analyst.followed) {
            state.user.analyst.followed.followedTags = analyst.followed.followedTags;
          }
        }
      },

      SET_FOLLOWED_ANALYSTS(state, analysts) {
        if (state.user != null) {
          state.user.analyst.followed.followedAnalysts = {...analysts};
          state.user.analyst.activityStats.numFollowing = Object.keys(analysts).length;
        }
      },

      SET_GROUP_ROLES(state, roles) {
        if (state.user != null) {
          state.user.groupRoles = [...roles];
        }
      },

      SET_SETTING(state, {id, value}) {

        if (state.user != null) {
          const index = state.user.settings.findIndex(setting => setting.userPreferenceId === id);
          if (index == -1) {
            state.user.settings[state.user.settings.length] = { value: value, userId: state.user.userId, userPreferenceId: id };
          } else {
            let item = state.user.settings[index];
            item.value = value;

            state.user.settings[index] = item;
          }
        }
      }
      
    }


export const actions = {
      setUser({ rootState,commit }, user) {
        commit('SET_USER', user)
        
        if (rootState.autoPersist) {
          LocalStorageService.persistCurrentState();
        }
      },

      setAnalyst({rootState, commit}, analyst) {
        commit('SET_ANALYST', analyst);

        if (rootState.autoPersist) {
          LocalStorageService.persistCurrentState();
        }
      },

      setFollowedAnalysts({rootState, commit}, analysts) {
        commit('SET_FOLLOWED_ANALYSTS', analysts);
        
        if (rootState.autoPersist) {
          LocalStorageService.persistCurrentState();
        }
      },

      setSetting({rootState, commit}, {id, value}) {
        commit('SET_SETTING', {id, value});

        if (rootState.autoPersist) {
          LocalStorageService.persistCurrentState();
        }
      }
      
    }

export const getters = {
      getUser: state  => {
        return state.user;
      },

      roleList: state => {
        return state.user.roles;
      },

      groupRoleList: state => {
        return state.user.groupRoles;
      },
  
      subscriptionList: state => {
        return state.user.subscriptions;
      },

        
      featureList: state => {
        return state.user.features;
      },

      settingsList: state => {
        return state.user.settings;
      },

      getSetting: state => id => {
        return state.user?.settings.filter((setting) => setting.userPreferenceId === id)[0];
      }
    }
  