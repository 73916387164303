import APIService from '@/common/APIService'
import store from '../store/index';
import StringUtils from '../utilities/StringUtils';
//import CacheService from './CacheService';

class ResearchServiceImpl {
  
  
  queryMarket(type, query, limit, page) {
    let url = '/research/query/market?limit='+limit+'&page='+page+'&q='+query+"&qType="+type;
    return APIService.get(url);
   
    
  }

 
  getNotes(limit, offset, filter, filterValue, orderBy, orderByDir) {
    let options = (filter != null ? "&filter="+filter+"="+filterValue : "");
    options += (orderBy != null ? "&orderBy="+orderBy : "");
    options += (orderBy != null && orderByDir != null ? "&orderByDir="+orderByDir : "");
    return APIService.get('/research/notes?analystId=' + store.state.users.user.analyst.analystId+'&limit='+limit+'&offset='+offset+options);
  }

  getNote(noteId) {
    return APIService.get('/research/notes/'+noteId);
  
  }

  getNoteVersion(noteVersionUUID) {
    return APIService.get('/research/notes/versions/'+noteVersionUUID);
  
  }

  deleteNote(noteId) {
    return APIService.delete('/research/notes/'+noteId);
  }

  updateNote(note) {
    return APIService.put('/research/notes/'+note.noteId, note);
  }

  saveNote(note) {
    return APIService.post('/research/notes', note);
  }
  
  uploadImage(file) {
    return APIService.postFile('/research/analyst/'+store.state.users.user.analyst.analystId+'/image/upload', file);
  }

  getCompanyProfile(securityId) {
    return APIService.get('/research/security/'+securityId+'/company/profile');
  }

  getEquityOverview(securityId) {
    return APIService.get('/research/security/'+securityId+'/overview');
  }
  

  getSecurity(exchangeSymbol, ticker) {
    return APIService.get('/research/security?exSymbol='+exchangeSymbol+'&symbol='+ticker);
  }

  getSecurityByCountryAndSymbol(countryCode, ticker) {
    return APIService.get('/research/security/'+countryCode+'/'+ticker);
  }

  getSecurityBySymbolCompanyCountry(ticker, company, country) {
    return APIService.get('/research/security/search?symbol='+ticker+'&company='+company+'&country='+country);
  }

  getFund(exchangeSymbol, ticker) {
    return APIService.get('/research/fund?exSymbol='+exchangeSymbol+'&symbol='+ticker);
  }

  getMarketIndex( ticker) {
    return APIService.get('/research/marketIndex?symbol='+ticker);
  }


  getInvrsChart(userId, url) {
    return APIService.get('/research/chart?userId='+userId +'&url='+url);
  }

  getSnapshotUrl(userId, cropWidthAmount, dataUrl ) {
    return APIService.post('/research/snapshot?userId='+userId+"&cropWidthAmount="+(cropWidthAmount != null ?cropWidthAmount:40), dataUrl);
  }

  createPortfolio(userId,  portfolioName, cashAmount, readOnly) {
    return APIService.post('/research/portfolios?userId='+userId+"&name="+portfolioName+"&cash="+cashAmount+"&readOnly="+readOnly, {});
  }
  updateDiscordServers() {
    return APIService.post('/research/discord/servers', {});
  }
  searchDiscordServers(limit, page, query) {
    return APIService.get('/research/discord/servers?limit='+limit+'&page='+page+'&q='+query);
  }

  addUserDiscordServer(userId, server) {
    return APIService.post('/research/user/discord/servers', {'userId' : userId, 'serverId': server.serverId});
  }

  getUserDiscordServers(userId, limit, page, query) {
    
    return APIService.get('/research/user/discord/servers?userId='+userId+'&limit='+limit+'&page='+page+'&q='+query);    
  }

  getUserDiscordSecret(userId){
    return APIService.get('/research/user/discord/secret?userId='+userId);
  }

  linkAccount(userId, token){

    return APIService.post('/research/link/wealthica/account', {'userId' : userId, 'token': token});
  }

 
  setSelectedSymbolFromQM(symbolParam, callback) {
    //console.debug("setSelectedSymbolFromQM called for " + symbolParam);
    let exSym = symbolParam.split(":");
    let exchange = "";
    if( exSym.length > 1 ){
      exchange = this.getINVRSExchange(exSym[1]);
    }
    let ticker = exSym[0];
    //console.debug("looking up "+ exchange + " with ticker " + ticker);

    const handleErr = (error) => {
      console.debug('There was an error:', error.response);
      callback("Not Found");
    }

    const handleResponse = (response) => {
      if (response.data && response.data.length > 0) {
        // console.debug(JSON.stringify(response.data));
        store.commit('SET_SELECTED_EQUITY', response.data[0])
        callback("success");
      } else {
        callback("Not Found");
      }
    }

    if (exSym.length > 1 && exSym[1] == 'CA') {
      this.getSecurityByCountryAndSymbol('CDN', ticker).then((resp) => handleResponse(resp)).catch((err) => handleErr(err));
    } else if (exchange && exchange != '') {
      this.getSecurity(exchange, ticker).then((resp) => handleResponse(resp)).catch((err) => handleErr(err));
    } else {
      this.getSecurityByCountryAndSymbol('US', ticker).then((resp) => handleResponse(resp)).catch((err) => handleErr(err));
    }

  }
  
  setSelectedFundSymbolFromQM(symbolParam, callback) {
    //console.debug("setSelectedSymbolFromQM called for " + symbolParam);
    let exSym = symbolParam.split(":");
    let exchange = "";
    if( exSym.length > 1 ){
      exchange = this.getINVRSExchange(exSym[1]);
    } 
    let ticker = exSym[0];
    //console.debug("looking up "+ exchange + " with ticker " + ticker);
    this.getFund(exchange, ticker).then(response => {
      if( response.data ) {
        //console.debug(response.data)
        store.commit('SET_SELECTED_FUND', response.data)
        callback("success");
      }
    })
    .catch(error => {
      
      console.debug('There was an error:', error.response)
    })
  }

  setSelectedMarketIndexSymbolFromQM(symbolParam, callback) {
    this.getMarketIndex(symbolParam).then(response => {
      if (response.data) {
        //console.debug(response.data);
        store.commit('SET_SELECTED_MARKET_INDEX', response.data);
        callback();
      }
    })
  }

  


  setSelectedEquity(exchange, ticker, callback) {
    
    this.getSecurity(exchange, ticker).then(response => {
      if( response.data ) {
        //console.debug(response.data)
        response.data.symbol = StringUtils.convertXigniteToQmCompatibleSymbol(response.data.symbol);
        store.commit('SET_SELECTED_EQUITY', response.data)
        callback();
      }
        
        
    })
    .catch(error => {
      
      console.debug('There was an error:', error.response)
    })

  
  }


  setSelectedEquityFromXignite(exchange, ticker, callback) {
    
    this.getSecurity(exchange, ticker).then(response => {
      if( response.data ) {
        //console.debug(response.data)
        response.data.symbol = StringUtils.convertXigniteToQmCompatibleSymbol(response.data.symbol);
        store.commit('SET_SELECTED_EQUITY', response.data)
        callback("success");
      }
        
        
    })
    .catch(error => {
      
      console.debug('There was an error:', error.response)
    })

  
  }

  setSelectedFundFromXignite(exchange, ticker, callback) {
    
    this.getFund(exchange, ticker).then(response => {
      if( response.data ) {
        //console.debug(response.data)
        response.data.symbol = StringUtils.convertXigniteToQmCompatibleSymbol(response.data.symbol);
        store.commit('SET_SELECTED_FUND', response.data)
        callback('success');
      }
        
        
    })
    .catch(error => {
      
      console.debug('There was an error:', error.response)
    })
  }

  setSelectedFund(exchange, ticker, callback) {
    
    this.getFund(exchange, ticker).then(response => {
      if( response.data ) {
        //console.debug(response.data)
        response.data.symbol = StringUtils.convertXigniteToQmCompatibleSymbol(response.data.symbol);
        store.commit('SET_SELECTED_FUND', response.data)
        callback();
      }
        
        
    })
    .catch(error => {
      
      console.debug('There was an error:', error.response)
    })

  
  }

  setSelectedIndex(ticker, callback) {
    
    this.getMarketIndex( ticker).then(response => {
      if( response.data ) {
        //console.debug(response.data)
        response.data.symbol = StringUtils.convertXigniteToQmCompatibleSymbol(response.data.symbol);
        store.commit('SET_SELECTED_MARKET_INDEX', response.data)
        callback();
      }
        
        
    })
    .catch(error => {
      
      console.debug('There was an error:', error.response)
    })

  
  }


  getINVRSExchange( exchangeSymbol) {
    if( exchangeSymbol == 'NGS') {
      return "XNAS" ;
    }
    else if( exchangeSymbol == 'XNYS') {
      return "XNYS";
    }
    else if( exchangeSymbol == 'OOTC') {
      return "OOTC:" ;
    }
    else if( exchangeSymbol == 'CA') {
      return "XTSE";
    }
    else if( exchangeSymbol == 'CC') {
      return "XTSX";
    }
    else if( exchangeSymbol == 'CNX') {
      return "XCNQ";
    }
    else if( exchangeSymbol == 'AQL') {
      return "AQL";
    }
    else {
      return "";
    }
  }


  getAssetLists(limit, offset, query){
    return APIService.get('/research/'+store.state.users.user.userId+'/lists?limit='+limit+'&offset='+offset+'&query='+query);
  }

  getAssetListItems(listId, limit, offset, query){
    return APIService.get('/research/list/'+listId+'/items?limit='+limit+'&offset='+offset+'&query='+( query ? query : ''));
  }

  createAssetList(assetListReq) {
    return APIService.post('/research/lists', assetListReq);
  }

  updateAssetList(assetList){

    return APIService.put('/research/lists', assetList);
  }

  updateAssetListItems(listId, assetsToAdd, assetsToRemove){

    return APIService.put('/research/lists/'+listId+'/items', {toAdd : assetsToAdd, toRemove: assetsToRemove});
  }

  deleteAssetList(assetListId) {
    return APIService.delete('/research/lists/'+assetListId);
  }


  saveScreenerResultsToNewList(newListName, selectedRows) {
    return APIService.post('/research/screener/lists/', {userId: store.state.users.user.userId, type: 'equity', name: newListName, selectedSymbols: selectedRows});
  }

  saveScreenerResultsToList(assetListId, selectedRows) {
    return APIService.post('/research/screener/lists/', {assetListId: assetListId, userId: store.state.users.user.userId, type: 'equity', selectedSymbols: selectedRows});
  }

  saveCompareListToNewList(newListName, selectedRows) {
    return APIService.post('/research/compare/lists/', {userId: store.state.users.user.userId, type: 'equity', name: newListName, selectedCompanies: selectedRows});
  }

  saveCompareListToList(assetListId, selectedRows) {
    return APIService.post('/research/compare/lists/', {assetListId: assetListId, userId: store.state.users.user.userId, type: 'equity', selectedCompanies: selectedRows});
  }

  clearCache() {
    return APIService.post('/research/cache/evict/',{});
  }
  
}

const ResearchService = new ResearchServiceImpl();
export default ResearchService;